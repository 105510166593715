@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

// ----------------------------------------------
// Pricing Masthead
// ----------------------------------------------

.pricing-masthead {
    background-image: url('../images/masthead/pricing-masthead-small.jpg');
    background-position: center bottom;

    .masthead-content {
        margin-top: _f.rem(160);
    }

    .masthead-list {
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-s)} {
        background-image:
            image-set(
                url('../images/masthead/pricing-masthead-large.jpg') 1x,
                url('../images/masthead/pricing-masthead-large@2x.jpg') 2x
            );

        .masthead-content {
            margin: auto;
        }

        .masthead-list {
            display: block;
        }
    }
}


// ----------------------------------------------
// Pricing Plans
// ----------------------------------------------

.pricing-wrap {
    margin: 0 auto _c.$spacing-xl;
    position: relative;
    max-width: 680px;

    @media #{(_f.query(_f.em(1160, 16)))} {
        margin-bottom: _c.$spacing-xl;
        max-width: _c.$main-width;
    }
}

.pricing {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{(_f.query(_f.em(1160, 16)))} {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    }
}

.pricing__item {
    margin-bottom: _c.$spacing-l;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    @media #{(_f.query(_f.em(1160, 16)))} {
        margin-bottom: 0;
        width: calc(#{math.div(100%, 3)} - #{math.div(_c.$spacing-xm, 2)});
    }
}

.pricing-card {
    @include _m.layer-shadow-a;

    background-color: _c.$white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 _c.$spacing-l;
    position: relative;

    @media #{(_f.query(_f.em(1160, 16)))} {
        margin-top: _c.$spacing-xxl;

        &.pricing-card--em {
            margin-top: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    h4,
    h5 {
        @include _m.sans--bold;
    }

    h3 {
        @include _m.sans--black;
        font-size: _f.rem(24);
        margin-bottom: 0;
        text-align: center;

        span {
            @include _m.sans--semi-bold;

            color: _c.$blue;
            display: block;
            font-size: _f.rem(32);
            line-height: 1;
        }

        strong {
            @include _m.sans--bold;

            font-size: _f.rem(50);
        }
    }

    h4 {
        color: _c.$blue;
        font-size: _f.rem(22);
    }

    h5 {
        font-size: _f.rem(20);
    }

    ul {
        padding-left: 1em;

        > li {
            margin-bottom: _c.$spacing-s;
        }
    }
}

.pricing__item__text {
    margin-top: _c.$spacing-xl;

    p {
        color: _c.$white;
        font-size: _f.rem(20);
        margin-bottom: _c.$spacing-xs;
        text-align: center;
    }

    a {
        font-size: _f.rem(16);

        &:link,
        &:visited {
            color: _c.$white;
        }
    }
}

.pricing__item__text:not(.pricing__item__text--small) {
    display: none;

    @media #{(_f.query(_f.em(1160, 16)))} {
        display: block;
    }
}

.pricing__item__text--small {
    @media #{(_f.query(_f.em(1160, 16)))} {
        display: none;
    }
}

.pricing-card__badge {
    height: 89px;
    left: -5px;
    position: absolute;
    top: -5px;
    width: 89px;
}

.pricing-card__header {
    border-bottom: 1px dashed _c.$gray-med;
    padding: _c.$spacing-xm;
    width: 100%;
}

.pricing-card__body {
    flex-grow: 1;
    justify-content: center;
    padding-top: _c.$spacing-m;

    @media (min-width:_c.$breakpoint-s) and #{(_f.query(_f.em(1160, 16), 'max'))} {
        > ul {
            display: flex;
            flex-wrap: wrap;

            > li {
                width: 40%;

                &:nth-child(even) {
                    margin-left: 10%;
                }
            }
        }
    }
}

.pricing-card__footer {
    border-radius: 0 0 8px 8px;
    padding: _c.$spacing-m;
    text-align: center;

    p {
        margin: 0;
    }

    span {
        display: block;
    }

    .btn {
        border: 2px solid _c.$white;
        margin-bottom: _c.$spacing-s;
    }
}

.pricing-tagline {
    @include _m.sans--black;

    color: _c.$blue;
    font-size: _f.rem(30);
    line-height: 1.2;
    margin: 0 auto;
    max-width: 1170px;
    padding: 0 _c.$spacing-m;
    text-align: center;
}

.standard-features {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        background-color: _c.$blue;

        .section-heading,
        .section-text {
            color: _c.$white;
        }

        .app-icons__link {
            color: _c.$white;
            transition: 0.25s opacity ease-in;

            &:hover {
                opacity: 0.7;
            }

            svg {
                background-color: #456286;
                color: _c.$white;
            }
        }
    }
}

// ---------------
//  Coupon Code
// ---------------

.coupon-code-pricing {
    .field-addon {
        border: 1px solid #d5dde9;
        border-radius: 99em;
    }
}
