@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

.app-reviews {
    display: flex;
    flex-wrap: wrap;
    padding: _c.$spacing-s;
}

.app-reviews__item {
    background-color: _c.$gray-light;
    border-radius: _c.$border-radius-xl;
    margin: 0 math.div(_c.$spacing-xm, 2) _c.$spacing-xm;
    padding: _c.$spacing-xm;

    @media #{_f.query(_c.$breakpoint-l)} {
        width: calc(#{math.div(100%, 3)} - #{_c.$spacing-xm});
    }
}

.app-reviews__header {
    @media #{_f.query(_c.$breakpoint-s)} {
        display: flex;
        justify-content: space-between;
    }
}

.app-reviews__meta {
    color: _c.$gray-dark;

    strong {
        display: inline-block;
        margin-right: _c.$spacing-s;
    }

    @media #{_f.query(_c.$breakpoint-s)} {
        order: 2;
        text-align: right;

        strong {
            display: block;
            margin-right: 0;
        }
    }
}
