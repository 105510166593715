@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

$homeMastHeight: calc(100vh - 150px); // viewport height - padding

.home-masthead {
    background-color: _c.$gray-med;
    border-bottom: 4px solid _c.$white;
    padding-top: 150px;
    position: relative;
    text-align: center;

    @media #{_f.query(_c.$breakpoint-l)} {
        min-height: $homeMastHeight;
    }

    header {
        padding: 0 _c.$spacing-m;

        .masthead-heading {
            margin-bottom: _c.$spacing-m;
            text-align: center; // override media queries in other mastheads

            @media #{_f.query(_c.$breakpoint-l, 'max')} {
                font-size: _f.rem(44);
            }
        }

        .masthead-tagline {
            @include _m.sans--bold;

            color: _c.$blue;
            font-size: _f.rem(22);
            margin-bottom: _c.$spacing-xxl;

            @media #{_f.query(_c.$breakpoint-l)} {
                color: _c.$black;
            }

            @media #{_f.query(_c.$breakpoint-xxl)} {
                margin-bottom: _c.$spacing-xxml;
                font-size: _f.rem(32);
            }
        }
    }

    .masthead-form-label {
        font-size: _f.rem(18);
        margin-bottom: _c.$spacing-s;

        @media #{_f.query(_c.$breakpoint-l)} {
            margin-bottom: _c.$spacing-l;
        }
    }

    .top-brands-wrap {
        background-color: _c.$gray-light-med;
        padding-bottom: _c.$spacing-m;
        padding-top: _c.$spacing-l;

        .top-brands-heading {
            color: #505256;
        }

        @media #{_f.query(_c.$breakpoint-m)} {
            background-color: transparent;

            .top-brands-heading {
                color: _c.$black;
            }
        }

        @media #{_f.query(_c.$breakpoint-xxl)} {
            padding-bottom: _c.$spacing-xxml;
        }
    }

    .large-hide {
        @media #{_f.query(_c.$breakpoint-s)} {
            display: none;
        }
    }

    .small-hide {
        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            display: none;
        }
    }
}

.request-demo-signup,
.request-demo-signup__input {
    border-radius: 99em;
}

.request-demo-signup {
    margin: 0 auto _c.$spacing-l;
    max-width: 728px;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-s)} {
        background-color: _c.$white;
        display: flex;
        justify-content: center;
    }

    // Class toggled by JS on input focus/blur
    &.has-focus {
        box-shadow: 0 0 0 4px rgba(_c.$white, 0.5);
    }

    .btn {
        font-size: _f.rem(18);
        width: 100%;

        @media #{_f.query(_c.$breakpoint-s)} {
            width: auto;
        }
    }
}

.request-demo-signup__input {
    border: 0;
    margin-bottom: _c.$spacing-m;
    min-width: 100%;
    outline: 0;
    padding: _c.$spacing-m _c.$spacing-m _c.$spacing-m _c.$spacing-l;
    transition: 0.15s box-shadow ease-in;

    @media #{_f.query(_c.$breakpoint-s)} {
        flex: 1 0 0%;
        margin: 0 _c.$spacing-m 0 0;
        min-width: 250px;
        width: 100%;
    }
}


// For browsers that do not support display: grid
.home-masthead__content {
    position: relative;
    width: 100%;
    z-index: 5;

    @media #{_f.query(_c.$breakpoint-m)} {
        bottom: 0;
        position: absolute;
    }

    header {
        margin-bottom: _c.$spacing-l;

        @media #{_f.query(_c.$breakpoint-m)} {
            margin-bottom: _f.rem(96);
        }

        @media #{_f.query(_c.$breakpoint-xl)} {
            margin-bottom: _f.rem(128);
        }
    }
}

// For browsers that support display: grid
// The !important is to override our fallbacks for
// browsers who don't support it.
@supports(display: grid) {
    .home-masthead__content {
        display: grid;
        grid-template-rows: 1fr auto;
        bottom: auto !important;
        position: relative !important;

        @media #{_f.query(_c.$breakpoint-l)} {
            min-height: $homeMastHeight;
        }

        header {
            align-self: end;
            margin-bottom: 0 !important;

            @media #{_f.query(_c.$breakpoint-l)} {
                align-self: center;
            }
        }
    }
}

.masthead-slider {
    .masthead__slide {
        @include _m.cover;

        opacity: 0;
        transition: opacity 0.5s ease-in;
        z-index: 1;

        &.is-showing {
            opacity: 1;
            z-index: 2;
        }
    }

    .masthead__img {
        background-size: cover;
        background-position: bottom center;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;

        @media (_f.query(_f.em(746, 16))) {
            background-position: 50% 50%;
        }
    }
}


// ----------------------------------------------
// Reward Section
// ----------------------------------------------

.reward-section-one {
    padding: _f.rem(32) _c.$spacing-m _f.rem(24);

    @media #{_f.query(_c.$breakpoint-l)} {
        background-color: _c.$gray-light-med;
        padding: _c.$spacing-xxl _c.$spacing-m;
    }
}

.reward-section-two {
    background-color: _c.$gray-light-med;
    padding: _f.rem(40) _c.$spacing-l;

    @media #{_f.query(_c.$breakpoint-l)} {

        padding: 0 _c.$spacing-l _c.$spacing-xxl;
    }
}


// ----------------------------------------------
// Typewriter
// ----------------------------------------------

.typewriter {
    display: block;
    white-space: nowrap;

    @media #{_f.query(_c.$breakpoint-s)} {
        display: inline;
    }
}

.typewriter__caret {
    animation: blink-caret 0.75s step-end infinite;
    bottom: -10px;
    display: inline-block;
    border: 2px solid #000;
    height: 42px;
    position: relative;
}

// The typewriter cursor effect
@keyframes blink-caret {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: lighten(_c.$green, 20%)
    }
}


// ----------------------------------------------
// Home Icon List
// ----------------------------------------------

.home-icon-list-heading {
    font-size: _f.rem(24);

    @media #{(_f.query(_c.$breakpoint-s))} {
        text-align: left;
    }
}

.home-icon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    width: 100%;

    @media #{(_f.query(_c.$breakpoint-s, 'max'))} {
        margin: 0 auto;
    }

    @media #{(_f.query(_c.$breakpoint-l))} {
        justify-content: space-between;
    }

    img,
    [role='img'] {
        display: inline-flex;
        height: auto;
        margin-right: _c.$spacing-l;
        margin-bottom: _f.rem(16);
        max-width: _f.rem(64);
        width: 100%;

        @media #{(_f.query(_c.$breakpoint-s))} {
            margin-right: 0;
            max-width: _f.rem(96);
        }
    }

    > li {
        @include _m.sans--bold;
        list-style: none;
        font-size: _f.rem(20);
        margin: 0;
        padding: _f.rem(8);
        min-width: _f.rem(96);
        width: 100%;

        &:first-child {
            margin-left: 0;
        }

        @media #{_f.query(_c.$breakpoint-s)} {
            text-align: center;
            width: auto;
        }
    }

    a {
        align-items: center;
        justify-content: flex-start;
        display: flex;
        flex-direction: row;
        text-decoration: none;

        &:link,
        &:visited {
            color: _c.$black;
        }

        &:hover {
            color: _c.$blue-highlight;
        }

        @media #{_f.query(_c.$breakpoint-s)} {
            flex-direction: column;
            justify-content: center;
        }
    }

    p {
        margin-bottom: 0;
    }

    span {
        @include _m.sans;
        display: block;
    }
}


// ----------------------------------------------
// Perks Report Section
// ----------------------------------------------

.perks-report__form {
    margin-bottom: _c.$spacing-l;
}

@supports (display:grid) {
    .perks-report__form {
        margin-bottom: 0;
    }
}

@media #{_f.query(_c.$breakpoint-l)} {
    .perks-report__img,
    .perks-report__form {
        float: left;
        width: 50%;
    }

    .perks-report__merchants {
        clear: left;
        padding-top: _c.$spacing-xl;
    }

    @supports (display:grid) {
        .perks-report__img,
        .perks-report__form {
            float: none;
            width: auto;
        }

        .perks-report__merchants {
            padding-top: 0;
        }
    }
}

.perks-report {
    display: grid;
    place-items: start center;
}

.perks-report-heading {
    @include _m.sans--black;

    color: _c.$blue;
    line-height: 1.2;
    font-size: _f.rem(37);

    @media #{_f.query(_f.em(376, 16))} {
        font-size: _f.rem(44);
        text-align: center;
    }
}

.perks-report__title {
    margin-bottom: _c.$spacing-l;
    order: 1;

    p {
        margin: 0 auto;
        max-width: _f.rem(364);
    }
}

.perks-report__img {
    order: 2;
    padding-bottom: _c.$spacing-xm;

    img {
        height: auto;
        max-width: _f.rem(436);
    }
}

.perks-report__form {
    order: 4;

    .box {
        padding: 1rem;

        @media #{_f.query(_c.$breakpoint-s)} {
            padding: _c.$spacing-l _f.rem(40);
        }
    }
}

.perks-report__merchants {
    padding-bottom: _c.$spacing-xm;
    order: 3;
}


@media #{_f.query(_f.em(912, 16))} {
    .perks-report {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
    }

    .perks-report__img,
    .perks-report__merchants {
        grid-column: 1 / 2;
    }

    .perks-report__img {
        align-self: end;
        grid-row: 1 / 3;
    }

    .perks-report__merchants {
        grid-row: 3 / 4;
        margin-bottom: 0;
        padding-bottom: _c.$spacing-l;
    }

    .perks-report__title {
        grid-row: 1 / 2;
    }

    .perks-report__form {
        grid-row: 2 / 4;
        margin-bottom: 0;

        .box {
            max-width: _f.rem(500);
        }
    }
}


// ----------------------------------------------
// Complete Control Section
// ----------------------------------------------

@media #{_f.query(_c.$breakpoint-m)} {
    .complete-control__img {
        float: left;
        width: 50%;
    }

    @supports(display: grid) {
        .complete-control__img {
            float: none;
            width: auto;
        }
    }
}

.complete-control {
    @include _m.clearfix; // for browsers that don't support grid
    display: grid;
    gap: 0 _c.$spacing-m;
    grid-gap: 0 _c.$spacing-m;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);

    .section-heading {
        margin-bottom: _c.$spacing-l;
    }
}

.complete-control__title,
.complete-control__content {
    padding-left: _c.$spacing-m;
    padding-right: _c.$spacing-m;
}

.complete-control__title {
    grid-row: 1 / 2;
    padding-top: _c.$spacing-l;
}

.complete-control__img {
    align-self: end;
    grid-row: 2 / 3;

    img {
        max-width: _f.rem(860);
    }
}

.complete-control__content {
    align-self: center;
    padding-bottom: _c.$spacing-l;
    padding-top: _c.$spacing-l;
}

@media #{_f.query(_f.em(840))} {
    .complete-control {
        grid-template-columns: 1fr 1fr;
    }

    .complete-control__title {
        grid-column: 1 / 2;
        padding-bottom: _c.$spacing-l;
    }

    .complete-control__content {
        grid-row: 1 / 3;
    }
}

@media #{_f.query(_c.$breakpoint-xl)} {
    .complete-control {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, auto);

        .section-heading {
            margin-bottom: 0;
        }
    }

    .complete-control__title,
    .complete-control__content {
        padding-left: 0;
        padding-right: 0;
    }

    .complete-control__img {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }

    .complete-control__content {
        grid-row: 2 / 3;
        padding-bottom: _c.$spacing-l;
    }

    .complete-control__title {
        align-self: end;
        padding-bottom: 0;
    }

    .complete-control__title,
    .complete-control__content {
        grid-column: 2 / 3;
    }

    .complete-control__content {
        grid-row: 2 / 3;
    }
}

@media #{_f.query(_c.$breakpoint-xxl)} {
    .complete-control__img {
        padding-top: _c.$spacing-l;
    }
}

.feature-list {
    color: _c.$blue;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: _c.$spacing-m;
    padding: 0;

    .mobile-icons {
        @media #{_f.query(_c.$breakpoint-s)} {
            margin-bottom: -#{_c.$spacing-s}; // offset bottoms margins for vertical centering
        }

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            width: 100%; // for centering on mobile
        }
    }

    @media #{_f.query(_c.$breakpoint-xxl)} {
        margin-bottom: _c.$spacing-l;
    }
}

.feature-list__item {
    @include _m.sans--semi-bold;

    align-items: center;
    display: flex;
    margin-bottom: _c.$spacing-l;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-s)} {
        margin-bottom: _c.$spacing-m;
        margin-right: _c.$spacing-m;
        width: calc(50% - #{_c.$spacing-m});
    }
}

.feature-list__icon {
    display: inline-flex;
    flex-shrink: 0;
    margin-right: _c.$spacing-m;
    height: 48px;
    width: 48px;
}


// ----------------------------------------------
// What Brings You Here Today Section
// ----------------------------------------------

.today-section-heading {
    text-align: left;
    margin-bottom: _c.$spacing-m;

    @media #{(_f.query(_c.$breakpoint-l))} {
        margin-bottom: _c.$spacing-xl;
        text-align: center;
    }
}

.today-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{(_f.query(_c.$breakpoint-l))} {
        flex-direction: row;
    }

    .today-section__col {
        @include _m.layer-shadow-b;

        background-color: _c.$white;
        border-radius: _c.$border-radius-xl;
        color: inherit;
        display: flex;
        flex-direction: column;
        margin-bottom: _c.$spacing-xm;
        max-width: _f.rem(464);
        padding: _c.$spacing-l _c.$spacing-m;
        text-align: center;
        text-decoration: none;
        transform: translate3d(0, 0, 0);
        transition: 0.25s transform ease-in-out;
        width: 100%;

        &:last-child {
            margin-right: 0;
        }

        @media #{(_f.query(_c.$breakpoint-l, 'max'))} {
            &:nth-child(2) {
                order: -1;
            }
        }

        @media #{(_f.query(_c.$breakpoint-l))} {
            margin: 0 _c.$spacing-xm _c.$spacing-l 0;
            flex: 1 0 calc((100% / 3) - #{(_c.$spacing-xm)});
            max-width: calc((100% / 3) - #{(_c.$spacing-xm)});
        }

        &:hover {
            transform: translate3d(0, -16px, 0);

            h3 {
                color: _c.$blue;
            }
        }

        h3 {
            font-size: _f.rem(18);
            margin-bottom: _c.$spacing-m;

            @media #{_f.query(_c.$breakpoint-xxl)} {
                font-size: _f.rem(22);
                margin-bottom: _c.$spacing-l;
            }
        }

        p {
            font-size: _f.rem(18);
            margin: 0 auto _c.$spacing-l;
            max-width: 100%;

            @media #{(_f.query(_c.$breakpoint-l))} {
                max-width: _f.rem(290);
            }

            @media #{_f.query(_c.$breakpoint-xxl)} {
                font-size: _f.rem(20);
                margin-bottom: _c.$spacing-xl;
            }
        }

        .disneyland-text {
            max-width: 100%;

            @media #{_f.query(_c.$breakpoint-xxl)} {
                margin-bottom: _c.$spacing-l;
            }
        }
    }

    .today-section__col--no-pad {
        padding: _c.$spacing-l 0;

        header {
            padding: 0 _c.$spacing-m;
        }
    }

    .today-section__body {
        align-items: center;
        display: flex;
        flex: 1 0 auto;
    }

    .today-section-img {
        display: block;
        height: auto;
        margin: 0 auto;
        width: _f.rem(288);
    }
}
