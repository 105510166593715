@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

.perks-widget-iframe {
    border: 1px solid #ccc;
    height: 400px;
    margin-bottom: _c.$spacing-m;
    width: 100%;
}

.perks-widget-iframe--browser {
    border: 0;
    border-radius: 0 0 6px 6px;
    height: 600px;
    margin-bottom: 0;
}

.perks-widget-layout {
    background-color: rgb(250, 250, 250);
    max-width: 100%;

    main {
        max-width: 100%;
        min-height: 0;
    }
}

.perks-body {
    background-color: rgb(250, 250, 250);
    margin-left: auto;
    margin-right: auto;
    max-width: _c.$main-width;
    padding: _c.$spacing-l;

    h2 {
        border-bottom: 1px solid #ccc;
        color: _c.$gray-dark;
        font-size: _f.rem(21);
    }
}

.perks-widget {
    display: flex;
    flex-direction: column;

    @media #{_f.query(_c.$breakpoint-m)} {
        flex-direction: row;
    }
}

.perks-widget__cats {
    @media #{_f.query(_c.$breakpoint-m)} {
        width: 20%;
    }

    ul {
        padding-left: 0;
    }

    ul > li {
        list-style: none;
        margin-bottom: _c.$spacing-s;
        margin-left: 0;
    }

    ul ul > li {
        font-size: _f.rem(14);
        list-style-type: disc;
        margin-bottom: 0;
        margin-left: 1.5em;
    }
}

.perks-widget__offers {
    @media #{_f.query(_c.$breakpoint-m)} {
        padding-left: 24px;
        width: 80%;
    }

    ul li {
        margin-bottom: _c.$spacing-xs;

        p {
            margin-bottom: 0;
        }
    }
}

.offer-list {
    display: flex;
    flex-wrap: wrap;
}

.offer-list__item {
    display: flex;
    flex-direction: column;
    padding: _c.$spacing-s;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-s)} {
        width: 50%;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        width: math.div(100%, 3);
    }

    img {
        display: block;
        margin: 0 auto _c.$spacing-m;
        max-height: _f.rem(50);
    }

    h3 {
        @include _m.word-wrap;
        color: _c.$blue;
        text-align: center;
    }

    ul {
        font-size: _f.rem(14);
    }
}

.perks-widget__box {
    background-color: rgb(255, 255, 255);
    border-radius: _c.$border-radius-l;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.15);
    flex: 1;
    padding: _c.$spacing-m;

    ul {
        padding-left: 1em;
    }
}
