@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.social-network-list {
    align-items: center;
    display: flex;
    justify-content: center;
}

.social-network__link {
    color: _c.$blue;
    margin: 0 _c.$spacing-s;

    &:hover {
        color: _c.$blue-highlight;
    }

    svg {
        height: _f.rem(48);
        width: auto;
    }
}
