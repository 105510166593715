@use '../config' as _c;
@use '../function' as _f;

hr {
    border: 0;
    border-top: 1px dashed _c.$gray-med;
    display: block;
    height: 1px;
    margin: 0;
    padding: 0;
}

// Used in the footer
hr.copyright-line {
    border-top: 1px solid #536b8d;
    margin: 0 auto _c.$spacing-m;
    max-width: 85px;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-s)} {
        display: none;
    }
}
