$abenity-sans-serif: 'Gotham A', 'Gotham B', 'Montserrat', Helvetica, Arial, sans-serif;

//
//   @sans
// --------------------------------------------------------
//
//  use @include sans for this font
//
// --------------------------------------------------------

@mixin sans {
    font-family: $abenity-sans-serif;
    font-weight: 400;
}

@mixin sans--semi-bold {
    font-family: $abenity-sans-serif;
    font-weight: 500;
}

@mixin sans--bold {
    font-family: $abenity-sans-serif;
    font-weight: 700;
}

@mixin sans--black {
    font-family: $abenity-sans-serif;
    font-weight: 800;
}
