
// ----------------------------------------------
// Base images
// ----------------------------------------------

//  1. Fluid images for responsive purposes.
//  2. Offset `alt` text from surrounding copy.
//  3. Setting `vertical-align` removes the whitespace that
//     appears under `img` elements when they are dropped
//     into a page as-is. Safer alternative to using
//     `display: block;`.
// ---------------------------------------------------------

img {
    font-style: italic; // [2]
    height: auto;
    max-width: 100%; // [1]
    vertical-align: middle; // [3]
    width: 100%;
}


// ----------------------------------------------
// Natural image size
// ----------------------------------------------

//  Natural Images are 100% max-width, but their natural width by default
//
//  Example: Image, Natural Width
//  <img class="img--natural" src="http://fillmurray.com/600/300">
// --------------------------------------------------------

.img--natural img,
.img--natural {
    width: auto;
}


// ----------------------------------------------
// Make an image a circle
// ----------------------------------------------

.img--circle {
    border-radius: 50%;
}

%img-size {
    display: block;
    height: auto;
}

.img--small {
    @extend %img-size;
    max-width: 128px;
}

.img--med {
    @extend %img-size;
    max-width: 204px;
}
