@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


// Show a border while an iframe is loading
.iframe {
    border: 1px solid _c.$gray;

    &.lazyloaded {
        border: 0;
    }
}
