//
//   @cover
// ---------------------------------------------------------
//
//  Utility class to force an element to cover its positioned
//  parent element.
//
// ---------------------------------------------------------

@mixin cover {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
