// ---------------------------------------------------------
//  Word Wrap
// ---------------------------------------------------------
//  Prevents long lies from breaking out of the container
//  https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
// ---------------------------------------------------------

@mixin word-wrap {
    // These are technically the same, but use both
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    // This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    // Instead use this non-standard one:
    word-break: break-word;

    // Adds a hyphen where the word breaks, if supported (No Blink)
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}


@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
