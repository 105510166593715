@use '../config' as _c;

// ----------------------------------------------
// Loading state for modals
// ----------------------------------------------
@keyframes bounce {
    80%, 100% {
        opacity: 0.2;
        transform: translate3d(0, 16px, 0);
    }
}

.loading-animation {
    padding: _c.$spacing-l;
}

.loading-animation__circles {
    display: flex;
    justify-content: center;
    padding: 1rem;

    .circle {
        animation: 0.9s bounce infinite alternate;
        background-color: _c.$blue;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        margin: 0 8px;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}
