// Heading
//
// Buttons can and should be clicked.
//
// Markup:
// <button class="btn">Primary Button</button>
//
// :hover - Highlight the button when hovered.
// :disabled - Disabled state
//
// Styleguide Buttons

@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

button {
    &:focus,
    &:active {
        outline: 0;
    }
}

.btn {
    @include _m.sans--bold;

    background-color: _c.$green;
    border: 1px solid _c.$green;
    align-items: center;
    border-radius: 99em;
    color: _c.$white;
    cursor: pointer;
    display: inline-flex;
    font-size: _f.rem(16);
    justify-content: center;
    overflow: hidden;
    padding: _f.rem(12) _c.$spacing-l;
    position: relative;
    text-decoration: none;
    transition: background-color ease-in-out 0.2s;

    @media #{_f.query(_c.$breakpoint-s)} {
        padding: _f.rem(12) _c.$spacing-xl;
    }

    &:link,
    &:visited {
        color: _c.$white;
    }

    &:hover,
    &:focus {
        background-color: _c.$green-light;
        text-decoration: none;
    }

    &[disabled] {
        opacity: 0.5;

        &:hover {
            cursor: not-allowed;
        }
    }
}

.btn--stretch {
    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        width: 100%;
    }
}

.btn--wide {
    @media #{_f.query(_c.$breakpoint-l)} {
        padding: _f.rem(12) _c.$spacing-xxxl;
        min-width: _f.rem(304);

        &.btn--icon {
            padding-right: (_c.$spacing-xxxl - _f.rem(24));
        }
    }
}

.btn--icon {
    padding-right: (_c.$spacing-l - _f.rem(24));

    @media #{_f.query(_c.$breakpoint-s)} {
        padding-right: (_c.$spacing-xl - _f.rem(24));
    }
}

.btn__icon {
    height: _f.rem(24);
    margin-left: _c.$spacing-s;
    width: _f.rem(24);

    @media #{_f.query(_c.$breakpoint-s)} {
        margin-left: _c.$spacing-xm;
    }
}

.btn--outline {
    background-color: _c.$white;
    color: _c.$green;

    &:link,
    &:visited {
        color: _c.$green;
    }

    &:hover,
    &:focus {
        background-color: _c.$white;
        border-color: _c.$green-light;
        color: _c.$green-light;
    }
}


// ----------------------------------------------
// wrapper element to center button
// ----------------------------------------------

.btn--center {
    display: flex;
    justify-content: center;

    .btn + .btn {
        margin-left: _c.$spacing-m;
    }
}


// ----------------------------------------------
// Button that looks like a link
// ----------------------------------------------

.btn-text {
    background-color: transparent;
    border: 0;
    color: _c.$blue;
    padding: 0;
    text-decoration: underline;
    transition: color 0.15s ease-in;

    &:hover,
    &:focus {
        color: _c.$blue-highlight;
    }
}

span.btn-text {
    cursor: pointer;
}
