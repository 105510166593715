@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


// ----------------------------------------------
// Field type definition
// ----------------------------------------------

$field-types: "input:not([type='checkbox']):not([type='radio']), select:not([multiple]), textarea";

// ----------------------------------------------
// Form Spacing and Dimensions
// ----------------------------------------------

form {
    line-height: 1.5;
}

.form--full,
.field--full {
    #{$field-types} {
        width: 100%;
    }
}

.field {
    padding: _c.$spacing-s 0;
}

.field--center {
    display: flex;
    justify-content: center;
}

// ----------------------------------------------
// Labels
// ----------------------------------------------

label,
.label {
    @include _m.sans--bold;

    display: block;
    line-height: inherit;
    margin-bottom: _f.rem(4);

    &.is-required {
        &::after {
            color: _c.$red;
            content: '*';
            display: inline-block;
            left: 100%;
            margin-left: _f.rem(4);
        }
    }

    &.label--text-400 {
        @include _m.sans;
    }

    &.label--sr-only {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}


// ----------------------------------------------
// Hints
// ----------------------------------------------

.field__desc,
.label--text {
    @include _m.sans;

    font-size: _f.rem(14);
    margin-top: _c.$spacing-s;

    p,
    ul {
        font-size: _f.rem(14);
    }

    ul,
    ol {
        counter-reset: li;
        list-style: none;
        margin: 0 0 0 1.75em;
    }

    li {
        position: relative;

        &::before {
            padding-right: 0.5em;
            position: absolute;
            right: 100%;
            text-align: right;
        }
    }

    a {
        color: _c.$blue;
        cursor: pointer;
        text-decoration: underline;
        transition: color 0.15s ease-in;

        &:visited {
            color: _c.$blue;
        }

        &:hover,
        &:focus {
            color: _c.$blue-light;
        }
    }

    &.field__desc--inline {
        color: _c.$gray;
        margin-top: 0;
        padding-left: _f.rem(8);
    }
}

.field__desc {
    color: _c.$gray-dark;
}


// ----------------------------------------------
// Standard Inputs
// ----------------------------------------------
.field {
    #{$field-types} {
        -webkit-appearance: none;
        background-color: _c.$white;
        border: 1px solid _c.$slate;
        border-radius: 99em;
        font: inherit;
        line-height: inherit;
        padding: _f.rem(16) _f.rem(32);
        transition: all 0.15s ease-in;

        &:hover,
        &:focus {
            border-color: _c.$slate-dark;
        }

        &:focus {
            outline: 0;
        }

        .disabled &,
        [disabled] &,
        &[disabled],
        &[readonly] {
            background-color: _c.$gray-light;
            cursor: not-allowed;

            &:hover {
                border: 1px solid _c.$gray-med;
            }
        }

        &::placeholder {
            color: #50596D;
        }
    }

    &.has-error {
        #{$field-types},
        .field-addon--bd {
            border-color: _c.$red !important;
        }

        label,
        .label {
            color: _c.$red;
        }
    }

    textarea {
        border-radius: 2em;
        resize: none;
    }

    // Styling for select with options, not multi select
    select:not([multiple]) {
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: _c.$slate-light;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23272a2e' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
        background-position: right 32px top 50%;
        background-repeat: no-repeat;
        background-size: 12px auto;
        line-height: inherit;
        padding-right: 32px;

        &:-moz-focusring { // get rid of the focus border in Firefox
            color: transparent;
            text-shadow: 0 0 0 #000;
        }

        &::-ms-expand { // removes the arrow in IE 10
            display: none;
        }
    }

    // Disable arrows on number inputs
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // :not selectors are needed to override $field-types selectors
    input[type='number']:not([type='checkbox']):not([type='radio']) {
        -moz-appearance: textfield;
    }
}


// ----------------------------------------------
// Field Inline
// ----------------------------------------------

.label-inline {
    margin-bottom: 0;
    padding-bottom: calc(#{_f.rem(16)} + 1px); // input bottom padding plus 1px border
    padding-top: calc(#{_f.rem(16)} + 1px); // input top padding plus 1px border
}


// ----------------------------------------------
// Field Floating
// ----------------------------------------------

.field--floating {
    margin-bottom: _c.$spacing-m;
    padding: 0;
    position: relative;

    label:not(.label--sr-only),
    .label:not(.label--sr-only) {
        @include _m.sans;

        background-color: _c.$white;
        border: 1px solid transparent;
        color: #50596D;
        // font-size: _f.rem(14);
        // height: 100%;
        left: _f.rem(32);
        pointer-events: none;
        position: absolute;
        top: _f.rem(12);
        transform-origin: 0 0;
        transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    }

    #{$field-types} {
        padding: _f.rem(12) _f.rem(32);

        &::placeholder {
            color: transparent;
        }

        &:not(:placeholder-shown),
        &:focus {
            ~ label:not(.label--sr-only),
            ~ .label:not(.label--sr-only) {
                // opacity: 0.65;
                // padding: 0;
                transform: scale(0.85) translateY(_f.rem(-26)) translateX(.15rem);
            }
        }
    }
}


// ----------------------------------------------
// Small Fields
// ----------------------------------------------

.field--small {
    #{$field-types} {
        background-color: _c.$white;
        border: 0;
        border-radius: _c.$border-radius-m;
        padding: _c.$spacing-s;
    }

    // Styling for select with options, not multi select
    select:not([multiple]) {
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23272a2e' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
        background-position: right 16px top 50%;
        background-repeat: no-repeat;
        background-size: 12px auto;
        line-height: inherit;
        padding-right: 32px;

        &:-moz-focusring { // get rid of the focus border in Firefox
            color: transparent;
            text-shadow: 0 0 0 #000;
        }

        &::-ms-expand { // removes the arrow in IE 10
            display: none;
        }
    }
}

// ----------------------------------------------
// Checkbox and Radio Custom Input Styles
// ----------------------------------------------

@supports(appearance: none) or (-moz-appearance: none) or (-webkit-appearance: none) {
    .field {
        input[type='checkbox'],
        input[type='radio'] {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            background-color: _c.$white;
            border: 1px solid _c.$slate-light;
            cursor: pointer;
            display: inline-block;
            height: _f.rem(24);
            margin-right: _c.$spacing-m;
            outline: 0;
            position: relative;
            transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
            vertical-align: top;
            width: _f.rem(24);

            &::after {
                content: '';
                display: block;
                left: 5px;
                opacity: 0;
                position: absolute;
                top: 5px;
                transition: opacity ease-in-out 0.2s;
            }

            &:checked {
                background: _c.$blue-light;
                border-color: _c.$blue-light;
            }

            &:checked::after {
                opacity: 1;
            }

            &:disabled {
                background-color: _c.$gray-light;
                cursor: not-allowed;

                &:hover {
                    border: 1px solid _c.$gray-med;
                }

                &:checked {
                    border-color: _c.$gray-light;
                }

                & + label {
                    color: _c.$gray;
                    cursor: not-allowed;
                }
            }

            &:hover,
            &:focus {
                &:not(:checked) {
                    &:not(:disabled) {
                        border-color: _c.$slate;
                    }
                }
            }

            &:focus {
                outline: 0;
            }

            & + label {
                cursor: pointer;
                display: inline-block;
                margin-left: _f.rem(4);
                vertical-align: top;
            }
        }

        input[type='checkbox'] {
            border-radius: _c.$border-radius-m;
            padding: _f.rem(4);

            &::after {
                display: block;
                height: 8px;
                line-height: 0;
                width: 12px;
                z-index: 5;
            }

            &:checked::after {
                content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204L43.7 200.596c9.998-9.998 26.208-9.998 36.205 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0L504.5 108.8c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.4c-9.998 9.998-26.207 9.998-36.204 0z"/></svg>');
            }
        }

        input[type='radio'] {
            border-radius: 50%;

            &::after {
                background: _c.$white;
                border-radius: 50%;
                content: '';
                display: block;
                height: 10px;
                left: 6px;
                top: 6px;
                width: 10px;
                z-index: 5;
            }
        }

        &.has-error {
            input[type='checkbox'],
            input[type='radio'] {
                border-color: _c.$red;
                color: _c.$red;
            }
        }
    }
}

.field--checked {
    display: flex;
}

.field--checked__input {
    width: _f.rem(24);
}

.field--checked__label {
    flex: 1 1 auto;
    padding-left: _c.$spacing-m;
}

// --------------------------------------------------------
//  Field Addon
// --------------------------------------------------------

// password-input is included separately to play well
// with how form errors are added by JS
.field.field-addon,
.password-input.field-addon {
    background-color: _c.$white;
    border-radius: 99em;
    display: flex;
    padding: 0;

    &.has-focus {
        box-shadow: 0 0 0 2px _c.$blue;
    }

    &.field-addon--bd {
        border: 1px solid _c.$slate-light;
        transition: all 0.15s ease-in;

        &:hover {
            border-color: _c.$slate;
        }
    }

    &.field-addon--small {
        border-radius: _c.$border-radius-m;

        input.field-addon__input {
            // border-radius: _c.$border-radius-m;
            padding: _c.$spacing-s;
        }

        .field-addon__item-btn {
            padding-left: _c.$spacing-s;
            padding-right: _c.$spacing-s;
        }
    }

    input.field-addon__input { // input needed to override :not selectors
        border: 0;
        flex: 1 0 0%;
        margin: 0;
        width: 100%;
    }

    .field-addon__item {
        cursor: pointer;
        outline: 0;
        position: relative;

        &.btn {
            padding-left: _c.$spacing-l;
            padding-right: _c.$spacing-l;

            &.btn--icon {
                padding-right: (_c.$spacing-l - _f.rem(16)); // minus icon width
            }
        }
    }

    .field-addon__item-btn {
        background-color: transparent;
        border: 0;
        color: _c.$gray-med;
        padding-left: _c.$spacing-l;
        padding-right: _c.$spacing-l;

        &:hover {
            color: _c.$blue-highlight;
        }
    }

    .field-addon__item-btn--text {
        color: _c.$gray-dark;
    }

    .field-addon__item__icon {
        height: _f.rem(24);
        width: _f.rem(24);
    }
}
