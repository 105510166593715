@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.social-mission-masthead {
    background-image: url('../images/masthead/social-mission-masthead.jpg');
    background-position: left bottom;
    padding-top: _f.rem(100);

    .masthead-content {
        margin: auto;
        max-width: _c.$main-width;
    }

    .masthead-heading {
        margin: 0;
    }

    .masthead-heading,
    .masthead-quote {
        max-width: _f.rem(448);
    }

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        background-image: url('../images/masthead/social-mission-masthead-small.jpg');
        padding-top: _f.rem(160);

        .masthead-heading {
            margin: auto;
            max-width: _f.rem(432);
        }

        .masthead-content {
            margin-top: 0;
        }

        .masthead-quote {
            display: none;
        }
    }
}

.impact-report-preview {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        img {
            max-width: 675px;
            width: 675px;
        }
    }
}

.impact-cover {
    float: right;
    max-width: _f.rem(520);
}
