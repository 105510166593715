//
//   @multimedia
// ---------------------------------------------------------
//
//  For video and object responsive sizing.
//
// ---------------------------------------------------------

.multimedia {
    height: 0;
    margin: 0 auto;
    // 16:9 aspect ratio
    padding-bottom: 56.25%;
    position: relative;
}

.multimedia embed,
.multimedia object,
.multimedia video,
.multimedia iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0 !important; // to overwrite margins that may be set in other stylesheets
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}


.multimedia--3-4 {
    padding-bottom: 133.3333333%;
}

// ratio rules
// widescreen already accounted for in default rule
.multimedia--standard {
    padding-bottom: 75%;
}
