@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

$perks-masthead-bottom-offset: 145px;

.perks-for-masthead {
    background-color: _c.$gray-light-med;
    position: relative;

    @media #{_f.query(_c.$breakpoint-s)} {
        padding-left: _c.$spacing-l;
        padding-right: _c.$spacing-l;

        &::after {
            background-color: _c.$white;
            bottom: 0;
            content: '';
            height: $perks-masthead-bottom-offset;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 1;
        }
    }

    .masthead-heading {
        font-size: _f.rem(48);
        line-height: _f.rem(56);
        margin-bottom: 0;
        max-width: _f.rem(484);
        text-align: left;

        mark {
            display: block;
            font-size: _f.rem(48);

            @media #{_f.query(_c.$breakpoint-s)} {
                font-size: _f.rem(72);
            }
        }

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            font-size: _f.rem(32);
            line-height: _f.rem(48);
            margin-left: _c.$spacing-l;
            margin-right: _c.$spacing-l;
            text-align: center;
        }
    }

    .box {
        position: relative;
        z-index: 5;
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        .box {
            border-radius: 0;
            box-shadow: none;
        }

        .box__header {
            border-radius: 0;
        }

        h2 {
            text-align: center;
        }
    }
}

.perks-for-masthead__inner {
    padding-top: _f.rem(124);
    margin: 0 auto;
    max-width: _c.$main-width;

    @media #{_f.query(_c.$breakpoint-l)} {
        display: flex;

        .perks-for-masthead__intro {
            width: 60%;
        }

        .box {
            width: 40%;
        }
    }
}

@supports (display:grid) {
    .perks-for-masthead__inner {
        display: grid !important;

        @media #{_f.query(_c.$breakpoint-l)} {
            grid-template-columns: 1fr 530px;
        }

        @media #{_f.query(_c.$breakpoint-xxl)} {
            grid-template-columns: minmax(370px, 870px) minmax(0, 530px);
        }

        .perks-for-masthead__intro,
        .box {
            width: auto;
        }
    }
}

.perks-for-masthead__intro {
    align-self: center;

    @media #{_f.query(_c.$breakpoint-xl)} {
        align-self: flex-end;
    }

    .small-img {
        @media #{_f.query(_c.$breakpoint-s)} {
            display: none;
        }
    }
}

@supports (display:grid) {
    .perks-for-masthead__intro {
        align-self: center;

        @media #{_f.query(_c.$breakpoint-xl)} {
            align-self: end;
        }
    }
}

.perks-for-masthead__text {
    background-color: #dedfe1;
    padding: _c.$spacing-l;

    @media #{_f.query(_c.$breakpoint-s)} {
        background-color: #f4f4f4;
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        margin-bottom: $perks-masthead-bottom-offset;
        padding-left: 0;
        padding-top: _f.rem(56);
    }

    @media #{_f.query(_c.$breakpoint-xl)} {
        background: no-repeat _c.$gray-light-med 20px 100%
            image-set(
                url('../images/masthead/masthead-alumni.jpg') 1x,
                url('../images/masthead/masthead-alumni@2x.jpg') 2x
            );
        min-height: 510px;
        padding-bottom: 0;
        padding-right: _f.percent(490, 870);
    }
}

@supports (display:grid) {
    .perks-for-masthead__text {
        @media #{_f.query(_c.$breakpoint-xl)} {
            background-position: -40px 100%;
        }

        @media #{_f.query(_c.$breakpoint-xxl)} {
            background-position: right bottom;
        }
    }
}

.perks-for-masthead-list {
    padding-left: 1em;

    > li {
        padding-left: _c.$spacing-m;
    }

    h2 {
        @include _m.sans--semi-bold;

        font-size: _f.rem(16);
    }

    p {
        color: _c.$gray-dark;
        font-size: _f.rem(14);
    }
}

.avatar-layout {
    align-items: center;
    display: flex;

    .avatar-layout__text {
        flex: 1 1 auto;
        padding-right: _c.$spacing-xm;

        p {
            color: _c.$gray-dark;
        }
    }

    .avatar-layout__img {
        flex-shrink: 0;
        width: 128px;
    }


    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        flex-direction: column;
        text-align: center;

        .avatar-layout__text {
            padding-right: 0;
        }

        .avatar-layout__img {
            margin-bottom: _c.$spacing-m;
            order: -1;
        }
    }
}

.avatar-layout__heading {
    @include _m.sans--semi-bold;
    font-size: _f.rem(24);
}

.features-icons {
    display: flex;

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        align-items: center;
        flex-direction: column;
    }
}

.features-icons__item {
    text-align: center;

    @media #{_f.query(_c.$breakpoint-s)} {
        padding: _f.rem(30);
        width: 80%;
    }

    @media #{_f.query(_c.$breakpoint-xl)} {
        width: math.div(100%, 3);
    }
}

.features-icons__img {
    background-color: #e3e1df;
    border-radius: 50%;
    height: _f.rem(200);
    margin: 0 auto _c.$spacing-l;
    padding: _f.rem(40);
    width: _f.rem(200);
}

.features-icons__heading {
    font-size: _f.rem(20);
    border-bottom: 1px solid _c.$gray;
    margin-bottom: _c.$spacing-m;
    padding-bottom: _c.$spacing-s;
    text-transform: uppercase;

    @media #{_f.query(_c.$breakpoint-xl)} {
        font-size: _f.rem(24);
    }
}
.features-icons__text {
    color: _c.$gray-dark;
}
