@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

%alert-text {
    color: _c.$black;
    font-size: _f.rem(14);

    // avoid styling things like buttons or js hook selectors
    a:not([class]),
    a[class|='js'] {
        &:link,
        &:visited {
            color: _c.$blue;
        }

        &:hover,
        &:focus {
            color: _c.$blue-light;
        }
    }
}

%alert-box {
    border-radius: _c.$border-radius-m;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1),
                0 1px 5px 0 rgba(0, 0, 0, 0.1);
    color: _c.$black;
    padding: _f.rem(8) _f.rem(16);
    position: relative;

    // @import 'lists';

    ul,
    ol {
        padding-left: 1em;
    }

    // no bottom margin on last element
    > :last-child {
        margin-bottom: 0;
    }

    [role='img'] {
        display: inline-block;
        height: _f.rem(16);
        left: _f.rem(8);
        position: absolute;
        top: _f.rem(10);
        width: _f.rem(16);
    }

    .alert-heading {
        font-size: _f.rem(14);
        font-weight: 700;
        text-transform: uppercase;
    }

    &.has-icon {
        background-position: _f.rem(12) _f.rem(8);
        background-repeat: no-repeat;
        background-size: 24px auto;
        padding-left: _f.rem(48);
    }
}

.alert-box--icon {
    padding-left: _f.rem(32);
}

.form-alerts {
    display: none;
}

.form-processing {
    @extend %alert-text;
    @extend %alert-box;

    background-color: #e8e8e8;
    color: _c.$black;
    display: none;
    font-size: _f.rem(14);
    margin-bottom: _f.rem(16);
    margin-top: _f.rem(16);
    text-shadow: none;
}

.success-box {
    @extend %alert-text;
    @extend %alert-box;

    background-color: #E6F8D5;
    border: 1px solid #0F983C;
    color: _c.$black;
    text-shadow: none;

    a {
        &:link,
        &:visited,
        &:hover,
        &:focus {
            color: _c.$green;
        }
    }

    [role='img'] {
        fill: _c.$green;
    }
}

.info-box {
    @extend %alert-text;
    @extend %alert-box;
    background-color: #EEE;
    border: 1px solid #CCC;
    text-shadow: none;

    a {
        &:link,
        &:visited,
        &:hover,
        &:focus {
            color: _c.$gray;
        }
    }
}

.warning-box {
    @extend %alert-text;
    @extend %alert-box;
    background-color: #F9F9D6;
    border: 1px solid #F4C301;
    text-shadow: none;
}

.error-box {
    @extend %alert-text;
    @extend %alert-box;
    background-color: #FFEBE8;
    border: 1px solid #DD3C10;

    li {
        font-style: italic;
    }
}

.alert--red {
    color: _c.$red;
    font-size: _f.rem(14);
}

.alert--green {
    color: _c.$green;
    font-size: _f.rem(14);
}
