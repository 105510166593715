@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

// ----------------------------------------------
// Padding
// ----------------------------------------------
.u-padding--b-xxl {
    padding-bottom: _c.$spacing-xxl !important;
}

.u-padding--t-s {
    padding-top: _c.$spacing-s !important;
}

.u-padding--t-xxl {
    padding-top: _c.$spacing-xxl  !important;
}

// ----------------------------------------------
// Margins
// ----------------------------------------------

.u-margin--b-0 {
    margin: 0;
}

.u-margin--b-xs {
    margin-bottom: _c.$spacing-xs !important;
}

.u-margin--b-s {
    margin-bottom: _c.$spacing-s !important;
}

.u-margin--b-m {
    margin-bottom: _c.$spacing-m !important;
}

.u-margin--b-xm {
    margin-bottom: _c.$spacing-xm !important;
}

.u-margin--b-l {
    margin-bottom: _c.$spacing-l !important;
}

.u-margin--b-lm {
    margin-bottom: _c.$spacing-lm !important;
}

.u-margin--b-xl {
    margin-bottom: _c.$spacing-xl !important;
}

.u-margin--b-xxl {
    margin-bottom: _c.$spacing-xxl !important;
}

.u-margin--b-xxml {
    margin-bottom: _c.$spacing-xxml !important;
}

.u-margin--b-xxxl {
    margin-bottom: _c.$spacing-xxxl !important;
}

.u-margin--t-m {
    margin-top: _c.$spacing-m !important;
}

.u-margin--t-xl {
    margin-top: _c.$spacing-xl  !important;
}


// ----------------------------------------------
// Responsive Margins
// ----------------------------------------------

.u-margin--s--b-m {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        margin-bottom: _c.$spacing-m;
    }
}

.u-margin--s--b-l {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        margin-bottom: _c.$spacing-l;
    }
}

.u-margin--m--b-xxxl {
    @media #{_f.query(_c.$breakpoint-m)} {
        margin-bottom: _c.$spacing-xxxl;
    }
}

.u-margin--xxl--b-l--max {
    @media #{_f.query(_c.$breakpoint-xxl, 'max')} {
        margin-bottom: _c.$spacing-l;
    }
}

.u-margin--xxl--b-xxxl {
    @media #{_f.query(_c.$breakpoint-xxl)} {
        margin-bottom: _c.$spacing-xxxl;
    }
}

.u-margin--resp-l--0--xl {
    margin-bottom: 0;

    @media #{_f.query(_c.$breakpoint-l)} {
        margin-bottom: _c.$spacing-xl;
    }
}


// ----------------------------------------------
// Responsive Show/Hide
// ----------------------------------------------

.u-resp-hide--m {
    @media #{_f.query(_c.$breakpoint-m)} {
        display: none;
    }
}

.u-resp-hide--m--max {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        display: none;
    }
}

.u-resp-hide--s--max {
    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        display: none;
    }
}


// ----------------------------------------------
// Images
// ----------------------------------------------

img.u-center--img,
.u-center--img img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


// ----------------------------------------------
//  Text
// ----------------------------------------------

.u-ta--left {
    text-align: left !important;
}

.u-ta--center {
    text-align: center !important;
}

.u-ta--right {
    text-align: right !important;
}

.u-tt--upper {
    letter-spacing: 0.025em;
    text-transform: uppercase !important;
}


// ----------------------------------------------
// Colors
// ----------------------------------------------

.bg--blue {
    background-color: _c.$blue !important;
}

.bg--gray-light {
    background-color: _c.$gray-light !important;
}

.bg--gray-light-med {
    background-color: _c.$gray-light-med !important;
}

.bg--slate-x-light {
    background-color: _c.$slate-x-light !important;
}

.bg--white {
    background-color: _c.$white !important;
}

.u-c--green {
    color: _c.$green !important;
}

.u-c--red {
    color: _c.$red !important;
}

.u-c--black {
    color: _c.$black !important;
}

.u-c--blue {
    color: _c.$blue !important;
}

.u-c--white {
    color: _c.$white !important;
}

.u-c--gray {
    color: _c.$gray !important;
}

.u-c--dark-gray {
    color: _c.$gray-dark !important;
}


// ----------------------------------------------
// Positioning
// ----------------------------------------------

.u--pos-r {
    position: relative;
}


// ----------------------------------------------
// Show/Hide
// ----------------------------------------------

.is-hidden {
    display: none;
}
