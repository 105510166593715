@use '../config' as _c;
@use '../function' as _f;
@use 'sass:math';

.top-brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: _c.$main-width;

    @media #{_f.query(_c.$breakpoint-m)} {
        padding: 0 _f.rem(16);
    }
}

.top-brands-heading {
    margin-bottom: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m)} {
        margin-bottom: _c.$spacing-l;
    }
}

.top-brands__item {
    margin: 0;
    padding: 0 _f.rem(8);
    width: 50%;

    @media #{_f.query(_c.$breakpoint-s)} {
        width: 25%;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        width: auto;
    }
}

.top-brands__img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-height: _f.rem(96);
    max-width: 100%;
    width: auto;
}

.top-brands--responsive {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        .top-brands__item {
            width: 100%;
        }
    }

    &::after {
        content: 'flickity';
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        .top-brands__img {
            height: auto;
            max-height: _f.rem(128);
            max-width: 100%;
            width: auto;
        }
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        &::after {
            content: '';
        }
    }
}

.top-brands--8 {
    @media #{_f.query(_c.$breakpoint-l)} {
        .top-brands__item {
            width: math.div(100%, 8);
        }
    }
}

.top-brands--slider .top-brands__item {
    width: 100%;

    @media #{_f.query(_c.$breakpoint-m)} {
        width: 20%;
    }
}

.top-brands-section {
    background: _c.$blue url('../images/top-brands/bg/top-brands-section-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding: _c.$spacing-xxxl 0;

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        background-image: url('../images/top-brands/bg/top-brands-section-bg-mobile.jpg');
    }

    .top-brands--responsive {
        @media #{_f.query(_c.$breakpoint-xl)} {
            justify-content: space-between;
        }
    }

    .flickity-button {
        color: #e9e9e9;
    }

    .flickity-slider {
        align-items: center;
        display: flex;
    }
}

.top-brands-section--universal {
    background-image: url('../images/top-brands/bg/top-brands-section-bg-universal.jpg');
    background-position: center bottom;
}

.top-brands-section--quote {
    padding: _c.$spacing-xl 0;

    .section-heading,
    .section-tagline {
        text-align: center;

        @media #{_f.query(_c.$breakpoint-l)} {
            text-align: left;
        }
    }

    .section-heading {
        font-size: _f.rem(26);

        @media #{_f.query(_c.$breakpoint-m)} {
            font-size: _f.rem(50);
        }
    }

    .section-tagline {
        font-size: _f.rem(18);

        @media #{_f.query(_c.$breakpoint-m)} {
            font-size: _f.rem(34);
        }
    }

    @media #{_f.query(_c.$breakpoint-l, 'max')} {
        .l--max--order-first {
            margin-bottom: _c.$spacing-l;
        }
    }
}
