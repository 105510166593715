@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.login-heading {
    @include _m.sans--semi-bold;
    font-size: _f.rem(18);
    text-align: center;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(24);
    }

    span {
        @include _m.sans--black;
        font-size: _f.rem(32);
        display: block;

        @media #{_f.query(_c.$breakpoint-m)} {
            color: _c.$blue;
            font-size: _f.rem(42);
            display: inline;
        }
    }
}

.login-form {
    margin: 0 auto;
    position: relative;
    max-width: _f.rem(1008);
}

.login-form-inputs {
    margin-left: auto;
    margin-right: auto;
    max-width: _f.rem(432);
    width: 100%;

    input {
        width: 100%;
    }
}

.login-options-btn,
.login-options-list {
    @include _m.layer-shadow-c;

    background-color: _c.$white;
    border: 1px solid #d6dbe5;
    border-radius: _c.$border-radius-l;
    width: 100%;
}

.login-options-btn {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%236e84a0' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
    background-position: right 24px top 50%;
    background-repeat: no-repeat;
    background-size: 12px auto;
    border: 0;
    display: block;
    margin-bottom: _c.$spacing-xxl;
    padding: _c.$spacing-m 32px _c.$spacing-m _c.$spacing-xm;
    text-align: left;

    @media #{_f.query(_c.$breakpoint-m)} {
        display: none;
    }
}

.login-options-list {
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 5;

    &.is-visible {
        display: block;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        background-color: transparent;
        box-shadow: none;
        border: 0;
        display: block;
        position: static;
    }
}

.login-options {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media #{_f.query(_c.$breakpoint-m)} {
        flex-direction: row;
        margin-bottom: _c.$spacing-xxl;
    }
}

.login-options__radio {
    border-bottom: 1px solid #F3F4F8;

    &:last-child {
        border: 0;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        border: 0;
        padding: _c.$spacing-m;
        width: 50%;
    }

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        &:first-child label {
            border-radius: _c.$border-radius-l _c.$border-radius-l 0 0;
        }

        &:last-child label {
            border-radius: 0 0 _c.$border-radius-l _c.$border-radius-l;
        }
    }

    label {
        cursor: pointer;
        margin: 0;
        padding: _c.$spacing-m _c.$spacing-xm;

        @media #{_f.query(_c.$breakpoint-m)} {
            @include _m.layer-shadow-c;
            background-color: #fff;
            border-radius: _c.$border-radius-l;
            padding: _c.$spacing-l;
            text-align: center;
        }
    }

    input[type='checkbox'],
    input[type='radio'] {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        &:checked + label {
            background-color: #B9C5DF;

            @media #{_f.query(_c.$breakpoint-m, 'max')} {
                padding: _c.$spacing-m _c.$spacing-xm;

                .list-options__text {
                    color: _c.$black;
                }
            }
        }
    }
}

.list-options__heading {
    @include _m.sans--black;
    color: _c.$blue;
    font-size: _f.rem(20);
    margin-bottom: 0;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(28);
    }
}

.list-options__text {
    @include _m.sans;

    color: _c.$slate;
    font-size: _f.rem(16);
    margin-bottom: 0;

    @media #{_f.query(_c.$breakpoint-m)} {
        color: _c.$black;
        font-size: _f.rem(18);
    }
}

.password-input.field-addon {
    button.passwordless-btn {
        background-color: _c.$blue;
        flex: 1;
        padding: _c.$spacing-m 1rem;
        transition: all 0.15s ease-in;

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            flex: auto;
        }

        &:hover {
            background-color: _c.$blue-light;
            color: _c.$gray-light;
        }

        &.hide {
            display: none;
        }
    }
}

.passwordless-help {
    color: _c.$gray;
    margin-top: _c.$spacing-s;
    text-align: center;

    &.hide {
        display: none;
    }
}

.password-hide-toggle {
    &.hide {
        display: none;
    }
}

input[name='password'].field-addon__input {
    flex: 1;
    transition: all 0.15s ease-in;

    &.toggle {
        flex: 0;
        opacity: 0;
        padding: 0;
    }
}
