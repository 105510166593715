@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


// ----------------------------------------------
// Box
// ----------------------------------------------

.box {
    @include _m.layer-shadow-b;

    background-color: _c.$white;
    border-radius: _c.$border-radius-xl;
    padding: _c.$spacing-m;

    @media (_f.query(_c.$breakpoint-s)) {
        padding: _c.$spacing-l;
    }
}

.box--alt {
    padding: 0;
}

.box__header {
    background-color: _c.$blue;
    border-radius: _c.$border-radius-xl _c.$border-radius-xl 0 0;
    padding: _c.$spacing-s _c.$spacing-l;
}

.box__heading {
    @include _m.sans--black;

    color: _c.$white;
    font-size: _f.rem(30);
    margin-bottom: 0;
    text-align: center;

    @media #{_f.query(_c.$breakpoint-s)} {
        font-size: _f.rem(40);
        text-align: left;
    }
}

.box__body {
    padding: _c.$spacing-l;
}


// ----------------------------------------------
// Card
// ----------------------------------------------

.card-wrap {
    display: flex;
    flex-direction: column;

    .card {
        flex: 1 0 auto;
    }
}

.card {
    @include _m.layer-shadow-b;

    background-color: _c.$white;
    border-radius: _c.$border-radius-l;
    padding: _c.$spacing-s;
}

.card--link {
    transition: transform 0.25s ease-in-out;

    &:hover {
        transform: translate3d(0, -8px, 0);
    }
}

.card__img--fade {
    @media #{_f.query(_c.$breakpoint-l)} {
        margin-bottom: -#{_c.$spacing-xl};
    }
}

.card__heading {
    @include _m.sans--bold;

    font-size: _f.rem(24);
}

.card__link {
    color: _c.$blue;
    display: block;
    text-decoration: none;
    transition: color 0.15s ease-in;

    &:visited {
        color: _c.$blue;
    }

    &:hover,
    &:focus {
        color: _c.$blue-highlight;
    }
}

.card__link--arrow {
    @include _m.sans--semi-bold;
    display: inline-block;

    [role='img'] {
        display: inline-block;
        height: _f.rem(40);
        margin-left: _c.$spacing-s;
        width: _f.rem(40);
    }
}

.card__body {
    padding: _c.$spacing-s _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-l)} {
        padding: _c.$spacing-m _c.$spacing-l;
    }
}
