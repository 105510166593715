@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.savings-calc {
    @include _m.layer-shadow-b;

    background-color: _c.$white;
    border-radius: 6px;
    clear: both;
    margin: 0 auto;
    max-width: _f.rem(1024);

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }
}

.savings-calc__header {
    margin-bottom: _c.$spacing-xxl;

    @media #{_f.query(_c.$breakpoint-m)} {
        display: flex;
        padding: 0 _c.$spacing-xxl;
    }
}

.savings-calc__content {
    flex: 1 0 0;
    padding: _c.$spacing-l;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-m)} {
        padding: _c.$spacing-xl _c.$spacing-xm 0 0;
    }
}

.savings-calc__total-savings {
    background-color: _c.$blue;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: flex-end;

    @media #{_f.query(_c.$breakpoint-m)} {
        border-radius: 0 0 _c.$border-radius-m _c.$border-radius-m;
    }
}

.savings-calc__total {
    color: _c.$white;
    line-height: 1;
    padding: _c.$spacing-xm _c.$spacing-xxxl;
    text-align: center;

    h2 {
        @include _m.sans--bold;
        color: #acb9c9;
        font-size: _f.rem(20);
    }

    .dollar-sign,
    .total-savings {
        @include _m.sans--black;
    }

    .dollar-sign {
        font-size: _f.rem(30);
        margin-right: _c.$spacing-xs;
        position: relative;
        top: -2px;
    }

    .total-savings {
        font-size: _f.rem(50);
    }
}

.savings-calc__body {
    padding-bottom: _c.$spacing-xxl;
}

.input-table {
    display: table;
    width: 110px;
}

.input-table__cell {
    display: table-cell;

    &:first-child,
    &:last-child {
        text-align: center;
    }

    &:first-child span {
        padding-right: _c.$spacing-xs;
    }
    &:last-child span {
        padding-left: _c.$spacing-xs;
    }
}

.savings-calc__row {
    align-items: center;
    border-top: 1px dashed _c.$gray-med;
    display: flex;
    margin: 0 _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m)} {
        margin: 0 _c.$spacing-xxl;
    }
}

.savings-calc__row-wrap:first-child {
    .savings-calc__row {
        border: 0;
    }
}

.savings-calc__btn,
.savings-calc__header__total {
    font-size: _f.rem(18);

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(22);
    }
}

.savings-calc__btn {
    background-color: transparent;
    border: 0;
    display: flex;
    flex: 1 0 0;
    padding: _c.$spacing-m _c.$spacing-s _c.$spacing-m 0;
    text-align: left;
    width: 100%;

    &:hover {
        color: _c.$blue-highlight;
    }

    &.is-visible .savings-calc__icon {
        transform: rotate(180deg);
    }
}

.savings-calc__icon {
    flex-shrink: 0;
    height: 16px;
    margin: 0.25em _c.$spacing-s 0 0.25em;
    margin-top: 0.5em;
    transform: rotate(0);
    transition: transform 0.15s ease-in;
    width: 16px;
}

.savings-calc__header.is-active img {
    transform: rotate(180deg);
}

.savings-calc__header__total {
    @include _m.sans--black;

    flex-shrink: 0;
    text-align: right;
}

.savings-calc__heading {
    flex: 1;
    font-size: inherit; // inherited from the .savings-calc__btn
    margin-bottom: 0;
}

.savings-calc__row-body {
    background-color: rgb(255, 255, 255);
    border-left: 1px solid _c.$gray-light;
    border-right: 1px solid _c.$gray-light;
    position: relative;
    width: 100%;

    // @media #{'(min-width: 1025px)'} {
    //     display: table;
    // }

    display: none;

    &.is-visible {
        display: block;
    }
}

.savings-calc__cell-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 _c.$spacing-m;

    &:last-of-type {
        border-bottom: 0;
        .savings-calc__cell {
            border-bottom: 0;
        }
    }

    &.odd {
        background-color: _c.$gray-light;
    }

    @media #{(_f.query(_c.$breakpoint-m))} {
        border: 0;
        flex-wrap: nowrap;
        padding: 0 _c.$spacing-xxl;
        // display: table-row;
    }
}

.savings-calc__cell--label {
    flex: 1 1 auto;

    @media #{(_f.query(_c.$breakpoint-m, 'max'))} {
        // max-width: 100%;
        // width: 100%;
    }
}

.savings-calc__cell {
    padding: _f.rem(8);
    // width: 100%;

    input,
    select:not([multiple]) {
        background-color: #e0e0e0;
        border: 1px solid #e0e0e0;
        border-radius: _c.$border-radius-m;
        outline: 0;
        padding: _c.$spacing-xs;

        &:focus {
            border-color: _c.$gray;
        }
    }

    select:not([multiple]) {
        background-position: right 8px top 50%;
    }

    &.total,
    label {
        @include _m.sans--semi-bold;
    }

    label {
        display: inline-block;
        font-size: _f.rem(16);
        margin-bottom: 0;
        position: relative;
    }

    &.total {
        text-align: right;

        @media #{_f.query(_c.$breakpoint-m)} {
            min-width: _f.rem(112);
        }
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        border-bottom: 1px solid _c.$gray-light;
        padding: _f.rem(16) _f.rem(8);
        vertical-align: top;

        &:first-child {
            padding-right: _c.$spacing-m;
        }

        &:last-child {
            padding-left: _c.$spacing-m;
        }

        input {
            width: 100%;
        }

        select {
            margin-left: _f.rem(20);
            width: 130px;
        }
    }
}

.savings-calc__header.first {
    border: 1px solid _c.$gray-med;
    border-radius: 6px 6px 0 0;

    .savings-calc__icon {
        border-radius: 6px 0 0 0;
        top: -1px;
    }
}

.savings-calc__header.last {
    border-radius: 0 0 6px 6px;

    .savings-calc__icon {
        border: none;
        border-radius: 0 0 0 6px;
    }

    &.is-active {
        border-radius: 0;
        .savings-calc__icon {
            border-radius: 0;
        }
    }
}

.vendor-info__icon {
    color: _c.$gray;
    display: inline-block;
    cursor: pointer;
    height: 16px;
    margin-left: _c.$spacing-xs;
    position: relative;
    top: -2px;
    width: 16px;

    @media #{'(min-width: 1025px)'} {
        left: -(_c.$spacing-l);
        margin-left: 0;
        position: absolute;
    }

    svg {
        height: auto;
        width: 100%;
    }

    &:hover {
        color: _c.$blue-highlight;
    }
}

.vendor-info {
    display: none;
}

// Vendor tooltip template
.savings-calc__vendor {
    .vendor-info {
        // @include shadow-2dp;

        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        display: none;
        margin-top: _f.rem(8);
        padding: _f.rem(16) _f.rem(24) 0 _f.rem(16);
        position: absolute;
        width: 100%;
        z-index: 5;

        @media #{'(min-width: 481px)'} {
            margin-top: _f.rem(16);
            padding: _f.rem(16) _f.rem(16) 0;
            width: 440px;
        }

        @media #{'(min-width: 1025px)'} {
            margin-left: -220px;
        }

        @media #{'(max-width: 480px)'} {
            left: 0 !important;
            right: auto !important;
        }

        p {
            font-size: _f.rem(14);
        }
    }
}


.vendor-info__logo {
    display: block;
    height: auto;
    margin: 0 auto _f.rem(24);
    max-height: _f.rem(32);
    max-width: 100%;
    width: auto;
}
