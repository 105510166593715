
// ----------------------------------------------
// Google reCAPTCHA
// ----------------------------------------------
// Target and center the div that gets inline
// width and height styles and ultimately
// contains the iframe with the recaptcha
// ----------------------------------------------

.g-recaptcha {
    > div {
        margin: 0 auto;
    }
}
