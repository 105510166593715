@use '../config' as _c;
@use '../function' as _f;
@use '../mixin/' as _m;


// ----------------------------------------------
// Footer
// ----------------------------------------------

.site-footer {
    background-color: _c.$gray-light-med;
    padding: _c.$spacing-xl _c.$spacing-xm _c.$spacing-xl;
}

.site-footer__branding,
.footer-nav-grid {
    margin: 0 auto;
    max-width: _c.$main-width;
}


// ----------------------------------------------
// Footer Logo and Tagline
// ----------------------------------------------

.site-footer__branding {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: _c.$spacing-l;
    padding-bottom: _c.$spacing-xm;
    position: relative;

    &::after {
        background: url('../images/border.svg') repeat-x 0 0;
        bottom: 0;
        content: '';
        left: 0;
        height: 1px;
        position: absolute;
        width: 100%;
    }

    @media #{_f.query(_f.em(544, 16))} {
        flex-direction: row;
    }

    .logo {
        @media #{_f.query(_f.em(544, 16))} {
            margin-right: _c.$spacing-m;
        }
    }

    .logo,
    .logo svg {
        height: _f.rem(58);
        width: _f.rem(175);

        @media #{_f.query(_f.em(544, 16))} {
            height: _f.rem(42);
            width: _f.rem(124);
        }
    }
}

.site-footer__branding--solo {
    margin-bottom: 0;
    padding-bottom: 0;

    &::after {
        display: none;
    }
}

.site-footer__tagline {
    color: #8595aa;
    line-height: 1.5;
    font-size: _f.rem(26);
    font-style: italic;
    margin-bottom: 0;
    text-align: center;

    @media #{_f.query(_f.em(544, 16))} {
        border-left: 2px solid #e4e5e6;
        color: _c.$blue;
        padding-left: _c.$spacing-m;
        text-align: left;
    }
}


// ----------------------------------------------
// Footer Layout
// ----------------------------------------------

.footer-nav-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        flex-direction: column;
    }

    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

.footer-nav-grid__item {
    padding-right: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-s)} {
        flex-basis: 50%;
        max-width: 50%;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        flex-basis: 25%;
        max-width: 25%;
    }

    @supports (display: grid) {
        grid-column: auto/span 4;
        max-width: none;

        @media #{_f.query(_c.$breakpoint-s)} {
            grid-column: auto/span 2;
            max-width: none;
        }

        @media #{_f.query(_c.$breakpoint-m)} {
            grid-column: auto/span 1;
            max-width: none;
        }
    }
}


// ----------------------------------------------
// Footer Nav Accordions
// ----------------------------------------------
.footer-nav__heading {
    @include _m.sans--semi-bold;

    display: block;
    color: _c.$gray-xdark;
    font-size: _f.rem(16);
    line-height: 1;
    margin-bottom: _c.$spacing-xs;
    width: 100%;

    &.is-accordion {
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        display: none;
        margin-bottom: _c.$spacing-xs;

        // Detected by JS to add listener for accordion
        &.is-accordion {
            display: block;
        }
    }
}

.footer-nav__btn {
    @include _m.sans--semi-bold;

    background: transparent;
    border: 0;
    display: flex;
    padding: 0;
    text-align: left;
    text-decoration: none;

    &:link,
    &:visited {
        color: #c8dcf0;
    }

    .accordion-btn__icon {
        display: none;
        height: 10px;
        width: 10px;
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        padding: _c.$spacing-s 0;

        .accordion-btn__icon {
            display: flex;
        }
    }
}

.footer-nav {
    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        display: none;

        &.is-visible {
            display: block;
        }
    }
}

.footer-nav__list {
    margin-bottom: _c.$spacing-l;
    padding: 0;

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        margin-bottom: _c.$spacing-m;
    }
}

.footer-nav__item {
    list-style: none;
}

.footer-nav__link {
    text-decoration: none;

    &:link,
    &:visited {
        color: _c.$gray-dark;
    }
}


// ----------------------------------------------
// Copyright
// ----------------------------------------------

// ----------------------------------------------
// Copyright Information
// ----------------------------------------------
.copyright {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin-top: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-s)} {
        flex-direction: row;
    }
}

.copyright__item {
    color: rgba(#fefefe, 0.9);
    font-size: _f.rem(16);
    margin-bottom: _c.$spacing-m;
    padding: 0 _c.$spacing-m;
    text-align: center;

    @media #{_f.query(_c.$breakpoint-s)} {
        border-right: 1px solid rgba(#fefefe, 0.9);

        &:last-child {
            border: 0;
        }
    }

    a {
        text-decoration: none;
        transition: color 0.2s ease-in;

        &:link,
        &:visited {
            color: rgba(#fefefe, 0.9);
        }

        &:hover {
            color: rgba(#fefefe, 1);
        }
    }
}

// ------------ Footer Social Network Icons ------------ //
.copyright__item--social {
    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        display: flex;
        justify-content: space-between;
        max-width: 180px;
        width: 100%;
    }

    a {
        display: inline-block;
        transition: opacity 0.2s ease-in;
        vertical-align: middle;
        width: _f.rem(20);

        &:link,
        &:visited {
            opacity: 0.9;
        }

        &:hover {
            opacity: 1;
        }
    }

    img {
        height: auto;
        width: 100%;
    }
}
