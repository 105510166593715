@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.masthead-heading,
.masthead-subheading,
.masthead-text,
.masthead-list {
    color: _c.$blue;
}

.masthead-heading {
    @include _m.sans--black;

    font-size: _f.rem(48);
    line-height: 1;
    text-align: center;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(72);
        text-align: left;
    }
}

.masthead-subheading {
    @include _m.sans--semi-bold;
    font-size: _f.rem(40);
}

.masthead-text {
    font-size: _f.rem(26);
}

.masthead-text--medium {
    @include _m.sans--semi-bold;
}

.masthead-list {
    font-size: _f.rem(20);
    padding-left: 1em;
}

.masthead-tagline {
    @include _m.sans--black;
    font-size: _f.rem(18);

    @media #{_f.query(_c.$breakpoint-s)} {
        font-size: _f.rem(25);
    }
}

.masthead-quote {
    @include _m.sans--semi-bold;

    color: _c.$blue;
    padding-left: _f.rem(56);
    position: relative;

    &::after {
        @include _m.sans--black;

        content: '\201C';
        font-size: _f.rem(64);
        left: 0;
        line-height: 1;
        padding-right: _c.$spacing-xm;
        position: absolute;
        top: 0;
    }

    .masthead-text {
        font-size: _f.rem(24);
    }

    .masthead__src {
        font-size: _f.rem(18);
        font-style: italic;
    }
}

.masthead {
    align-items: center;
    background-color: #edecec;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 4px solid _c.$white;
    display: flex;
    height: _f.rem(610); // for IE 11 ;_;
    justify-content: center;
    // min-height: _f.rem(610);
    padding: _c.$spacing-m;

    h1 {
        margin: auto;
    }

    .masthead-content {
        margin: auto;
        max-width: _c.$main-width;
        width: 100%;
    }
}

.masthead--right {
    background-position: right bottom;
}

.masthead-hide {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        display: none;
    }
}
