@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

.quote {
    align-items: center;
    background-color: _c.$gray-med;
    border-radius: _c.$border-radius-m;
    display: flex;
    padding: _c.$spacing-xm _c.$spacing-l;

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        flex-direction: column;
        text-align: center;

        .quote__img {
            margin-bottom: _c.$spacing-m;
            order: -1;
        }

        .quote__caption {
            padding-left: 0;
        }
    }
}

.quote__img {
    border: 8px solid _c.$white;
    height: auto;
    max-width: _f.rem(120);
}

.quote__src {
    color: #38414c;

    p:first-child {
        font-size: _f.rem(18);
    }

    strong {
        @include _m.sans--black;
    }
}

.quote__caption {
    padding-left: _c.$spacing-l;
}

.quote__text {
    color: _c.$blue;
    font-size: _f.rem(18);
    font-style: italic;
    margin-bottom: 0;

    &::before {
        content: "\201C";
    }

    &::after {
        content: "\201D";
    }
}


// ----------------------------------------------
// Quote with quotation marks
// ----------------------------------------------

.quote-mark {
    margin-bottom: _f.rem(124);
    padding-left: _c.$spacing-xm;
    padding-right: _c.$spacing-xm;
    position: relative;

    &::before {
        background: no-repeat url('../images/quotation-marks-light.svg');
        content: '';
        height: 72px;
        left: -24px;
        position: absolute;
        top: -32px;
        width: 98px;
        z-index: 1;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        padding-left: _f.rem(148);
        padding-right: _f.rem(56);

        &::before {
            background: no-repeat url('../images/quotation-marks.svg');
            left: 12px;
            top: 0;
        }
    }
}

.quote-mark__text {
    border-bottom: 1px solid _c.$gray;
    color: _c.$gray-dark;
    font-size: _f.rem(18);
    font-style: italic;
    padding-bottom: _c.$spacing-xm;
    position: relative;
    z-index: 5;
}

.quote-mark__text--bd {
    border-top: 1px solid _c.$gray;
    padding-top: _c.$spacing-xm;
}


// ----------------------------------------------
// Large size quote
// ----------------------------------------------

.quote-large {
    @include _m.sans--semi-bold;

    color: _c.$blue;
    font-size: _f.rem(30);
    line-height: 1.2;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(40);
        text-align: center;
    }

    strong {
        @include _m.sans--black;
    }
}

.quote-large--light {
    color: _c.$blue-xlight;
}

.quote-large__src {
    color: _c.$white;
    margin-bottom: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(18);
        margin-bottom: _c.$spacing-xxl;
        text-align: center;
    }
}


// ----------------------------------------------
// Slider with Boxed Quotes
// ----------------------------------------------

.quote-box {
    margin: 0 auto;
    // main width plus padding around cards
    max-width: _c.$main-width + (_c.$spacing-xm * 2);
    width: 100%;

    .flickity-button {
        box-shadow: 0 0 0 2px #90a8c6;
    }

    .flickity-prev-next-button.previous {
        left: 16px;
    }

    .flickity-prev-next-button.next {
        right: 16px;
    }

    .flickity-slider {
        align-items: center;
        display: flex;
    }

    .flickity-prev-next-button {
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        .flickity-prev-next-button {
            display: block;
        }
    }

    @media #{_f.query(_f.em(1601, 16))} {
        .flickity-prev-next-button.previous {
            left: -64px;
        }

        .flickity-prev-next-button.next {
            right: -64px;
        }
    }
}

.quote-box__wrap {
    padding: _c.$spacing-xm;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-l)} {
        width: math.div(100%, 3);
    }
}
