@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.privacy-notice {
    @include _m.layer-shadow-b;

    background-color: _c.$white;
    border-radius: _c.$border-radius-l;
    font-size: _f.rem(14);
    left: _c.$spacing-m;
    padding: _c.$spacing-l _c.$spacing-m _c.$spacing-m;
    position: fixed;
    right: _c.$spacing-m;
    top: 100%;
    transition: opacity 0.25s ease-in, transform 0.35s ease-in;
    z-index: _c.$z-index-modal;

    @media #{_f.query(_c.$breakpoint-s)} {
        left: auto;
        padding: _c.$spacing-l;
        right: _c.$spacing-l;
        width: _f.rem(432);
    }

    &[aria-hidden='true'] {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }

    &[aria-hidden='false'] {
        opacity: 1;
        transform: translate3d(0, calc(-100% - #{(_c.$spacing-l)}), 0);
    }
}

.privacy-notice__close {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: _c.$slate;
    cursor: pointer;
    display: block;
    line-height: 1;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: (_c.$z-index-modal + 1);

    &:hover {
        color: _c.$blue;
    }

    svg {
        display: inline-block;
        height: 24px;
        width: 24px;
    }
}
