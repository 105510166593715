@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:color';
@use 'sass:math';

// ----------------------------------------------
// Masthead
// ----------------------------------------------
$masthead-img-width: 778px;

.features-masthead {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 auto;
    max-width: _c.$main-width;
    padding-bottom: _c.$spacing-xxml;
    padding-top: _f.rem(160);

    @media #{_f.query(_f.em(1400, 16), 'max')} {
        padding-left: _c.$spacing-xm;
        padding-right: _c.$spacing-xm;
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        flex-direction: row;
        padding-bottom: _c.$spacing-xxxxl;
        padding-top: _f.rem(176);
    }

    .masthead-heading {
        @media #{_f.query(_c.$breakpoint-l, 'max')} {
            width: auto;

            mark.highlight--v3 {
                display: inline-block;
            }
        }
    }
}

@supports (display:grid) {
    .features-masthead {
        @media #{_f.query(_c.$breakpoint-l)} {
            display: grid;
            grid-template-columns: 1fr minmax(520px, #{$masthead-img-width});
        }
    }
}

.features-masthead__content {
    padding-right: _c.$spacing-l;

    p {
        max-width: _f.rem(480);
    }
}

.features-masthead__img {
    max-width: $masthead-img-width;
    padding-right: _c.$spacing-xm;

    @media #{_f.query(_c.$breakpoint-l, 'max')} {
        margin-bottom: _c.$spacing-l;
        padding-right: 0;
    }
}

.features-masthead-img-wrap {
    display: block;
    position: relative;

    .poster-image {
        position: relative;
        z-index: 5;
    }

    .play-icon {
        height: 64px;
        left: 50%;
        margin-left: -32px;
        margin-top: -32px;
        position: absolute;
        opacity: 0.6;
        top: 50%;
        transition: opacity 0.3s ease-in;
        width: 64px;
        z-index: 10;
    }

    &:hover {
        .play-icon {
            opacity: 1;
        }
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        &::before {
            background-color: _c.$blue;
            content: '';
            height: 100%;
            position: absolute;
            right: -#{_c.$spacing-xm};
            top: -#{_c.$spacing-xm};
            transition: background-color 0.3s ease-in;
            width: 100%;
            z-index: 1;
        }

        &:hover::before {
            background-color: color.scale(_c.$blue-light, $lightness: 10%);
        }
    }
}


// ----------------------------------------------
// Sub Nav
// ----------------------------------------------

.sub-nav-features {
    position: sticky;
    top: -1px;
    z-index: _c.$z-index-overlay;

    &.is-stuck {
        border-bottom: 4px solid _c.$white;
    }

    @media #{_f.query(_f.em(880, 16), 'max')} {
        display: none;
    }
}

.sub-nav {
    display: flex;
    justify-content: space-between;
    padding: _c.$spacing-xm;
    margin: 0 auto;
    max-width: _c.$main-width;
}

.sub-nav__link {
    @include _m.sans--semi-bold;

    text-decoration: none;

    &:link,
    &:visited {
        color: _c.$blue-xlight;
    }

    &:hover {
        color: _c.$white;
    }
}


// ----------------------------------------------
// Cards
// ----------------------------------------------

.feature-cards {
    $gutter-x: _c.$spacing-m;
    $gutter-y: 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    margin-left: -$gutter-x;
    margin-top: -$gutter-y;
    padding: 0;

    > .card-wrap {
        margin-bottom: _c.$spacing-l;
        margin-top: $gutter-y;
        max-width: _f.rem(460);
        padding-left: $gutter-x;

        @media #{_f.query(_c.$breakpoint-xl)} {
            margin-bottom: 0;
            width: math.div(100%, 3);
        }
    }
}

.feature-cards--spaced {
    > .card-wrap {
        @media #{_f.query(_c.$breakpoint-xl)} {
            margin-bottom: _c.$spacing-l;
        }
    }

    .card__heading {
        color: _c.$blue;
        font-size: _f.rem(22);
        text-align: center;
    }
}

.feature-cards--responsive {
    &::after {
        content: 'flickity';
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        margin-left: auto;
        margin-right: auto;
        max-width: _c.$main-width;

        &::after {
            content: '';
        }
    }

    @media #{_f.query(_c.$breakpoint-l, 'max')} {
        margin-left: 0; // undo negative margin

        .card-wrap {
            padding: _c.$spacing-l;
            width: 100%;
        }
    }

    .flickity-prev-next-button.previous {
        left: -16px;
    }

    .flickity-prev-next-button.next {
        right: -16px;
    }
}


// ----------------------------------------------
// Our Perks
// ----------------------------------------------

.features-our-perks {
    $img-width: _f.percent(1170, 1920);

    background: no-repeat url('../images/features/our-perks-bg.jpg') center bottom;
    display: flex;
    min-height: 630px;

    .feature-img {
        width: $img-width;

        img {
            display: none;
        }
    }

    .feature-text {
        max-width: _f.rem(436);
        padding-right: _c.$spacing-m;

        @media #{_f.query(_f.em(725, 16))} {
            margin-top: _f.rem(72);
        }

        @media #{_f.query(_f.em(780, 16))} {
            margin-top: _f.rem(88);
        }
    }

    .hyphen-list {
        padding-left: 0.5em;

        @media #{_f.query(_f.em(724, 16), 'max')} {
            padding-left: 1.125em;
        }
    }

    @media #{_f.query(_f.em(1200, 16))} {
        background-position: -200px bottom;
    }

    @media #{_f.query(_c.$breakpoint-xxl)} {
        background-position: center bottom;
    }

    @media #{_f.query(_f.em(724, 16), 'max')} {
        align-items: center;
        background: none;
        flex-direction: column;
        min-height: 0;

        .feature-text {
            padding: _c.$spacing-m;
            order: -1;
            width: 100%;
        }

        .feature-img {
            width: 100%;

            img {
                display: block;
            }
        }
    }
}


// ----------------------------------------------
// Local & National Discounts
// ----------------------------------------------

.features-discounts-wrap {
    background: _c.$blue url(../images/features/us-map-large.png) no-repeat right center;
    padding-bottom: _c.$spacing-xxxxl + _f.rem(64); // account for negative margin below this section
    padding-top: _c.$spacing-xl;

    @media #{_f.query(_c.$breakpoint-m)} {
        padding-bottom: _c.$spacing-xxxxl + _f.rem(114);
    }

    @media #{_f.query(_f.em(580, 16), 'max')} {
        background-position: center 320px;
        background-size: 600px auto;
    }

    @media #{_f.query(_f.em(1400, 16), 'max')} {
        padding-left: _c.$spacing-xm;
        padding-right: _c.$spacing-xm;
    }
}

.features-discounts {
    margin: 0 auto;
    max-width: _c.$main-width;

    @media #{_f.query(_f.em(1000, 16))} {
        display: flex;

        .features-discounts__text {
            padding-right: _f.rem(32);
            width: 30%;
        }

        .features-discounts__savings-network {
            width: 70%;
        }
    }
}

@supports (display:grid) {
    .features-discounts {
        @media #{_f.query(_f.em(1000, 16))} {
            display: grid;
            grid-gap: _f.rem(96);
            grid-template-columns: _f.rem(400) 1fr;

            .features-discounts__text {
                padding-right: 0;
            }

            .features-discounts__text,
            .features-discounts__savings-network {
                width: auto;
            }
        }
    }
}

.features-discounts-heading {
    @include _m.sans--black;

    color: _c.$white;
    font-size: _f.rem(47);
    line-height: 1;
    margin-bottom: _c.$spacing-xm;
}

// helps to align with Savings Network content
.features-discounts__text {
    align-self: center;
    margin-bottom: 4rem;
}

.features-discounts-p {
    color: _c.$white-faded;
}

.stats {
    align-items: center;
    border: 1px solid rgba(_c.$white, 0.25);
    border-radius: _c.$border-radius-l;
    color: _c.$white;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 _c.$spacing-l;
    max-width: _f.rem(400);
    width: 100%;
}

.stats__label {
    @include _m.sans--bold;

    font-size: _f.rem(18);
}

.stats__value {
    @include _m.sans--black;

    font-size: 59px;
}

.offers-city-heading {
    color: _c.$white;
    font-size: _f.rem(18);
}

.city-table-wrap {
    display: grid;

    @media #{_f.query(_c.$breakpoint-s)} {
        grid-gap: _c.$spacing-xxl;
        grid-template-columns: 1fr 1fr;
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        grid-gap: 2rem;
    }
}

.city-table {
    width: 100%;
}

.city-table__city {
    color: _c.$white-faded;
    white-space: nowrap;
}

.city-table__count {
    @include _m.sans--bold;

    color: _c.$white;
    text-align: right;
    width: 50px;
}

// ----------------------------------------------
// Perks Rotator
// ----------------------------------------------

.features-perks-wrap {
    @media #{_f.query(_f.em(1400, 16), 'max')} {
        padding-left: _c.$spacing-xm;
        padding-right: _c.$spacing-xm;
    }

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        padding: 0;
    }
}

.features-perks {
    margin-top: _f.rem(-132);

    @media #{_f.query(_c.$breakpoint-m)} {
        @include _m.layer-shadow-c;

        background-color: _c.$white;
        border-radius: _c.$border-radius-m;
        display: flex;
        margin: _f.rem(-114) auto _c.$spacing-xxxl;
        max-width: _c.$main-width;
        padding: _c.$spacing-s;
    }

    .flickity-slider {
        padding: 0 _c.$spacing-xm;
    }

    .features-perks__images {
        max-width: 915px;
        width: 100%;
    }
}

@supports (display:grid) {
    .features-perks {
        display: grid;
        grid-gap: _c.$spacing-m;
        grid-template-columns: minmax(0, 915px) 1fr;
    }
}

.features-perks__images {
    &.flickity-enabled {
        margin-bottom: _c.$spacing-xxl;
    }

    &::after {
        content: 'flickity';
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        &::after {
            content: '';
        }
    }
}

.features-perks__card-wrap {
    @media #{_f.query(_c.$breakpoint-m)} {
        display: none;

        &.is-visible {
            display: block;
        }
    }
}

.features-perks__card__info {
    display: none;
}

@media #{_f.query(_c.$breakpoint-m, 'max')} {
    .features-perks__card-wrap {
        padding: _c.$spacing-xm;
        width: 100%;
    }

    .features-perks__card {
        @include _m.layer-shadow-b;
        @include _m.sans--bold;

        background-color: _c.$white;
        border-radius: _c.$border-radius-m;
        display: block;
        padding: _c.$spacing-s;
        width: 100%;
    }

    .features-perks__card__info {
        align-items: center;
        display: flex;
        font-size: _f.rem(18);
        justify-content: center;
        letter-spacing: 0.025em;
        padding: _c.$spacing-m;
        text-transform: uppercase;
    }
}

.features-perks__img {
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: _c.$border-radius-m;
    height: 310px;
    max-width: 916px;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        background-size: contain;
        height: 0;
        padding-bottom: math.div(310, 916) * 100%; // height divided by width for aspect ratio
    }
}

.features-perks__nav {
    display: none;

    @media #{_f.query(_c.$breakpoint-m)} {
        display: flex;
        flex-direction: column;
        max-width: 450px;
        width: 100%;
    }
}

@supports (display:grid) {
    .features-perks__nav {
        @media #{_f.query(_c.$breakpoint-m)} {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, 1fr);
            max-width: none;
            width: auto;
        }
    }
}

.features-perks__nav__btn {
    @include _m.sans--bold;

    align-items: center;
    background: none;
    border: 0;
    border-top: 1px dashed _c.$gray-med;
    display: flex;
    font-size: _f.rem(18);
    letter-spacing: 0.025em;
    padding: _c.$spacing-m;
    text-align: left;
    text-transform: uppercase;

    &:first-child {
        border: 0;
    }

    &:hover {
        color: _c.$blue-highlight;
    }

    &.is-active,
    &.is-active:hover {
        background-color: #4b5971;
        border-color: transparent;
        border-radius: _c.$border-radius-l;
        color: _c.$white;

        + .features-perks__nav__btn {
            border-color: transparent;
        }
    }
}

.features-perks__icon {
    height: _f.rem(24);
    margin-right: _c.$spacing-xm;
    white-space: nowrap;
    width: _f.rem(24);
}


// ----------------------------------------------
// Back Office Section
// ----------------------------------------------

.features-back-office {
    background-color: _c.$blue;
    color: _c.$white;
    min-height: 448px;
    padding: _c.$spacing-xxl _c.$spacing-xm;
    position: relative;
}

.features-back-office__heading {
    @include _m.sans--bold;

    font-size: _f.rem(32);
    text-align: center;
}

.features-back-office__text {
    margin: 0 auto _c.$spacing-xxl;
    max-width: _f.rem(598);
    text-align: center;
}

.features-back-office__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;

    img {
        display: block;
        height: _f.rem(100);
        margin: 0 auto _c.$spacing-xm;
        width: _f.rem(100);
    }

    > li {
        @include _m.sans--bold;

        margin-bottom: _c.$spacing-xl;
        text-align: center;
        width: math.div(100%, 2);

        @media #{_f.query(_c.$breakpoint-m)} {
            width: math.div(100%, 3);
        }

        @media #{_f.query(_c.$breakpoint-l)} {
            width: math.div(100%, 6);
        }
    }
}


// ----------------------------------------------
// Branding Options Section
// ----------------------------------------------

.features-branding-options-one {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: _c.$main-width;
    padding-top: _c.$spacing-xl;
    position: relative; // to site above next section
    z-index: 10;
}

.features-branding-options__text {
    width: 100%;

    @media #{_f.query(_f.em(1400, 16), 'max')} {
        padding-left: _c.$spacing-xm;
        padding-right: _c.$spacing-xm;
        max-width: (_f.rem(440) + (_c.$spacing-xm * 2));
    }
}

.features-branding-options__img {
    .small-img {
        display: block;
    }

    .large-img {
        display: none;
    }
}

@media #{_f.query(_c.$breakpoint-m)} {
    .features-branding-options__img {
        .small-img {
            display: none;
        }

        .large-img {
            display: block;
        }
    }
}

@media #{_f.query(_c.$breakpoint-l)} {
    .features-branding-options-one {
        align-items: flex-start;
        flex-direction: row;
        justify-content: center;
    }

    .features-branding-options__text {
        max-width: _f.rem(440);
        padding-right: 0;
    }

    .features-branding-options__img {
        flex: 1 0 _f.percent(900, 1400);
        max-width: _f.percent(900, 1400);
    }
}

.features-branding-options-two {
    padding: _c.$spacing-xxl _c.$spacing-m ;
    position: relative;
    z-index: 1;

    @media #{_f.query(_c.$breakpoint-m)} {
        background-color: _c.$gray-light-med;
        margin-top: -150px;
        padding-top: 177px;
    }

    @media #{_f.query(_c.$breakpoint-xl)} {
        margin-top: -250px;
        padding-top: 277px;
    }

    .dots {
        display: none;

        @media #{_f.query(_c.$breakpoint-m)} {
            display: block;
        }
    }
}


// ----------------------------------------------
// One Click Enrollment
// ----------------------------------------------

.features-one-click {
    background-color: _c.$gray-light-med;
    display: flex;
    flex-direction: column;
    padding: 0 _c.$spacing-xm;

    @media #{(_f.query(_c.$breakpoint-l))} {
        flex-direction: row;
        justify-content: center;
    }

    @media #{_f.query(_c.$breakpoint-l, 'max')} {
        align-items: center;
    }
}

.features-one-click__img {
    max-width: 760px;
    position: relative;

    @media #{(_f.query(_c.$breakpoint-l))} {
        margin-right: 170px;
        order: -1;

        &::after {
            background: no-repeat url('../images/features/arrow.png');
            content: '';
            height: 316px;
            position: absolute;
            right: -176px;
            top: 96px;
            width: 176px;
            z-index: 1;
        }

        .btn {
            display: none;
        }
    }

}

.features-one-click__text {
    max-width: _f.rem(428);
    padding-top: 32px;
    position: relative;
    z-index: 5; // to appear on top of arrow

    .mobile-icons {
        .mobile-icons__app:last-child {
            margin-bottom: _c.$spacing-xl;
            margin-right: 0;
        }

        @media #{(_f.query(_c.$breakpoint-l))} {
            .mobile-icons__app:last-child {
                margin-bottom: _c.$spacing-m;
            }

            img {
                width: 172px;
            }
        }

    }

    .btn {
        display: none;
        margin: 0 auto;
        max-width: 355px;
        text-align: center;
        width: 100%;

        @media #{(_f.query(_c.$breakpoint-l))} {
            display: block;
        }
    }
}


// ----------------------------------------------
// Content Controls
// ----------------------------------------------

.features-content-controls {
    $img-width: _f.percent(997, 1920);

    align-items: center;
    background-color: _c.$gray-light-med;
    display: flex;
    flex-direction: column;
    min-height: 720px;
    padding-top: _c.$spacing-xxml;

    @media #{_f.query(_f.em(1201, 16))} {
        background: _c.$gray-light-med url('../images/features/content-controls-bg.png') center bottom;
        flex-direction: row;
        justify-content: center;
        padding-top: 0;
    }

    .features-content-controls__img {
        width: $img-width;

        img {
            display: none;
        }

        @media #{_f.query(_f.em(1200, 16), 'max')} {
            width: 100%;

            img {
                display: block;
                margin: 0 auto;
                max-width: 525px;
            }
        }
    }

    .features-content-controls__text {
        max-width: _f.rem(698);
        padding-left: _c.$spacing-m;
        padding-right: _c.$spacing-m;

        p {
            max-width: _f.rem(554);
        }
    }
}


// ----------------------------------------------
// Compare Our Discounts
// ----------------------------------------------

.compare-discounts {
    padding: _c.$spacing-xxl 0;

    .btn--center {
        padding: 0 _c.$spacing-xm;
    }
}

.compare-discounts-one {
    color: _c.$blue;
    margin: 0 auto;
    max-width: _f.rem(604);
    text-align: center;

    @media #{_f.query(_f.em(604, 16), 'max')} {
        padding: 0 _c.$spacing-xm;
    }

    h2 {
        font-size: _f.rem(32);
        line-height: 1.2;
    }
}

.comparison-slider {
    display: flex;
    margin: 0 auto _c.$spacing-xl;
    max-width: _f.rem(1300);
    padding: 0 _c.$spacing-xm;
    text-align: center;
    width: 100%;

    &.flickity-enabled {
        display: block;
    }

    &::after {
        content: 'flickity';
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-s)} {
        padding: 0;
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        &::after {
            content: '';
        }
    }

    .card {
        @include _m.layer-shadow-a;
        margin: _c.$spacing-m math.div(_c.$spacing-l, 2) _c.$spacing-l;
        width: 100%;

        @media #{_f.query(_c.$breakpoint-s)} {
            width: 400px;
        }

        @media #{_f.query(_c.$breakpoint-l)} {
            margin: _c.$spacing-m;
            width: calc(#{math.div(100%, 3)} - #{_c.$spacing-m});
        }

        &.flickity-enabled {
            display: inline-block;
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    .flickity-button {
        color: _c.$blue;
    }

    .flickity-prev-next-button {
        background: none;

        &.previous {
            left: -64px;
        }

        &.next {
            right: -64px;
        }

        // Move arrows out at large enough screen size
        @media #{_f.query(_f.em(1428, 16), 'max')} {
            display: inline-block;
            position: static;
            margin: _c.$spacing-l _c.$spacing-s 0;
        }
    }
}


// ----------------------------------------------
// Developer Resources
// ----------------------------------------------

.dev-section {
    display: flex;
    flex-wrap: wrap;

    .dev-intro {
        width: 100%;
    }

    .dev-icon {
        width: math.div(100%, 3);
    }
}

.dev-section {
    align-items: center;
    display: grid;
    grid-gap: _f.rem(32);
    margin: 0 auto;
    max-width: _c.$main-width;
    padding: _c.$spacing-xxxl 0 _c.$spacing-xxl;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-xl)} {
        grid-template-columns: 320px repeat(3, 1fr);
    }

    @media #{_f.query(_c.$breakpoint-xxl)} {
        grid-gap: _f.rem(72);
    }

    @media #{(_f.query(_f.em(1400, 16), 'max'))} {
        padding: _c.$spacing-xxxl _c.$spacing-xm _c.$spacing-xxl;
    }
}

@supports (display:grid) {
    .dev-section {
        .dev-intro,
        .dev-icon {
            width: auto;
        }
    }
}


@media (min-width:_c.$breakpoint-m) and #{_f.query(_c.$breakpoint-xl, 'max')} {
    .dev-section {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    .dev-intro {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }

    .dev-icon {
        grid-row: 2 / 3;

        &:nth-child(1) {
            grid-column: 1 / 2;
        }

        &:nth-child(2) {
            grid-column: 2 / 3;
        }

        &:nth-child(3) {
            grid-column: 3 / 4;
        }
    }
}

.dev-icon {
    display: flex;

    > div {
        padding-left: _c.$spacing-m;
    }
}

.dev-icon__heading {
    color: #3c3c3c;
    font-size: _f.rem(20);
    letter-spacing: 0.025em;
    margin-bottom: 0;

    @media (min-width:_c.$breakpoint-m) {
        text-transform: uppercase;
    }
}

.dev-icon__text {
    font-size: _f.rem(15);
}

.dev-icon__img {
    height: auto;
    max-height: _f.rem(68);
    max-width: _f.rem(72);

    @media #{_f.query(_c.$breakpoint-xxl)} {
        max-height: _f.rem(74);
        max-width: _f.rem(88);
    }
}

.additional-features-heading {
    font-size: _f.rem(32);
    line-height: 1.2;
}

.additional-features-subheading {
    font-size: _f.rem(20);
    letter-spacing: 0.025em;
    margin-bottom: _c.$spacing-xl;
    text-transform: uppercase;

    @media #{_f.query(_c.$breakpoint-m)} {
        margin-bottom: _c.$spacing-xxml;
    }
}

.additional-features {
    margin: 0 auto;
    max-width: _c.$main-width;

    @media #{(_f.query(_f.em(1400, 16), 'max'))} {
        padding: 0 _c.$spacing-xm;
    }

    .box {
        padding: _f.rem(24);

        @media #{_f.query(_c.$breakpoint-m)} {
            padding: _c.$spacing-xxl;
        }
    }

    li::marker {
        color: _c.$green;
    }

    .grid__item {
        @media #{_f.query(_c.$breakpoint-m)} {
            padding-right: _c.$spacing-xm;
            position: relative;

            &::after {
                background: url('../images/border-vertical.svg') repeat-y 0 0;
                content: '';
                height: 100%;
                position: absolute;
                right: 12px;
                top: 0;
                width: 1px;
            }

            &:last-child::after {
                display: none;
            }
        }

        ul {
            @include _m.sans--bold;

            @media #{_f.query(_c.$breakpoint-l)} {
                padding-left: 2em;
            }

            > li {
                margin-bottom: _c.$spacing-m;

                @media #{_f.query(_c.$breakpoint-m)} {
                    margin-bottom: _c.$spacing-l;
                }
            }
        }
    }
}