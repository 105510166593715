@use '../config' as _c;

@import "~flickity/css/flickity.css";

.flickity-enabled {
    &.hide-arrows {
        .flickity-prev-next-button {
            display: none;
        }
    }
}

.flickity-viewport {
    width: 100%; // Needed for flex powered sliders, apparently
}

.flickity-button {
    color: _c.$blue;
}

.flickity-prev-next-button {
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }
}

.flickity-prev-next-button.previous {
    left: 0;
}

.flickity-prev-next-button.next {
    right: 0;
}

.vendor-logo-grid--responsive {
    .flickity-prev-next-button.previous {
        left: -10px;
    }

    .flickity-prev-next-button.next {
        right: -10px;
    }
}

/* fade in image when loaded */
.flickity-lazyload {
    transition: opacity 0.4s;
    opacity: 0;
}

.flickity-lazyload.flickity-lazyloaded,
.flickity-lazyload.flickity-lazyerror {
    opacity: 1;
}

.flickity-page-dots {
    left: 0;
    .dot {
        background: _c.$white;
        border: 1px solid _c.$blue;
        opacity: 1;

        &.is-selected {
            background-color: _c.$blue;
        }
    }
}
