@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

.whats-next {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto _c.$spacing-l;
    max-width: _f.rem(944);

    @media #{_f.query(_c.$breakpoint-m)} {
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        flex-wrap: nowrap;
        margin-bottom: _f.rem(96);
    }
}

.whats-next__link {
    background-color: _c.$gray-light;
    border-radius: 24px;
    color: _c.$gray-dark;
    font-size: _f.rem(16);
    margin: 0 (math.div(_c.$spacing-xm, 2)) _c.$spacing-xm;
    padding: _c.$spacing-xl _c.$spacing-m;
    text-align: center;
    text-decoration: none;
    width: calc(100% - #{(_c.$spacing-m * 2)});

    &:hover p,
    &:hover strong {
        color: _c.$blue-highlight;
    }

    @media #{_f.query(_c.$breakpoint-s)} {
        width: calc(#{math.div(100%, 2)} - #{math.div(_c.$spacing-xm, 2)});
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        width: calc(#{math.div(100%, 3)} - #{math.div(_c.$spacing-xm, 2)});
    }
}

.whats-next__img {
    display: block;
    margin: 0 auto _c.$spacing-m;
    width: _f.rem(80);

    img {
        display: inline-block;
        height: _f.rem(64);
        margin: 0 auto;
        width: auto;
    }
}

.whats-next__text {
    margin-bottom: 0;

    strong {
        @include _m.sans--semi-bold;

        color: _c.$blue;
        display: block;
        font-size: _f.rem(18);
        letter-spacing: 0.025em;
        text-transform: uppercase;
    }
}

@media #{_f.query(_c.$breakpoint-s, 'max')} {
    .whats-next__link {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: _c.$spacing-xm;
        text-align: left;
    }

    .whats-next__img {
        margin: 0;
    }

    .whats-next__text {
        padding-left: _c.$spacing-xm;
        max-width: _f.rem(144);
        width: 100%;
    }
}