@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


.inc-5000-layout {
    margin-bottom: _c.$spacing-l;

    @media #{_f.query(_c.$breakpoint-l)} {
        display: grid;
        grid-gap: _c.$spacing-xxxl;
        grid-template-columns: 1fr 360px;
        margin-bottom: _c.$spacing-s;
    }
}

.inc-5000-layout--spaced {
    margin-bottom: _f.rem(128) !important;
}

.inc-5000 {
    background-color: _c.$gray-light;
    border-radius: _c.$border-radius-m;
    margin: 0 auto;
    max-width: _f.rem(360);
    padding: _c.$spacing-l;

    @media #{_f.query(_c.$breakpoint-l, 'max')} {
        margin-top: _c.$spacing-l;
    }
}

.inc-5000__perks {
    font-size: _f.rem(32);
}

.inc-5000__recipient {
    font-size: _f.rem(18);
}

.inc-5000__perks,
.inc-5000__recipient {
    @include _m.sans--black;
    color: _c.$blue;
}

.inc-5000__recipient {
    font-size: _f.rem(18);
}
