@use '../config'as _c;

table.grid-table {
    min-width: 600px;
    width: 100%;
    display: grid;
    border-collapse: collapse;
    grid-template-columns: 1fr 20% 20%;

    thead,
    tbody,
    tr {
        display: contents;
    }

    th,
    td {
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.9rem;
        white-space: nowrap;
    }

    th {
        position: sticky;
        top: 0;
        background: _c.$blue-light;
        text-align: left;
        font-weight: normal;
        color: _c.$white;
    }

    td {
        background: _c.$white;
        border: 1px solid _c.$gray-med;
        border-bottom: 0;
        border-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        color: _c.$black;

        &:last-child {
            border-right: 1px solid _c.$gray-med;
        }
    }

    tr:last-child {
        td {
            border-bottom: 1px solid _c.$gray-med;
        }
    }
}
