@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

// ----------------------------------------------
// US Map
// ----------------------------------------------

.us-map {
    background: _c.$blue url('../images/us-map-full.svg') no-repeat 50% 50%;
    background-size: auto 592px;
}

.us-map--white {
    background-color: transparent;
    background-image: url('../images/us-map-white.svg');

    .savings-network__cell .stat__number {
        color: _c.$black;
    }

    .show-more-btn {
        &:hover {
            color: _c.$blue;
        }
    }
}

.us-map--auto {
    background-size: 100% auto;
}


// ----------------------------------------------
// Savings Network Icons
// ----------------------------------------------

// [1] minus the bottom spacing on all items
.savings-network {
    margin: 0 auto; // [1]
    max-width: (_c.$main-width - _f.rem(128));
    padding-bottom: (_c.$spacing-xxl - _c.$spacing-l);

    // This is extending the base .accordion-btn class
    .show-more-btn {
        background: transparent;
        border: 0;
        color: #95a5b9;
        margin: 0 auto;
        text-align: center;
        width: auto;

        &:hover {
            color: _c.$white;
        }
    }
}

.savings-network__body {
    display: flex;
    flex-flow: row wrap;
}

.savings-network__cell {
    display: flex;
    justify-content: flex-start;
    margin-bottom: _c.$spacing-l;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-s)} {
        padding-right: _c.$spacing-m;
        width: math.div(100%, 2);
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        padding-right: _c.$spacing-l;
        width: math.div(100%, 3);
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        &.mobile-hide {
            display: none;

            &.toggle {
                display: flex;
            }
        }
    }

    .stat__number {
        color: _c.$white;
        display: block;
    }

    .stat__text {
        color: #eaeff0;
        margin-bottom: 0;
    }

    img {
        flex-shrink: 0;
        height: _f.rem(40);
        margin-right: _c.$spacing-m;
        width: auto;
    }
}

.savings-network--two-col {
    .savings-network__cell {
        @media #{_f.query(_c.$breakpoint-m)} {
            padding-right: _c.$spacing-l;
            width: math.div(100%, 2);
        }
    }
}

.savings-network {
    .show-more-btn {
        display: none;

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            display: block;
        }
    }
}
