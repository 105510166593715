@use '../config' as _c;
@use '../function' as _f;
@use 'sass:math';

.vendor-logo-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.vendor-logo__item {
    background-color: #fff;
    margin: 0 _f.rem(8) _f.rem(24);
    padding: 0 _f.rem(8);
    width: _f.rem(128);

    img {
        display: block;
        margin: 0 auto;
        height: auto;
        max-height: _f.rem(54);
        max-width: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
    }
}

.vendor-logo-grid-v2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    @media #{_f.query(_c.$breakpoint-m)} {
        justify-content: space-between;
        width: 75%;
    }

    img {
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
        width: auto;

        @media #{_f.query(_c.$breakpoint-m)} {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .third,
    .fourth,
    .fifth,
    .sixth {
        margin-bottom: (_c.$spacing-s * 2);
        padding-left: _c.$spacing-s;
        padding-right: _c.$spacing-s;
    }

    .third {
        flex: 0 0 math.div(100%, 3);
        max-width: math.div(100%, 3);
    }

    .fourth {
        flex: 1 0 math.div(100%, 4);
        max-width: math.div(100%, 4);
    }

    .fifth {
        flex: 1 0 math.div(100%, 5);
        max-width: math.div(100%, 5);
    }

    .sixth {
        flex: 0 0 math.div(100%, 6);
        max-width: math.div(100%, 6);
    }
}

.vendor-logo-grid--responsive {
    &::after {
        content: 'flickity';
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        &::after {
            content: '';
        }
    }

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        display: block;
        width: 100%;

        // Flickity positions these absolutely,
        // so we pull a little vertical centering trick
        .third,
        .fourth,
        .fifth,
        .sixth {
            display: inline-block;
            margin-bottom: 0;
            max-width: 50%;
            top: 50%;
            transform: translateY(-50%);
            width: 50%;
            vertical-align: middle;

            img {
                max-height: 70px;
            }
        }
    }
}

.vendor-logo-grid-v3 {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        flex-wrap: wrap;
    }

    > div {
        max-width: _f.rem(80);
        padding: 0 _c.$spacing-s;

        @media #{_f.query(_c.$breakpoint-m, 'max')} {
            padding-bottom: _c.$spacing-s;
        }
    }

    img {
        display: block;
        height: auto;
        max-height: _f.rem(48);
        max-width: 100%;
        width: auto;
    }
}
