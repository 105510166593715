@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

// ---------------------------------------------------
// .text is used to scope common article text styles
// ---------------------------------------------------

.text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include _m.sans--black;
    }

    h2 {
        font-size: _f.rem(36);
    }

    h3 {
        font-size: _f.rem(30);
    }

    h4 {
        font-size: _f.rem(25);
    }

    h5 {
        font-size: _f.rem(22);
    }

    h6 {
        font-size: _f.rem(18);
    }

    ul,
    ol {
        padding-left: 1em;

        > li {
            margin-bottom: _c.$spacing-s;
        }

        ul,
        ol {
            margin-top: _c.$spacing-s;
        }
    }
}

.text--dark {
    a:not([class]),
    a[class|='js'] {
        color: _c.$black;

        &:visited {
            color: _c.$black;
        }

        &:hover,
        &:focus {
            color: _c.$blue-highlight;
        }
    }
}

.text--large {
    h3 {
        font-size: _f.rem(22);
    }

    p {
        font-size: _f.rem(18);
        margin-bottom: _f.rem(40);
    }

    p + h3 {
        margin-top: _c.$spacing-xxxl;
    }
}
