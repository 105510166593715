@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.skip-to-content {
    font-size: _f.rem(14);
    height: 0;
    left: -9999%;
    margin: 0;
    opacity: 0;
    outline: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: _f.rem(148);

    &:focus {
        height: auto;
        left: 0;
        opacity: 1;
        width: 100%;

        @media #{_f.query(_c.$breakpoint-m)} {
            top: _f.rem(98);
        }

        span {
            background: _c.$blue;
            color: _c.$white;
            padding: _c.$spacing-xs _c.$spacing-s;
        }
    }
}
