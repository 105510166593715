@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

// ----------------------------------------------
// Section Headings
// ----------------------------------------------

.section-heading,
.section-subheading {
    @include _m.sans--black;

    color: _c.$blue;
    line-height: 1.2;
}

.section-heading {
    font-size: _f.rem(37);

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(42);
        text-align: center;
    }

    .section-heading__sub {
        display: block;
    }
}

.section-heading--resp-black {
    color: _c.$black;

    @media #{_f.query(_c.$breakpoint-m)} {
        color: _c.$blue;
    }
}

.section-heading--large {
    font-size: _f.rem(47);

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(47);
    }
}

.section-heading-v2 {
    @include _m.sans--black;

    font-size: _f.rem(37);
    line-height: 1;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(52);
        line-height: 1.2;
        text-align: center;
    }
}

.section-heading-v3 {
    @include _m.sans--black;

    font-size: _f.rem(37);
    line-height: 1;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(44);
        line-height: 1.2;
        text-align: center;
    }
}

.section-heading-v4 {
    @include _m.sans--black;

    color: _c.$blue;
    font-size: _f.rem(40);
    line-height: 1.2;
}

.section-heading__sub {
    @include _m.sans--semi-bold;

    color: _c.$blue;
    font-size: _f.rem(26);
}

.section-heading--black {
    color: _c.$black;
}

.section-subheading {
    font-size: _f.rem(28);
    text-align: center;
}

.section-subheading--align {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        text-align: left;
    }
}

.section-subheading-v2 {
    @include _m.sans;

    color: _c.$black;
    font-size: _f.rem(16);
    letter-spacing: 0.125em;
    text-align: left;
    text-transform: uppercase;

    @media #{_f.query(_c.$breakpoint-m)} {
        color: _c.$blue;
        text-align: center;
    }
}

.section-tagline {
    font-size: _f.rem(25);
}

.section-text {
    font-size: _f.rem(18);
}

.section-text--align {
    @media #{_f.query(_c.$breakpoint-m)} {
        text-align: center;
    }
}

.section-text-v2 {
    @include _m.sans--semi-bold;
    font-size: _f.rem(18);

    @media #{_f.query(_c.$breakpoint-m)} {
        @include _m.sans--bold;

        font-size: _f.rem(22);
        text-align: center;
    }
}

.section-text-v3 {
    @include _m.sans--semi-bold;
    font-size: _f.rem(18);

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(22);
        text-align: center;
    }
}

.section-tagline-list {
    color: _c.$black;
    font-size: _f.rem(20);
    padding: _c.$spacing-m 0 0;

    @media (min-width: 680px) {
        padding-top: 0;
        text-align: center;
    }

    @media #{_f.query(_c.$breakpoint-xxl)} {
        font-size: _f.rem(24);
    }

    > li {
        list-style: none;
        margin-bottom: 20px;
        position: relative;

        &::after {
            background-color: #c9d2ea;
            bottom: -10px;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 208px;
        }

        &:last-child::after {
            display: none;
        }

        @media (min-width: 680px) {
            display: inline-block;
            margin-bottom: 0;
            margin-left: _c.$spacing-l;

            &:first-child {
                margin-left: 0;
            }

            &::after {
                height: 32px;
                left: auto;
                right: -18px;
                top: 0;
                width: 1px;
            }

            &:last-child::after {
                display: none;
            }
        }
    }
}


// ----------------------------------------------
// Section Widths
// ----------------------------------------------

.section {
    padding: _c.$spacing-xxl _c.$spacing-m;
    position: relative;
}

.section--pad-top {
    padding: _c.$spacing-xxl _c.$spacing-m 0;
}

.section--v2 {
    padding: _c.$spacing-l _c.$spacing-m;
}

.section--v3 {
    padding: _c.$spacing-xm;
}

.section--v4 {
    padding: _c.$spacing-xl _c.$spacing-m;
}

.section--v5 {
    padding: _c.$spacing-xxxl 0;
}

.section--v6 {
    padding: _c.$spacing-xl 0;
}

.section--v7 {
    padding: _c.$spacing-xm _c.$spacing-xl;
}

.section--margin {
    margin-bottom: _f.rem(128);
}

// ------------ Place above bg dots ------------ //
.section-content {
    position: relative;
    z-index: _c.$z-index-base;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: _c.$main-width; // 1400
}

.container--v2 {
    max-width: _f.rem(1160);
}

.container--v3 {
    max-width: _f.rem(944);
}

.container--v4 {
    max-width: _f.rem(784);
}

.container--v5 {
    max-width: _f.rem(976);
}

.container--spaced-m {
    padding-left: _c.$spacing-m;
    padding-right: _c.$spacing-m;
}

.container--spaced-xm {
    padding-left: _c.$spacing-xm;
    padding-right: _c.$spacing-xm;
}

.container--spaced-xxxl {
    padding-left: _c.$spacing-m;
    padding-right: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m)} {
        padding-left: _c.$spacing-xxxl;
        padding-right: _c.$spacing-xxxl;
    }
}

.container--spaced-v--xl {
    padding-bottom: _c.$spacing-xl;
    padding-top: _c.$spacing-xl;
}


// ----------------------------------------------
// Section Dots
// ----------------------------------------------

.dots {
    background-repeat: repeat;
    display: none;
    height: _f.rem(448);
    position: absolute;
    width: _f.rem(336);
    z-index: 1;

    $dots-breakpoint: (336 * 2) + (16 * 2);
    @media #{_f.query(_f.em($dots-breakpoint, 16))} {
        display: block;
    }
}

// Add a min-height to a section with bg dots
.dots--min-height {
    min-height: _f.rem(420 + 64);
}

.dots--narrow {
    height: _f.rem(420);
    width: _f.rem(256);

    &.dots--c-l,
    &.dots--c-r {
        $margin: math.div(420, 2);
        margin-top: _f.rem(-$margin);
    }
}

.dots--grey {
    background-image: url('../images/grey-dots.svg');
}

.dots--grey-light-med {
    background-image: url('../images/grey-light-med-dots.svg');
}

.dots--blue {
    background-image: url('../images/blue-dots.svg');
}

// ------------ Dots aligned to center left ------------ //
.dots--c-l {
    $margin: math.div(448, 2);
    left: 0;
    margin-top: _f.rem(-$margin);
    top: 50%
}

// ------------ Dots aligned to center right ------------ //
.dots--c-r {
    $margin: math.div(448, 2);
    margin-top: _f.rem(-$margin);
    right: 0;
    top: 50%;
}

// ------------ Dots aligned to bottom left ------------ //
.dots--b-l {
    bottom: 0;
    left: 0;

    &.dots--offset {
        bottom: _f.rem(80);
    }
}

// ------------ Dots aligned to bottom right ------------ //
.dots--b-r {
    bottom: 0;
    right: 0;

    &.dots--offset {
        bottom: _f.rem(80);
    }
}

// ------------ Dots aligned to top left ------------ //
.dots--t-l {
    left: 0;
    top: 0;
}

// ------------ Dots aligned to top right ------------ //
.dots--t-r {
    right: 0;
    top: 0;
}


// ----------------------------------------------
// Background Stripes
// ----------------------------------------------
.offset-stripe {
    content: '';
    left: 0;
    position: absolute;
    height: _f.rem(288);
    top: 0;
    width: 100%;
    z-index: 1;
}

// ----------------------------------------------
// Blue Section
// ----------------------------------------------

.section-blue {
    background-color: _c.$blue;
    color: _c.$blue-xlight;
    padding-bottom: _f.rem(224);

    @media #{_f.query(_c.$breakpoint-l, 'max')} {
        padding-top: _c.$spacing-l;
        padding-bottom: _f.rem(176);
    }

    .app-icons__link {
        color: _c.$white;

        svg {
           background-color: #557090;
           color: _c.$white;
        }
    }


    // Signup heading with button
    // ----------------------------------------------
    // Stack the inputs at 538px and less
    // and between 960px and 1150px

    .heading-signup {
        align-items: center;
        display: flex;

        .heading-signup__heading {
            margin-bottom: 0;
        }

        .btn {
            font-size: _f.rem(18);
        }

        @mixin stackedHeading {
            flex-direction: column;

            .heading-signup__heading {
                margin-bottom: _c.$spacing-m;
                text-align: center;
            }

            .btn {
                width: 100%;
            }
        }

        @media #{(_f.query(_f.em(584, 16), 'max'))} {
            @include stackedHeading;
        }

        @media #{(_f.query(_f.em(960, 16)))} and #{(_f.query(_f.em(1250, 16), 'max'))} {
            @include stackedHeading;
        }

        @media #{_f.query(_c.$breakpoint-l, 'max')} {
            margin-bottom: _c.$spacing-l;
        }
    }

    .heading-signup__heading {
        flex: 1 0 0%;
        width: 100%;

        @media #{_f.query(_c.$breakpoint-l)} {
            font-size: _f.rem(20);
        }

        @media #{_f.query(_c.$breakpoint-xxl)} {
            font-size: _f.rem(24);
        }
    }

    .field.field-addon {
        &.has-focus {
            box-shadow: 0 0 0 2px #95a5b9;
        }

        // Stack the inputs at 538px and less
        // and between 960px and 1150px
        @mixin stackedAddOn {
            background-color: transparent;
            flex-direction: column;

            input.field-addon__input {
                margin-bottom: _c.$spacing-m;
            }

            &.has-focus {
                box-shadow: none;

                input.field-addon__input {
                    box-shadow: 0 0 0 2px #95a5b9;
                }
            }
        }

        @media #{(_f.query(_f.em(538, 16), 'max'))} {
            @include stackedAddOn;
        }

        @media #{(_f.query(_f.em(960, 16)))} and #{(_f.query(_f.em(1150, 16), 'max'))} {
            @include stackedAddOn;
        }
    }

    .grid {
        display: flex;
        flex-direction: column;

        > div:first-child {
            order: 2;
            padding-bottom: _c.$spacing-l;
        }

        > div:last-child {
            order: 1;
        }

        @media #{_f.query(_c.$breakpoint-l)} {
            flex-direction: row;

            > div {
                width: 50%;
            }

            > div:first-child {
                order: 1;
                padding-bottom: 0;
            }

            > div:last-child {
                order: 2;
                padding-left: _c.$spacing-xxl;
            }
        }
    }

    @supports (display:grid) {
        .grid {
            display: grid;
            grid-template-columns: 1fr;

            @media #{_f.query(_c.$breakpoint-l)} {
                grid-template-columns: 1fr 1fr;

                > div {
                    width: auto;
                }
            }
        }
    }
}

.section-blue--no-padding {
    padding-bottom: 0;
}

.section-blue__heading {
    color: _c.$white;
    font-size: _f.rem(24);
}

.section-blue__heading--center {
    @media #{(_f.query(_f.em(538, 16), 'max'))} {
        text-align: center;
    }

    @media #{(_f.query(_f.em(960, 16)))} and #{(_f.query(_f.em(1150, 16), 'max'))} {
        text-align: center;
    }
}
