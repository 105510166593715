@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


.featured-videos {
    .flickity-page-dots {
        margin-top: _c.$spacing-l;
        position: static;

        .dot {
            background-color: _c.$white;
            border: 1px solid _c.$blue-dark;
            opacity: 1;

            &.is-selected {
                background-color: _c.$blue-dark;
            }
        }
    }

    .flickity-prev-next-button {

        background-color: _c.$white;
        height: 64px;
        opacity: 0.75;
        transition: opacity 0.3s ease-in;
        width: 48px;

        &:hover {
            opacity: 1;
        }

        &.previous {
            border-radius: 0 8px 8px 0;
            left: 0;
        }

        &.next {
            border-radius: 8px 0 0 8px;
            right: 0;
        }

        .arrow {
            fill: #305078;
        }

        &[disabled] {
            opacity: 0.5 !important;
            .arrow {
                fill: _c.$gray;
            }
        }
    }
}

.featured-videos__item {
    display: inline-block;
    font-size: _f.rem(14);
    transform: scale(0.85, 0.85);
    transition: 0.25s transform ease-in-out;
    width: 360px;

    &.is-selected {
        transform: scale(1, 1);
    }
}

.featured-videos__meta {
    background-color: _c.$gray-light-med;
    display: flex;
    padding: _c.$spacing-m;

    img {
        border-radius: 50%;
        flex-shrink: 0;
        height: 40px;
        width: 40px;
    }

    h3 {
        padding-left: _c.$spacing-m;
    }
}
