@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

ul {
    li::marker {
        color: _c.$blue;
    }

    &.no-pad {
        padding-left: 0 !important;
    }

    &.spaced li {
        padding-left: _c.$spacing-m;
    }
}

.checklist {
    li {
        padding-inline-start: _c.$spacing-s;

        &::marker {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='16' height='16'%3E%3Cpath fill='%23305078' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
        }
    }
}

.hyphen-list {
    li {
        @include _m.sans--bold;

        color: _c.$blue;
        padding-inline-start: _c.$spacing-s;

        &::marker {
            content: '\2010';
        }
    }
}

li.file {
    list-style: none;
    padding-left: 0;

    &::before {
        padding-right: _c.$spacing-s;
        position: relative;
        top: 6px;
    }

    &.presentation::before {
        content: url('../images/resources/film.svg');
    }

    &.pdf::before {
        content: url('../images/resources/file-pdf.svg');
    }

    &.zip::before {
        content: url('../images/resources/file-zip.svg');
    }
}
