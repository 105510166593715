@use '../config' as _c;


// ----------------------------------------------
// The classless selector
// Only applies styles to links without a class
// ----------------------------------------------

a:not([class]),
a[class|='js'] {
    color: _c.$blue;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.15s ease-in;

    &:visited {
        color: _c.$blue;
    }

    &:hover,
    &:focus {
        color: _c.$blue-highlight;
    }
}
