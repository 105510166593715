@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.yes-no-section {
    margin: 0 auto _c.$spacing-xxl;
    max-width: (_c.$main-width + _c.$spacing-m); // 1400
    padding: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        background-color: _c.$blue;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.yes-no {
    display: flex;
    flex-direction: column;
    padding: _c.$spacing-m 0;

    @media #{_f.query(_c.$breakpoint-m)} {
        flex-direction: row;

        .yes-no__yes,
        .yes-no__no {
            width: 50%;
        }
    }
}

@supports (display:grid) {
    .yes-no {
        display: grid;
        grid-template-columns: 1fr;

        @media #{_f.query(_c.$breakpoint-m)} {
            grid-template-columns: 1fr 1fr;

            .yes-no__yes,
            .yes-no__no {
                width: auto;
            }
        }
    }
}

.yes-no--padded-s {
    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        margin-bottom: _c.$spacing-xl;
        padding-bottom: _c.$spacing-xl;
    }
}

.yes-no--v2.box {
    margin-top: _f.rem(-192);
    position: relative;
    z-index: 5;
}

.yes-no__yes,
.yes-no__no {
    padding: 0 _c.$spacing-xm;

    li {
        margin-bottom: _c.$spacing-xm;
    }
}

.yes-no__yes {
    @media #{_f.query(_c.$breakpoint-m)} {
        border-right: 1px dashed _c.$gray-med;
    }

    li::marker {
        color: _c.$green;
    }
}

.yes-no__no {
    li::marker {
        color: _c.$red;
    }
}
