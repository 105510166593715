@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.nav {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.nav--piped {
    > li {
        border-right: 1px solid _c.$gray-med;
        padding-left: _c.$spacing-s;
        padding-right: _c.$spacing-s;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border: 0;
        }
    }
}
