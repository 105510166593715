// sass-lint:disable-all

// --------------------------------------------------------
//  Print styles.
// --------------------------------------------------------
//  Inlined to avoid the additional HTTP request:
//  http://www.phpied.com/delay-loading-your-print-css/
// --------------------------------------------------------

@media print {
    *,
    *:before,
    *:after,
    *:first-letter,
    *:first-line {
        background: transparent !important;
        color: #000 !important; // Black prints faster: http://www.sanbeiji.com/archives/953
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    // --------------------------------------------------------
    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    // --------------------------------------------------------

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    // --------------------------------------------------------
    // Printing Tables:
    // http://css-discuss.incutio.com/wiki/Printing_Tables
    // --------------------------------------------------------

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // --------------------------------------------------------
    //  Site specific overrides
    // --------------------------------------------------------

    .logo,
    .copyright {
        a::after {
            content: '';
        }
    }

    // Make white text readable because we removed all BG colors
    .section-blue {
        color: #000 !important;
    }

    // expand accordion sections so they are readable
    .accordion-section {
        display: block;
    }

    .site-header__nav,
    .mobile-menu-btn,
    .site-footer,
    .copyright__item--social {
        display: none;
    }

    // Avoid page breaks in the middle of a button
    .btn {
        page-break-inside: avoid;
    }


    // ----------------------------------------------
    // Some Flickity Wrangling
    // ----------------------------------------------

    // Hide arrows and let visible logos show
    .flickity-prev-next-button {
        display: none;
    }

    .flickity-viewport {
        height: auto !important;
        overflow: auto !important;
        max-width: 100%;
    }

    .flickity-slider {
        transform: none !important;
        width: 100%;
    }

    .top-brands {
        display: block;
        .top-brands__item {
            // justify-content: flex-start;
            display: inline-block;
            position: static;
            width: 25% !important;

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    // default system settings prevent bg colors,
    // so the light colored logos don't work so great
    .top-brands-section {
        display: none;
    }


    // ----------------------------------------------
    // Forms
    // ----------------------------------------------

    // Avoid overlap with the placeholders
    .field--floating label {
        visibility: hidden;
    }


    // ----------------------------------------------
    // Features
    // ----------------------------------------------

    // Cards on Features page
    .card-wrap {
        display: block;
    }
}

// sass-lint:enable-all
