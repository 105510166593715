@use '../config' as _c;
@use '../function' as _f;

.mobile-icons {
    text-align: center;

    .mobile-icons__app {
        display: inline-block;
        margin-bottom: _c.$spacing-s;
        margin-right: _c.$spacing-s;

        &:last-child {
            margin-right: 0;
        }
    }

    img {
        width: 135px;
    }
}

.mobile-icons--large img {
    @media #{_f.query(_c.$breakpoint-m)} {
        width: 216px;
    }
}
