@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.zip-code-form {
    @media #{_f.query(_c.$breakpoint-xxl)} {
        display: flex;

        .zip-code-form__item {
            margin-bottom: 0;
            padding-right: _c.$spacing-m;
        }
    }

    label {
        @include _m.sans--semi-bold;

        color: #4d5055;
        font-size: _f.rem(16);
        white-space: nowrap;
    }

    input[type='text'],
    input[type='number'],
    select:not([multiple]) {
        background-color: _c.$white;
        border: 0;
        border-radius: _c.$border-radius-m;
        padding: _c.$spacing-s;
    }

    // Disable arrows on number inputs
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // Styling for select with options, not multi select
    select:not([multiple]) {
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23272a2e' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
        background-position: right 16px top 50%;
        background-repeat: no-repeat;
        background-size: 12px auto;
        line-height: inherit;
        padding-right: 32px;

        &:-moz-focusring { // get rid of the focus border in Firefox
            color: transparent;
            text-shadow: 0 0 0 #000;
        }

        &::-ms-expand { // removes the arrow in IE 10
            display: none;
        }
    }

    .btn {
        background-color: _c.$blue-light;
        border-color: _c.$blue-light;
        text-align: center;

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            width: 100%;
        }
    }
}

.zip-code-form__inputs {
    display: flex;
}

.zip-code-form__item {
    align-items: center;
    display: flex;

    @media #{_f.query(_f.em(1200, 16))} {
        &:first-child {
            flex-shrink: 0;
        }

        label {
            padding-right: _c.$spacing-m;
        }
    }

    @media #{_f.query(_f.em(1200, 16), 'max')} {
        align-items: flex-start;
        flex-direction: column;
        width: 50%;
    }

    &:first-child {
        padding-right: _c.$spacing-s;
    }

    &:last-child {
        padding-left: _c.$spacing-s;
    }

    input[type='text'],
    input[type='number'],
    select:not([multiple]) {
        width: 100%;
    }
}