@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


h1,
h2,
h3,
h4,
h5,
h6 {
    @include _m.sans--bold;
}


.hd-sub {
    font-size: _f.rem(20);
    letter-spacing: 0.025em;
    text-transform: uppercase;

    @media #{_f.query(_c.$breakpoint-xxl)} {
        font-size: _f.rem(24);
    }
}

.hd-icon {
    background-color: #e9e9e9;
    align-items: center;
    border-radius: _c.$border-radius-l;
    display: flex;
    padding: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        justify-content: center;
    }
}

.hd-icon__heading {
    line-height: 1;
    margin-bottom: 0;

    @media #{_f.query(_c.$breakpoint-m)} {
        flex: 1 0 auto;
    }
}

.hd-icon__img {
    flex: 0 0 auto;
    height: _f.rem(40);
    margin-right: _f.rem(16);
    width: _f.rem(40);
}

.semibold {
    @include _m.sans--semi-bold
}

// ------------ Gotham Regular 18 ------------ //
.gotham-18 {
    @include _m.sans;
    font-size: _f.rem(18);
}


// ------------ Gotham Black 16 ------------ //
.hd-16-black {
    @include _m.sans--black;
    font-size: _f.rem(16);
}

// ------------ Gotham Black 24 ------------ //
.hd-24-bold {
    @include _m.sans--bold;
    font-size: _f.rem(24);
}

// ------------ Gotham Black 26 ------------ //
.hd-26-black {
    @include _m.sans--black;
    font-size: _f.rem(26);
}

.hd-26-bold {
    @include _m.sans--bold;
    font-size: _f.rem(26);
    line-height: 1.2;
}

// ------------ Gotham Black 36px ------------ //
.hd-36-black {
    @include _m.sans--black;
    font-size: _f.rem(36);
}

// ------------ Gotham Black 40px ------------ //
.hd-40-black {
    @include _m.sans--black;
    font-size: _f.rem(40);
}

// ------------ Gotham Bold 32 ------------ //
.hd-32-bold {
    @include _m.sans--bold;
    font-size: _f.rem(32);
}

// ------------ Body text 14 ------------ //
.text-14 {
    font-size: _f.rem(14);
}

.text-20 {
    font-size: _f.rem(20);
}

.text-22 {
    font-size: _f.rem(22);
}
