@use '../config' as _c;

li.status {
    list-style-type: none;

    &::before {
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 12px;
        margin-right: _c.$spacing-s;
        width: 12px;
    }

    &.green_light::before {
        background-color: _c.$green;
    }

    &.red_light::before {
        background-color: _c.$red;
    }

    &.all::before {
        display: none;
    }
}
