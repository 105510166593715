@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


mark {
    color: inherit;
    display: inline-block;
    position: relative;
    z-index: 2;

    &.underline {
        background-color: transparent;

        &::before,
        &::after {
            content: ' ';
            display: block;
            height: 90%;
            width: 100%;
            margin-left: -3px;
            margin-right: -3px;
            position: absolute;
            z-index: -1;
        }

        &::before {
            height: 0.12em;
            top: 0.98em;
            left: 6px;
            background: rgba(244, 235, 120, 0.8);
            transform: rotate(-2deg);
        }

        &::after {
            height: 0.18em;
            top: 1em;
            left: -2px;
            background: rgba(244, 235, 120, 0.8);
            border-bottom-left-radius: 6%;
            border-bottom-right-radius: 16%;
            border-top-left-radius: 90%;
            transform: rotate(-1deg);
        }
    }

    &.highlight {
        background-color: transparent;

        &::after {
            background: no-repeat url('../images/mark-2-dull.png') center/100% 100%;
            bottom: 0;
            content: ' ';
            display: block;
            left: -0.25em;
            position: absolute;
            right: -0.25em;
            top: 0;
            z-index: -1;
        }
    }

    &.highlight--v2:after {
        background-image: url('../images/mark-1-dull.png');
    }

    &.highlight--v3 {
        display: block;

        &::after {
            background-image: url('../images/mark-double.png');
            top: 0.5em;
        }
    }
}
