@use '../function';
@use '../mixin';
@use '../config';

// Hide site SVG sprite
.svg-sprite {
    display: none;
}

body,
html {
    height: 100%;
}

body {
    @include mixin.sans;

    background-color: config.$blue;
    color: config.$black;
    font-size: 100%; // 16px
    line-height: 1.5;
}

.site-wrapper {
    margin: 0 auto;
    max-width: function.rem(1920);
    position: relative;
}

.site-main {
    @include mixin.clearfix;

    background-color: config.$white;
}
