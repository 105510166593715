@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.icon-list {
    list-style: none;

    img,
    [role='img'] {
        display: inline-block;
        height: _f.rem(16);
        margin-right: _c.$spacing-m;
        width: _f.rem(16);
    }
}
