@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.laptop-masthead {
    background-image: url(../images/masthead/laptop-masthead-small.jpg);

    @media #{_f.query(_c.$breakpoint-m)} {
        background-image: url(../images/masthead/laptop-masthead.jpg);
    }
}

.parameters_table,
.tag_definition {
    border: 1px solid _c.$gray-med;
    font-size: _f.rem(14);
    margin-bottom: _c.$spacing-m;
    width: 100%;

    thead td {
        @include _m.text-shadow-default;
        background-color: _c.$blue-light;
        color: rgb(255, 255, 255);
    }

    td {
        border-bottom: 1px solid _c.$gray-med;
        padding: _c.$spacing-s;
        vertical-align: top;
    }

    tr:last-child td {
        border-bottom: 0;
    }
}

table.tag_definition tr td:nth-child(1) {
    width: 200px;
}

span.code {
    background-color: #f2f2f2;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 0.25em;
    font-family: Monaco, Consolas, 'Lucida Console', monospace;
    padding: 0 0.25em;

    &.url {
        word-wrap: break-word;
    }
}

.code.code-parameter {
    color: rgb(207, 49, 96);;
}

.code-parameter-list {
    margin: 10px 0 10px 15px;
}

.code-parameter-list li {
    margin-bottom: 0;
}

.code.http-verb {
    color: rgb(49, 207, 96);
}

.code.url {
    color: #254ca5;
}

span.long_string {
    display: inline-block;
    width: 200px;
    word-wrap: break-word;
}

table pre > code {
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
}