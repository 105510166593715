@charset "UTF-8";
@import "~flickity/css/flickity.css";
/*!
* Bootstrap Reboot v4.5.0 (https://getbootstrap.com/)
* Copyright 2011-2020 The Bootstrap Authors
* Copyright 2011-2020 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

a, button, input, select, textarea, label, summary {
  touch-action: manipulation;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 8px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin: 0;
}

ol:not([class]),
ul:not([class]),
dl:not([class]) {
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
}

a:hover {
  color: #0056b3;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 8px;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 24px;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.error-box, .warning-box, .info-box, .success-box, .form-processing {
  color: #272a2e;
  font-size: 0.875rem;
}
.error-box a:not([class]):link, .warning-box a:not([class]):link, .info-box a:not([class]):link, .success-box a:not([class]):link, .form-processing a:not([class]):link, .error-box a:not([class]):visited, .warning-box a:not([class]):visited, .info-box a:not([class]):visited, .success-box a:not([class]):visited, .form-processing a:not([class]):visited,
.error-box a[class|=js]:link,
.warning-box a[class|=js]:link,
.info-box a[class|=js]:link,
.success-box a[class|=js]:link,
.form-processing a[class|=js]:link,
.error-box a[class|=js]:visited,
.warning-box a[class|=js]:visited,
.info-box a[class|=js]:visited,
.success-box a[class|=js]:visited,
.form-processing a[class|=js]:visited {
  color: #305078;
}
.error-box a:not([class]):hover, .warning-box a:not([class]):hover, .info-box a:not([class]):hover, .success-box a:not([class]):hover, .form-processing a:not([class]):hover, .error-box a:not([class]):focus, .warning-box a:not([class]):focus, .info-box a:not([class]):focus, .success-box a:not([class]):focus, .form-processing a:not([class]):focus,
.error-box a[class|=js]:hover,
.warning-box a[class|=js]:hover,
.info-box a[class|=js]:hover,
.success-box a[class|=js]:hover,
.form-processing a[class|=js]:hover,
.error-box a[class|=js]:focus,
.warning-box a[class|=js]:focus,
.info-box a[class|=js]:focus,
.success-box a[class|=js]:focus,
.form-processing a[class|=js]:focus {
  color: #4472AB;
}

.error-box, .warning-box, .info-box, .success-box, .form-processing {
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  color: #272a2e;
  padding: 0.5rem 1rem;
  position: relative;
}
.error-box ul, .warning-box ul, .info-box ul, .success-box ul, .form-processing ul,
.error-box ol,
.warning-box ol,
.info-box ol,
.success-box ol,
.form-processing ol {
  padding-left: 1em;
}
.error-box > :last-child, .warning-box > :last-child, .info-box > :last-child, .success-box > :last-child, .form-processing > :last-child {
  margin-bottom: 0;
}
.error-box [role=img], .warning-box [role=img], .info-box [role=img], .success-box [role=img], .form-processing [role=img] {
  display: inline-block;
  height: 1rem;
  left: 0.5rem;
  position: absolute;
  top: 0.625rem;
  width: 1rem;
}
.error-box .alert-heading, .warning-box .alert-heading, .info-box .alert-heading, .success-box .alert-heading, .form-processing .alert-heading {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}
.has-icon.error-box, .has-icon.warning-box, .has-icon.info-box, .has-icon.success-box, .has-icon.form-processing {
  background-position: 0.75rem 0.5rem;
  background-repeat: no-repeat;
  background-size: 24px auto;
  padding-left: 3rem;
}

.alert-box--icon {
  padding-left: 2rem;
}

.form-alerts {
  display: none;
}

.form-processing {
  background-color: #e8e8e8;
  color: #272a2e;
  display: none;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-shadow: none;
}

.success-box {
  background-color: #E6F8D5;
  border: 1px solid #0F983C;
  color: #272a2e;
  text-shadow: none;
}
.success-box a:link, .success-box a:visited, .success-box a:hover, .success-box a:focus {
  color: #3C9D36;
}
.success-box [role=img] {
  fill: #3C9D36;
}

.info-box {
  background-color: #EEE;
  border: 1px solid #CCC;
  text-shadow: none;
}
.info-box a:link, .info-box a:visited, .info-box a:hover, .info-box a:focus {
  color: #aaa;
}

.warning-box {
  background-color: #F9F9D6;
  border: 1px solid #F4C301;
  text-shadow: none;
}

.error-box {
  background-color: #FFEBE8;
  border: 1px solid #DD3C10;
}
.error-box li {
  font-style: italic;
}

.alert--red {
  color: #C13D3D;
  font-size: 0.875rem;
}

.alert--green {
  color: #3C9D36;
  font-size: 0.875rem;
}

button:focus, button:active {
  outline: 0;
}

.btn {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  background-color: #3C9D36;
  border: 1px solid #3C9D36;
  align-items: center;
  border-radius: 99em;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  justify-content: center;
  overflow: hidden;
  padding: 0.75rem 2rem;
  position: relative;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s;
}
@media (min-width: 30.0625em) {
  .btn {
    padding: 0.75rem 3rem;
  }
}
.btn:link, .btn:visited {
  color: #fff;
}
.btn:hover, .btn:focus {
  background-color: #49BE41;
  text-decoration: none;
}
.btn[disabled] {
  opacity: 0.5;
}
.btn[disabled]:hover {
  cursor: not-allowed;
}

@media (max-width: 30em) {
  .btn--stretch {
    width: 100%;
  }
}

@media (min-width: 60em) {
  .btn--wide {
    padding: 0.75rem 5rem;
    min-width: 19rem;
  }
  .btn--wide.btn--icon {
    padding-right: 3.5rem;
  }
}

.btn--icon {
  padding-right: 0.5rem;
}
@media (min-width: 30.0625em) {
  .btn--icon {
    padding-right: 1.5rem;
  }
}

.btn__icon {
  height: 1.5rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}
@media (min-width: 30.0625em) {
  .btn__icon {
    margin-left: 1.5rem;
  }
}

.btn--outline {
  background-color: #fff;
  color: #3C9D36;
}
.btn--outline:link, .btn--outline:visited {
  color: #3C9D36;
}
.btn--outline:hover, .btn--outline:focus {
  background-color: #fff;
  border-color: #49BE41;
  color: #49BE41;
}

.btn--center {
  display: flex;
  justify-content: center;
}
.btn--center .btn + .btn {
  margin-left: 1rem;
}

.btn-text {
  background-color: transparent;
  border: 0;
  color: #305078;
  padding: 0;
  text-decoration: underline;
  transition: color 0.15s ease-in;
}
.btn-text:hover, .btn-text:focus {
  color: #418BDB;
}

span.btn-text {
  cursor: pointer;
}

form {
  line-height: 1.5;
}

.form--full input:not([type=checkbox]):not([type=radio]), .form--full select:not([multiple]), .form--full textarea,
.field--full input:not([type=checkbox]):not([type=radio]),
.field--full select:not([multiple]),
.field--full textarea {
  width: 100%;
}

.field {
  padding: 0.5rem 0;
}

.field--center {
  display: flex;
  justify-content: center;
}

label,
.label {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  display: block;
  line-height: inherit;
  margin-bottom: 0.25rem;
}
label.is-required::after,
.label.is-required::after {
  color: #C13D3D;
  content: "*";
  display: inline-block;
  left: 100%;
  margin-left: 0.25rem;
}
label.label--text-400,
.label.label--text-400 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
label.label--sr-only,
.label.label--sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.field__desc,
.label--text {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.field__desc p,
.field__desc ul,
.label--text p,
.label--text ul {
  font-size: 0.875rem;
}
.field__desc ul,
.field__desc ol,
.label--text ul,
.label--text ol {
  counter-reset: li;
  list-style: none;
  margin: 0 0 0 1.75em;
}
.field__desc li,
.label--text li {
  position: relative;
}
.field__desc li::before,
.label--text li::before {
  padding-right: 0.5em;
  position: absolute;
  right: 100%;
  text-align: right;
}
.field__desc a,
.label--text a {
  color: #305078;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.15s ease-in;
}
.field__desc a:visited,
.label--text a:visited {
  color: #305078;
}
.field__desc a:hover, .field__desc a:focus,
.label--text a:hover,
.label--text a:focus {
  color: #4472AB;
}
.field__desc.field__desc--inline,
.label--text.field__desc--inline {
  color: #aaa;
  margin-top: 0;
  padding-left: 0.5rem;
}

.field__desc {
  color: #525252;
}

.field input:not([type=checkbox]):not([type=radio]), .field select:not([multiple]), .field textarea {
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #99a6b7;
  border-radius: 99em;
  font: inherit;
  line-height: inherit;
  padding: 1rem 2rem;
  transition: all 0.15s ease-in;
}
.field input:not([type=checkbox]):not([type=radio]):hover, .field input:not([type=checkbox]):not([type=radio]):focus, .field select:not([multiple]):hover, .field select:not([multiple]):focus, .field textarea:hover, .field textarea:focus {
  border-color: #3c4147;
}
.field input:not([type=checkbox]):not([type=radio]):focus, .field select:not([multiple]):focus, .field textarea:focus {
  outline: 0;
}
.disabled .field input:not([type=checkbox]):not([type=radio]), [disabled] .field input:not([type=checkbox]):not([type=radio]), .field input:not([type=checkbox]):not([type=radio])[disabled], .field input:not([type=checkbox]):not([type=radio])[readonly], .disabled .field select:not([multiple]), [disabled] .field select:not([multiple]), .field select:not([multiple])[disabled], .field select:not([multiple])[readonly], .disabled .field textarea, [disabled] .field textarea, .field textarea[disabled], .field textarea[readonly] {
  background-color: #f7f8fa;
  cursor: not-allowed;
}
.disabled .field input:not([type=checkbox]):not([type=radio]):hover, [disabled] .field input:not([type=checkbox]):not([type=radio]):hover, .field input:not([type=checkbox]):not([type=radio])[disabled]:hover, .field input:not([type=checkbox]):not([type=radio])[readonly]:hover, .disabled .field select:not([multiple]):hover, [disabled] .field select:not([multiple]):hover, .field select:not([multiple])[disabled]:hover, .field select:not([multiple])[readonly]:hover, .disabled .field textarea:hover, [disabled] .field textarea:hover, .field textarea[disabled]:hover, .field textarea[readonly]:hover {
  border: 1px solid #d6d7d9;
}
.field input:not([type=checkbox]):not([type=radio])::placeholder, .field select:not([multiple])::placeholder, .field textarea::placeholder {
  color: #50596D;
}
.field.has-error input:not([type=checkbox]):not([type=radio]), .field.has-error select:not([multiple]), .field.has-error textarea,
.field.has-error .field-addon--bd {
  border-color: #C13D3D !important;
}
.field.has-error label,
.field.has-error .label {
  color: #C13D3D;
}
.field textarea {
  border-radius: 2em;
  resize: none;
}
.field select:not([multiple]) {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #d5dde9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23272a2e' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
  background-position: right 32px top 50%;
  background-repeat: no-repeat;
  background-size: 12px auto;
  line-height: inherit;
  padding-right: 32px;
}
.field select:not([multiple]):-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.field select:not([multiple])::-ms-expand {
  display: none;
}
.field input::-webkit-outer-spin-button,
.field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.field input[type=number]:not([type=checkbox]):not([type=radio]) {
  -moz-appearance: textfield;
}

.label-inline {
  margin-bottom: 0;
  padding-bottom: calc(1rem + 1px);
  padding-top: calc(1rem + 1px);
}

.field--floating {
  margin-bottom: 1rem;
  padding: 0;
  position: relative;
}
.field--floating label:not(.label--sr-only),
.field--floating .label:not(.label--sr-only) {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid transparent;
  color: #50596D;
  left: 2rem;
  pointer-events: none;
  position: absolute;
  top: 0.75rem;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.field--floating input:not([type=checkbox]):not([type=radio]), .field--floating select:not([multiple]), .field--floating textarea {
  padding: 0.75rem 2rem;
}
.field--floating input:not([type=checkbox]):not([type=radio])::placeholder, .field--floating select:not([multiple])::placeholder, .field--floating textarea::placeholder {
  color: transparent;
}
.field--floating input:not([type=checkbox]):not([type=radio]):not(:placeholder-shown) ~ label:not(.label--sr-only),
.field--floating input:not([type=checkbox]):not([type=radio]):not(:placeholder-shown) ~ .label:not(.label--sr-only), .field--floating input:not([type=checkbox]):not([type=radio]):focus ~ label:not(.label--sr-only),
.field--floating input:not([type=checkbox]):not([type=radio]):focus ~ .label:not(.label--sr-only), .field--floating select:not([multiple]):not(:placeholder-shown) ~ label:not(.label--sr-only),
.field--floating select:not([multiple]):not(:placeholder-shown) ~ .label:not(.label--sr-only), .field--floating select:not([multiple]):focus ~ label:not(.label--sr-only),
.field--floating select:not([multiple]):focus ~ .label:not(.label--sr-only), .field--floating textarea:not(:placeholder-shown) ~ label:not(.label--sr-only),
.field--floating textarea:not(:placeholder-shown) ~ .label:not(.label--sr-only), .field--floating textarea:focus ~ label:not(.label--sr-only),
.field--floating textarea:focus ~ .label:not(.label--sr-only) {
  transform: scale(0.85) translateY(-1.625rem) translateX(0.15rem);
}

.field--small input:not([type=checkbox]):not([type=radio]), .field--small select:not([multiple]), .field--small textarea {
  background-color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 0.5rem;
}
.field--small select:not([multiple]) {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23272a2e' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
  background-position: right 16px top 50%;
  background-repeat: no-repeat;
  background-size: 12px auto;
  line-height: inherit;
  padding-right: 32px;
}
.field--small select:not([multiple]):-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.field--small select:not([multiple])::-ms-expand {
  display: none;
}

@supports (appearance: none) or (-moz-appearance: none) or (-webkit-appearance: none) {
  .field input[type=checkbox],
  .field input[type=radio] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #d5dde9;
    cursor: pointer;
    display: inline-block;
    height: 1.5rem;
    margin-right: 1rem;
    outline: 0;
    position: relative;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    vertical-align: top;
    width: 1.5rem;
  }
  .field input[type=checkbox]::after,
  .field input[type=radio]::after {
    content: "";
    display: block;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transition: opacity ease-in-out 0.2s;
  }
  .field input[type=checkbox]:checked,
  .field input[type=radio]:checked {
    background: #4472AB;
    border-color: #4472AB;
  }
  .field input[type=checkbox]:checked::after,
  .field input[type=radio]:checked::after {
    opacity: 1;
  }
  .field input[type=checkbox]:disabled,
  .field input[type=radio]:disabled {
    background-color: #f7f8fa;
    cursor: not-allowed;
  }
  .field input[type=checkbox]:disabled:hover,
  .field input[type=radio]:disabled:hover {
    border: 1px solid #d6d7d9;
  }
  .field input[type=checkbox]:disabled:checked,
  .field input[type=radio]:disabled:checked {
    border-color: #f7f8fa;
  }
  .field input[type=checkbox]:disabled + label,
  .field input[type=radio]:disabled + label {
    color: #aaa;
    cursor: not-allowed;
  }
  .field input[type=checkbox]:hover:not(:checked):not(:disabled), .field input[type=checkbox]:focus:not(:checked):not(:disabled),
  .field input[type=radio]:hover:not(:checked):not(:disabled),
  .field input[type=radio]:focus:not(:checked):not(:disabled) {
    border-color: #99a6b7;
  }
  .field input[type=checkbox]:focus,
  .field input[type=radio]:focus {
    outline: 0;
  }
  .field input[type=checkbox] + label,
  .field input[type=radio] + label {
    cursor: pointer;
    display: inline-block;
    margin-left: 0.25rem;
    vertical-align: top;
  }
  .field input[type=checkbox] {
    border-radius: 6px;
    padding: 0.25rem;
  }
  .field input[type=checkbox]::after {
    display: block;
    height: 8px;
    line-height: 0;
    width: 12px;
    z-index: 5;
  }
  .field input[type=checkbox]:checked::after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204L43.7 200.596c9.998-9.998 26.208-9.998 36.205 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0L504.5 108.8c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.4c-9.998 9.998-26.207 9.998-36.204 0z"/></svg>');
  }
  .field input[type=radio] {
    border-radius: 50%;
  }
  .field input[type=radio]::after {
    background: #fff;
    border-radius: 50%;
    content: "";
    display: block;
    height: 10px;
    left: 6px;
    top: 6px;
    width: 10px;
    z-index: 5;
  }
  .field.has-error input[type=checkbox],
  .field.has-error input[type=radio] {
    border-color: #C13D3D;
    color: #C13D3D;
  }
}
.field--checked {
  display: flex;
}

.field--checked__input {
  width: 1.5rem;
}

.field--checked__label {
  flex: 1 1 auto;
  padding-left: 1rem;
}

.field.field-addon,
.password-input.field-addon {
  background-color: #fff;
  border-radius: 99em;
  display: flex;
  padding: 0;
}
.field.field-addon.has-focus,
.password-input.field-addon.has-focus {
  box-shadow: 0 0 0 2px #305078;
}
.field.field-addon.field-addon--bd,
.password-input.field-addon.field-addon--bd {
  border: 1px solid #d5dde9;
  transition: all 0.15s ease-in;
}
.field.field-addon.field-addon--bd:hover,
.password-input.field-addon.field-addon--bd:hover {
  border-color: #99a6b7;
}
.field.field-addon.field-addon--small,
.password-input.field-addon.field-addon--small {
  border-radius: 6px;
}
.field.field-addon.field-addon--small input.field-addon__input,
.password-input.field-addon.field-addon--small input.field-addon__input {
  padding: 0.5rem;
}
.field.field-addon.field-addon--small .field-addon__item-btn,
.password-input.field-addon.field-addon--small .field-addon__item-btn {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.field.field-addon input.field-addon__input,
.password-input.field-addon input.field-addon__input {
  border: 0;
  flex: 1 0 0%;
  margin: 0;
  width: 100%;
}
.field.field-addon .field-addon__item,
.password-input.field-addon .field-addon__item {
  cursor: pointer;
  outline: 0;
  position: relative;
}
.field.field-addon .field-addon__item.btn,
.password-input.field-addon .field-addon__item.btn {
  padding-left: 2rem;
  padding-right: 2rem;
}
.field.field-addon .field-addon__item.btn.btn--icon,
.password-input.field-addon .field-addon__item.btn.btn--icon {
  padding-right: 1rem;
}
.field.field-addon .field-addon__item-btn,
.password-input.field-addon .field-addon__item-btn {
  background-color: transparent;
  border: 0;
  color: #d6d7d9;
  padding-left: 2rem;
  padding-right: 2rem;
}
.field.field-addon .field-addon__item-btn:hover,
.password-input.field-addon .field-addon__item-btn:hover {
  color: #418BDB;
}
.field.field-addon .field-addon__item-btn--text,
.password-input.field-addon .field-addon__item-btn--text {
  color: #525252;
}
.field.field-addon .field-addon__item__icon,
.password-input.field-addon .field-addon__item__icon {
  height: 1.5rem;
  width: 1.5rem;
}

hr {
  border: 0;
  border-top: 1px dashed #d6d7d9;
  display: block;
  height: 1px;
  margin: 0;
  padding: 0;
}

hr.copyright-line {
  border-top: 1px solid #536b8d;
  margin: 0 auto 1rem;
  max-width: 85px;
  width: 100%;
}
@media (min-width: 30.0625em) {
  hr.copyright-line {
    display: none;
  }
}

img {
  font-style: italic;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
}

.img--natural img,
.img--natural {
  width: auto;
}

.img--circle {
  border-radius: 50%;
}

.img--med, .img--small {
  display: block;
  height: auto;
}

.img--small {
  max-width: 128px;
}

.img--med {
  max-width: 204px;
}

a:not([class]),
a[class|=js] {
  color: #305078;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.15s ease-in;
}
a:not([class]):visited,
a[class|=js]:visited {
  color: #305078;
}
a:not([class]):hover, a:not([class]):focus,
a[class|=js]:hover,
a[class|=js]:focus {
  color: #418BDB;
}

ul li::marker {
  color: #305078;
}
ul.no-pad {
  padding-left: 0 !important;
}
ul.spaced li {
  padding-left: 1rem;
}

.checklist li {
  padding-inline-start: 0.5rem;
}
.checklist li::marker {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='16' height='16'%3E%3Cpath fill='%23305078' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

.hyphen-list li {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #305078;
  padding-inline-start: 0.5rem;
}
.hyphen-list li::marker {
  content: "‐";
}

li.file {
  list-style: none;
  padding-left: 0;
}
li.file::before {
  padding-right: 0.5rem;
  position: relative;
  top: 6px;
}
li.file.presentation::before {
  content: url("../images/resources/film.svg");
}
li.file.pdf::before {
  content: url("../images/resources/file-pdf.svg");
}
li.file.zip::before {
  content: url("../images/resources/file-zip.svg");
}

.table {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01), 0 6px 10px rgba(50, 50, 93, 0.07);
  border-radius: 8px;
  margin-bottom: 1rem;
  width: 100%;
}
.table td,
.table th {
  padding: 0.5rem;
}
.table th {
  background-color: #4472AB;
  color: rgb(255, 255, 255);
}
.table th:first-child {
  border-radius: 6px 0 0;
}
.table th:last-child {
  border-radius: 0 6px 0 0;
}
.table td {
  border-bottom: 1px solid #d6d7d9;
}
.table tr:nth-child(even) td {
  background-color: #fafafa;
}
.table tr:last-child td {
  border-bottom: 0;
}
.table tr:last-child td:first-child {
  border-radius: 0 0 0 6px;
}
.table tr:last-child td:last-child {
  border-radius: 0 0 6px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.hd-sub {
  font-size: 1.25rem;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}
@media (min-width: 87.5em) {
  .hd-sub {
    font-size: 1.5rem;
  }
}

.hd-icon {
  background-color: #e9e9e9;
  align-items: center;
  border-radius: 8px;
  display: flex;
  padding: 1rem;
}
@media (max-width: 48em) {
  .hd-icon {
    justify-content: center;
  }
}

.hd-icon__heading {
  line-height: 1;
  margin-bottom: 0;
}
@media (min-width: 48.0625em) {
  .hd-icon__heading {
    flex: 1 0 auto;
  }
}

.hd-icon__img {
  flex: 0 0 auto;
  height: 2.5rem;
  margin-right: 1rem;
  width: 2.5rem;
}

.semibold {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

.gotham-18 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
}

.hd-16-black {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 1rem;
}

.hd-24-bold {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

.hd-26-black {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 1.625rem;
}

.hd-26-bold {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.2;
}

.hd-36-black {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 2.25rem;
}

.hd-40-black {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
}

.hd-32-bold {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

.text-14 {
  font-size: 0.875rem;
}

.text-20 {
  font-size: 1.25rem;
}

.text-22 {
  font-size: 1.375rem;
}

.u-padding--b-xxl {
  padding-bottom: 4rem !important;
}

.u-padding--t-s {
  padding-top: 0.5rem !important;
}

.u-padding--t-xxl {
  padding-top: 4rem !important;
}

.u-margin--b-0 {
  margin: 0;
}

.u-margin--b-xs {
  margin-bottom: 0.25rem !important;
}

.u-margin--b-s {
  margin-bottom: 0.5rem !important;
}

.u-margin--b-m {
  margin-bottom: 1rem !important;
}

.u-margin--b-xm {
  margin-bottom: 1.5rem !important;
}

.u-margin--b-l {
  margin-bottom: 2rem !important;
}

.u-margin--b-lm {
  margin-bottom: 2.5rem !important;
}

.u-margin--b-xl {
  margin-bottom: 3rem !important;
}

.u-margin--b-xxl {
  margin-bottom: 4rem !important;
}

.u-margin--b-xxml {
  margin-bottom: 4.5rem !important;
}

.u-margin--b-xxxl {
  margin-bottom: 5rem !important;
}

.u-margin--t-m {
  margin-top: 1rem !important;
}

.u-margin--t-xl {
  margin-top: 3rem !important;
}

@media (max-width: 48em) {
  .u-margin--s--b-m {
    margin-bottom: 1rem;
  }
}

@media (max-width: 48em) {
  .u-margin--s--b-l {
    margin-bottom: 2rem;
  }
}

@media (min-width: 48.0625em) {
  .u-margin--m--b-xxxl {
    margin-bottom: 5rem;
  }
}

@media (max-width: 87.4375em) {
  .u-margin--xxl--b-l--max {
    margin-bottom: 2rem;
  }
}

@media (min-width: 87.5em) {
  .u-margin--xxl--b-xxxl {
    margin-bottom: 5rem;
  }
}

.u-margin--resp-l--0--xl {
  margin-bottom: 0;
}
@media (min-width: 60em) {
  .u-margin--resp-l--0--xl {
    margin-bottom: 3rem;
  }
}

@media (min-width: 48.0625em) {
  .u-resp-hide--m {
    display: none;
  }
}

@media (max-width: 48em) {
  .u-resp-hide--m--max {
    display: none;
  }
}

@media (max-width: 30em) {
  .u-resp-hide--s--max {
    display: none;
  }
}

img.u-center--img,
.u-center--img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.u-ta--left {
  text-align: left !important;
}

.u-ta--center {
  text-align: center !important;
}

.u-ta--right {
  text-align: right !important;
}

.u-tt--upper {
  letter-spacing: 0.025em;
  text-transform: uppercase !important;
}

.bg--blue {
  background-color: #305078 !important;
}

.bg--gray-light {
  background-color: #f7f8fa !important;
}

.bg--gray-light-med {
  background-color: #f4f4f4 !important;
}

.bg--slate-x-light {
  background-color: #EFF1F5 !important;
}

.bg--white {
  background-color: #fff !important;
}

.u-c--green {
  color: #3C9D36 !important;
}

.u-c--red {
  color: #C13D3D !important;
}

.u-c--black {
  color: #272a2e !important;
}

.u-c--blue {
  color: #305078 !important;
}

.u-c--white {
  color: #fff !important;
}

.u-c--gray {
  color: #aaa !important;
}

.u-c--dark-gray {
  color: #525252 !important;
}

.u--pos-r {
  position: relative;
}

.is-hidden {
  display: none;
}

.accordion-btn {
  background-color: transparent;
  border: 0;
  display: flex;
  font: inherit;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  text-align: left;
  width: 100%;
}
.accordion-btn.is-visible .accordion-btn__icon {
  transform: rotate(180deg);
}

.accordion-btn__icon {
  fill: #fff;
  flex-shrink: 0;
  height: 16px;
  margin-left: 0.25em;
  margin-top: 0.25em;
  transform: rotate(0);
  transition: transform 0.15s ease-in;
  width: 16px;
}

.accordion-section {
  display: none;
}
.accordion-section.is-visible {
  display: block;
}

.accordion-question-list {
  list-style: none;
  padding: 0;
}
@media (min-width: 48.0625em) {
  .accordion-question-list {
    padding-right: 3rem;
  }
}
.accordion-question-list > li {
  border-bottom: 1px dashed #d6d7d9;
}
.accordion-question-list .accordion-btn {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.accordion-question-list .accordion-btn:hover {
  color: #305078;
}

.accordion-question-list__heading {
  font-size: 1.125rem;
  margin-bottom: 0;
}

.app-icons {
  display: flex;
  flex-wrap: wrap;
}

.app-icons__link {
  align-items: center;
  color: #272a2e;
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .app-icons__link {
    width: 50%;
  }
}
@media (min-width: 48.0625em) {
  .app-icons__link {
    width: 33.3333333333%;
  }
}
.app-icons__link:hover {
  color: #418BDB;
}
.app-icons__link:hover svg {
  color: #418BDB;
}
.app-icons__link svg {
  background-color: #d5dde9;
  border-radius: 50%;
  color: #223854;
  height: 4rem;
  flex: 0 0 auto;
  margin-right: 1rem;
  width: 4rem;
}
.app-icons__link span {
  flex: 1 0 0%;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .app-icons__link span {
    padding-right: 1rem;
  }
}

.app-icons--small .app-icons__link svg {
  height: 3rem;
  margin-right: 0.5rem;
  width: 3rem;
}

.app-reviews {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.app-reviews__item {
  background-color: #f7f8fa;
  border-radius: 10px;
  margin: 0 0.75rem 1.5rem;
  padding: 1.5rem;
}
@media (min-width: 60em) {
  .app-reviews__item {
    width: calc(33.3333333333% - 1.5rem);
  }
}

@media (min-width: 30.0625em) {
  .app-reviews__header {
    display: flex;
    justify-content: space-between;
  }
}

.app-reviews__meta {
  color: #525252;
}
.app-reviews__meta strong {
  display: inline-block;
  margin-right: 0.5rem;
}
@media (min-width: 30.0625em) {
  .app-reviews__meta {
    order: 2;
    text-align: right;
  }
  .app-reviews__meta strong {
    display: block;
    margin-right: 0;
  }
}

.back-to-top-btn {
  background-color: #fff;
  border: 0;
  border-radius: 0.375rem 0 0 0.375rem;
  bottom: 4rem;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  color: #305078;
  cursor: pointer;
  height: 4rem;
  line-height: 2;
  opacity: 1;
  outline: 0;
  padding: 0 0.5em;
  position: fixed;
  right: 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.25s ease-in-out;
  width: 4rem;
  z-index: 999;
}
.back-to-top-btn:hover {
  color: #4472AB;
}
.back-to-top-btn .icon {
  display: inline-block;
  fill: #305078;
  height: 3rem;
  position: relative;
  top: -0.0625em;
  vertical-align: middle;
  width: 3rem;
}
.back-to-top-btn.hidden {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.box {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
}
@media ((min-width: 30.0625em)) {
  .box {
    padding: 2rem;
  }
}

.box--alt {
  padding: 0;
}

.box__header {
  background-color: #305078;
  border-radius: 10px 10px 0 0;
  padding: 0.5rem 2rem;
}

.box__heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #fff;
  font-size: 1.875rem;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 30.0625em) {
  .box__heading {
    font-size: 2.5rem;
    text-align: left;
  }
}

.box__body {
  padding: 2rem;
}

.card-wrap {
  display: flex;
  flex-direction: column;
}
.card-wrap .card {
  flex: 1 0 auto;
}

.card {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem;
}

.card--link {
  transition: transform 0.25s ease-in-out;
}
.card--link:hover {
  transform: translate3d(0, -8px, 0);
}

@media (min-width: 60em) {
  .card__img--fade {
    margin-bottom: -3rem;
  }
}

.card__heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

.card__link {
  color: #305078;
  display: block;
  text-decoration: none;
  transition: color 0.15s ease-in;
}
.card__link:visited {
  color: #305078;
}
.card__link:hover, .card__link:focus {
  color: #418BDB;
}

.card__link--arrow {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: inline-block;
}
.card__link--arrow [role=img] {
  display: inline-block;
  height: 2.5rem;
  margin-left: 0.5rem;
  width: 2.5rem;
}

.card__body {
  padding: 0.5rem 1rem;
}
@media (min-width: 60em) {
  .card__body {
    padding: 1rem 2rem;
  }
}

.canvas-close {
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 800;
}
.canvas-close.is-active {
  display: block;
}
.canvas-close.is-visible {
  opacity: 1;
}

.flickity-enabled.hide-arrows .flickity-prev-next-button {
  display: none;
}

.flickity-viewport {
  width: 100%;
}

.flickity-button {
  color: #305078;
}

.flickity-prev-next-button {
  background-color: transparent;
}
.flickity-prev-next-button:hover {
  background-color: transparent;
}

.flickity-prev-next-button.previous {
  left: 0;
}

.flickity-prev-next-button.next {
  right: 0;
}

.vendor-logo-grid--responsive .flickity-prev-next-button.previous {
  left: -10px;
}
.vendor-logo-grid--responsive .flickity-prev-next-button.next {
  right: -10px;
}

/* fade in image when loaded */
.flickity-lazyload {
  transition: opacity 0.4s;
  opacity: 0;
}

.flickity-lazyload.flickity-lazyloaded,
.flickity-lazyload.flickity-lazyerror {
  opacity: 1;
}

.flickity-page-dots {
  left: 0;
}
.flickity-page-dots .dot {
  background: #fff;
  border: 1px solid #305078;
  opacity: 1;
}
.flickity-page-dots .dot.is-selected {
  background-color: #305078;
}

table.grid-table {
  min-width: 600px;
  width: 100%;
  display: grid;
  border-collapse: collapse;
  grid-template-columns: 1fr 20% 20%;
}
table.grid-table thead,
table.grid-table tbody,
table.grid-table tr {
  display: contents;
}
table.grid-table th,
table.grid-table td {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  white-space: nowrap;
}
table.grid-table th {
  position: sticky;
  top: 0;
  background: #4472AB;
  text-align: left;
  font-weight: normal;
  color: #fff;
}
table.grid-table td {
  background: #fff;
  border: 1px solid #d6d7d9;
  border-bottom: 0;
  border-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #272a2e;
}
table.grid-table td:last-child {
  border-right: 1px solid #d6d7d9;
}
table.grid-table tr:last-child td {
  border-bottom: 1px solid #d6d7d9;
}

.site-header {
  background-color: #fff;
  left: 0;
  padding: 1.5rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 500;
}
.site-header.is-transparent {
  background-color: transparent;
}
@media (min-width: 53.8125em) {
  .site-header {
    background-color: transparent;
  }
}
@media (max-width: 53.6875em) {
  .site-header {
    padding: 0.75rem 1.5rem;
  }
  .site-header .logo,
  .site-header .logo svg {
    height: 2.625rem;
    width: 7.75rem;
  }
}
.site-header.is-fixed {
  background-color: #fff;
  bottom: 100%;
  position: fixed;
  top: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 505;
}
.site-header.is-visible {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  transform: translate3d(0, 100%, 0);
}

.site-header__inner {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 87.5rem;
}

.site-header__nav {
  align-content: center;
  display: none;
}
@media (min-width: 53.8125em) {
  .site-header__nav {
    display: flex;
  }
}

.site-nav {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
}
.site-nav .has-submenu {
  position: relative;
}
.site-nav .accordion-btn__icon {
  height: 0.75rem;
  margin-top: 0;
  width: 0.75rem;
}

.site-nav__item {
  flex-shrink: 0;
}

.site-nav__link {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  transition: color 0.15s ease-in;
}
@media (min-width: 60em) {
  .site-nav__link {
    padding: 0.5rem 1rem;
  }
}
@media (min-width: 80em) {
  .site-nav__link {
    padding: 0.5rem 2rem;
  }
}
.site-nav__link:link, .site-nav__link:visited {
  color: #305078;
}
.site-nav__link:hover, .site-nav__link[aria-expanded=true] {
  color: #418BDB;
}
.site-nav__link.is-active {
  text-decoration: underline;
}

@media (max-width: 79.9375em) {
  .site-nav .btn {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.site-nav__submenu-wrap {
  display: none;
}

.site-nav__submenu {
  list-style: none;
  padding: 1rem;
}
.site-nav__submenu .site-nav__link {
  padding: 0.5rem;
  white-space: nowrap;
}
.site-nav__submenu .site-nav__link:link, .site-nav__submenu .site-nav__link:visited {
  color: #fff;
}
.site-nav__submenu .site-nav__link:hover {
  color: #d6d7d9;
}

.no-js .site-nav__submenu {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.15);
  background-color: #305078;
  border-radius: 8px;
  display: none;
  position: absolute;
  top: 100%;
}
.has-submenu:hover .no-js .site-nav__submenu {
  display: block;
}

.tippy-box[data-theme~=navigation] {
  background-color: #305078;
  border-radius: 10px;
  color: #fff;
}

body.has-mobile-menu {
  overflow: hidden;
}

.canvas-close--mobile-menu {
  background-color: rgba(0, 36, 65, 0.98);
  padding: 1rem;
}

.mobile-menu-btn {
  background-color: transparent;
  border: 0;
  color: #305078;
  display: inline-block;
  padding: 0;
}
@media (min-width: 53.8125em) {
  .mobile-menu-btn {
    display: none;
  }
}

.mobile-menu {
  display: none;
  opacity: 0;
  outline: 0;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.mobile-menu.is-active {
  display: block;
}
.mobile-menu.is-visible {
  opacity: 1;
}

.mobile-nav,
.mobile-nav__subnav {
  list-style: none;
}

.mobile-nav__item {
  margin-bottom: 1rem;
}

.mobile-menu__header {
  margin-bottom: 3rem;
  padding: 1rem;
  position: relative;
}
.mobile-menu__header .logo {
  color: #fff;
}

.mobile-menu__close {
  background-color: transparent;
  border: 0;
  color: #fff;
  display: block;
  height: 2.5rem;
  margin-top: -20px;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  width: 2.5rem;
}

.mobile-nav__section {
  display: none;
}
.mobile-nav__section.is-visible {
  display: block;
}

.mobile-nav__link {
  background: transparent;
  border: 0;
  color: #fff;
  display: block;
  font-size: 1.375rem;
  padding: 0;
  text-align: left;
  text-decoration: none;
  width: 100%;
}
.mobile-nav__link:link, .mobile-nav__link:visited {
  color: #fff;
}

.icon-list {
  list-style: none;
}
.icon-list img,
.icon-list [role=img] {
  display: inline-block;
  height: 1rem;
  margin-right: 1rem;
  width: 1rem;
}

.iframe {
  border: 1px solid #aaa;
}
.iframe.lazyloaded {
  border: 0;
}

.inc-5000-layout {
  margin-bottom: 2rem;
}
@media (min-width: 60em) {
  .inc-5000-layout {
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: 1fr 360px;
    margin-bottom: 0.5rem;
  }
}

.inc-5000-layout--spaced {
  margin-bottom: 8rem !important;
}

.inc-5000 {
  background-color: #f7f8fa;
  border-radius: 6px;
  margin: 0 auto;
  max-width: 22.5rem;
  padding: 2rem;
}
@media (max-width: 59.9375em) {
  .inc-5000 {
    margin-top: 2rem;
  }
}

.inc-5000__perks {
  font-size: 2rem;
}

.inc-5000__recipient {
  font-size: 1.125rem;
}

.inc-5000__perks,
.inc-5000__recipient {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #305078;
}

.inc-5000__recipient {
  font-size: 1.125rem;
}

.featured-videos .flickity-page-dots {
  margin-top: 2rem;
  position: static;
}
.featured-videos .flickity-page-dots .dot {
  background-color: #fff;
  border: 1px solid #223854;
  opacity: 1;
}
.featured-videos .flickity-page-dots .dot.is-selected {
  background-color: #223854;
}
.featured-videos .flickity-prev-next-button {
  background-color: #fff;
  height: 64px;
  opacity: 0.75;
  transition: opacity 0.3s ease-in;
  width: 48px;
}
.featured-videos .flickity-prev-next-button:hover {
  opacity: 1;
}
.featured-videos .flickity-prev-next-button.previous {
  border-radius: 0 8px 8px 0;
  left: 0;
}
.featured-videos .flickity-prev-next-button.next {
  border-radius: 8px 0 0 8px;
  right: 0;
}
.featured-videos .flickity-prev-next-button .arrow {
  fill: #305078;
}
.featured-videos .flickity-prev-next-button[disabled] {
  opacity: 0.5 !important;
}
.featured-videos .flickity-prev-next-button[disabled] .arrow {
  fill: #aaa;
}

.featured-videos__item {
  display: inline-block;
  font-size: 0.875rem;
  transform: scale(0.85, 0.85);
  transition: 0.25s transform ease-in-out;
  width: 360px;
}
.featured-videos__item.is-selected {
  transform: scale(1, 1);
}

.featured-videos__meta {
  background-color: #f4f4f4;
  display: flex;
  padding: 1rem;
}
.featured-videos__meta img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
}
.featured-videos__meta h3 {
  padding-left: 1rem;
}

.site-footer {
  background-color: #f4f4f4;
  padding: 3rem 1.5rem 3rem;
}

.site-footer__branding,
.footer-nav-grid {
  margin: 0 auto;
  max-width: 87.5rem;
}

.site-footer__branding {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.site-footer__branding::after {
  background: url("../images/border.svg") repeat-x 0 0;
  bottom: 0;
  content: "";
  left: 0;
  height: 1px;
  position: absolute;
  width: 100%;
}
@media (min-width: 34em) {
  .site-footer__branding {
    flex-direction: row;
  }
}
@media (min-width: 34em) {
  .site-footer__branding .logo {
    margin-right: 1rem;
  }
}
.site-footer__branding .logo,
.site-footer__branding .logo svg {
  height: 3.625rem;
  width: 10.9375rem;
}
@media (min-width: 34em) {
  .site-footer__branding .logo,
  .site-footer__branding .logo svg {
    height: 2.625rem;
    width: 7.75rem;
  }
}

.site-footer__branding--solo {
  margin-bottom: 0;
  padding-bottom: 0;
}
.site-footer__branding--solo::after {
  display: none;
}

.site-footer__tagline {
  color: #8595aa;
  line-height: 1.5;
  font-size: 1.625rem;
  font-style: italic;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 34em) {
  .site-footer__tagline {
    border-left: 2px solid #e4e5e6;
    color: #305078;
    padding-left: 1rem;
    text-align: left;
  }
}

.footer-nav-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
}
@media (max-width: 30em) {
  .footer-nav-grid {
    flex-direction: column;
  }
}
@supports (display: grid) {
  .footer-nav-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.footer-nav-grid__item {
  padding-right: 1rem;
}
@media (min-width: 30.0625em) {
  .footer-nav-grid__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 48.0625em) {
  .footer-nav-grid__item {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .footer-nav-grid__item {
    grid-column: auto/span 4;
    max-width: none;
  }
  @media (min-width: 30.0625em) {
    .footer-nav-grid__item {
      grid-column: auto/span 2;
      max-width: none;
    }
  }
  @media (min-width: 48.0625em) {
    .footer-nav-grid__item {
      grid-column: auto/span 1;
      max-width: none;
    }
  }
}

.footer-nav__heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: block;
  color: #2c2b2b;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 0.25rem;
  width: 100%;
}
.footer-nav__heading.is-accordion {
  display: none;
}
@media (max-width: 30em) {
  .footer-nav__heading {
    display: none;
    margin-bottom: 0.25rem;
  }
  .footer-nav__heading.is-accordion {
    display: block;
  }
}

.footer-nav__btn {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  background: transparent;
  border: 0;
  display: flex;
  padding: 0;
  text-align: left;
  text-decoration: none;
}
.footer-nav__btn:link, .footer-nav__btn:visited {
  color: #c8dcf0;
}
.footer-nav__btn .accordion-btn__icon {
  display: none;
  height: 10px;
  width: 10px;
}
@media (max-width: 30em) {
  .footer-nav__btn {
    padding: 0.5rem 0;
  }
  .footer-nav__btn .accordion-btn__icon {
    display: flex;
  }
}

@media (max-width: 30em) {
  .footer-nav {
    display: none;
  }
  .footer-nav.is-visible {
    display: block;
  }
}

.footer-nav__list {
  margin-bottom: 2rem;
  padding: 0;
}
@media (max-width: 30em) {
  .footer-nav__list {
    margin-bottom: 1rem;
  }
}

.footer-nav__item {
  list-style: none;
}

.footer-nav__link {
  text-decoration: none;
}
.footer-nav__link:link, .footer-nav__link:visited {
  color: #525252;
}

.copyright {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}
@media (min-width: 30.0625em) {
  .copyright {
    flex-direction: row;
  }
}

.copyright__item {
  color: rgba(254, 254, 254, 0.9);
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
  text-align: center;
}
@media (min-width: 30.0625em) {
  .copyright__item {
    border-right: 1px solid rgba(254, 254, 254, 0.9);
  }
  .copyright__item:last-child {
    border: 0;
  }
}
.copyright__item a {
  text-decoration: none;
  transition: color 0.2s ease-in;
}
.copyright__item a:link, .copyright__item a:visited {
  color: rgba(254, 254, 254, 0.9);
}
.copyright__item a:hover {
  color: #fefefe;
}

@media (max-width: 30em) {
  .copyright__item--social {
    display: flex;
    justify-content: space-between;
    max-width: 180px;
    width: 100%;
  }
}
.copyright__item--social a {
  display: inline-block;
  transition: opacity 0.2s ease-in;
  vertical-align: middle;
  width: 1.25rem;
}
.copyright__item--social a:link, .copyright__item--social a:visited {
  opacity: 0.9;
}
.copyright__item--social a:hover {
  opacity: 1;
}
.copyright__item--social img {
  height: auto;
  width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.grid {
  display: grid;
}

.grid--two-col {
  grid-template-columns: 1fr;
}
@media (min-width: 48.0625em) {
  .grid--two-col {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-flex {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  margin-left: -1rem;
  margin-top: 0;
  padding: 0;
}
.grid-flex > .grid__item {
  margin-top: 0;
  padding-left: 1rem;
  width: 100%;
}
.grid-flex > .grid__item.c-1of2 {
  flex-basis: 50%;
  max-width: 50%;
}
@media (min-width: 30.0625em) {
  .grid-flex > .grid__item.s--c-1of2 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 48.0625em) {
  .grid-flex > .grid__item.m--c-1of2 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid-flex > .grid__item.m--c-1of3 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid-flex > .grid__item.m--c-2of3 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid-flex > .grid__item.m--c-2of10 {
    flex-basis: 20%;
    max-width: 20%;
  }
  .grid-flex > .grid__item.m--c-8of10 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media (min-width: 60em) {
  .grid-flex > .grid__item.l--c-1of2 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid-flex > .grid__item.l--c-1of3 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (max-width: 59.9375em) {
  .grid-flex > .grid__item.l--max--order-first {
    order: -1;
  }
}

.grid-flex--middle {
  align-items: center;
}

.grid-flex--bottom {
  align-items: flex-end;
}

.media-flex {
  align-items: flex-start;
  display: flex;
}
.media-flex.media--middle {
  align-items: center;
}
.media-flex.media--center {
  justify-content: center;
}
.media-flex .media__figure {
  margin: 0 1.5rem 0 0;
}
.media-flex .media__figure.media__figure--alt {
  flex-shrink: 0;
}
.media-flex:not(.media--auto) .media__body {
  flex: 1;
}
.media-flex .media__body,
.media-flex .media__body :last-child {
  margin-bottom: 0;
}

@media (min-width: 48.0625em) {
  .text-cols-2 {
    columns: 2 auto;
  }
}

.text-cols__col {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.sub-nav-left-layout {
  margin: 0 auto;
  max-width: 87.5rem;
  padding: 1.5rem;
}
.sub-nav-left-layout .table_wrap {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll; /* has to be scroll, not auto */
}

.sub-nav-left-nav {
  margin: 0 auto 2rem;
  max-width: 400px;
}

.sub-nav-left-layout--rev {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 60em) {
  .sub-nav-left-layout--rev {
    flex-direction: row;
  }
}

@media (min-width: 60em) {
  .sub-nav-left-layout {
    display: flex;
    padding: 4rem 1rem;
  }
  @supports (display: grid) {
    .sub-nav-left-layout {
      display: grid;
      grid-template-columns: 400px minmax(0, 1fr);
    }
  }
  .sub-nav-left-layout .table_wrap {
    overflow-x: auto;
  }
  .sub-nav-left-content {
    flex: 1 0 0%;
    max-width: 100%;
    padding-left: 3rem;
  }
  .sub-nav-left-nav {
    margin: 0;
    position: sticky;
    top: 3rem;
  }
}
@media (min-width: 60em) {
  .sub-nav-left-nav--stacked {
    position: static;
  }
}

.sub-nav-left-nav {
  background-color: #fff;
  box-shadow: 0px 0px 58.5px 6.5px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  padding: 1.5rem;
}

.sub-nav-left-icon-list {
  padding: 0;
  margin: 0;
}
.sub-nav-left-icon-list ul {
  padding-left: 2.5rem;
}
.sub-nav-left-icon-list li {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
}
.sub-nav-left-icon-list li li {
  padding-left: 0.5rem;
}
.sub-nav-left-icon-list a {
  border-radius: 6px;
  display: inline-flex;
  font-size: 1.125rem;
  padding: 1rem 2rem;
  text-decoration: none;
  width: 100%;
}
.sub-nav-left-icon-list a:link, .sub-nav-left-icon-list a:visited {
  color: #272a2e;
}
.sub-nav-left-icon-list a:hover {
  color: #418BDB;
}
.sub-nav-left-icon-list a.is-active {
  background-color: #e6ebf2;
  color: #272a2e;
}
.sub-nav-left-icon-list img {
  height: 1.5rem;
  margin-right: 1.5rem;
  width: 1.5rem;
}

.sub-nav-left-heading {
  padding: 0 1rem;
}

.sub-nav-left-list {
  padding-left: 1em;
}
.sub-nav-left-list > li {
  margin-bottom: 0.5rem;
}
.sub-nav-left-list a {
  display: block;
}

@keyframes bounce {
  80%, 100% {
    opacity: 0.2;
    transform: translate3d(0, 16px, 0);
  }
}
.loading-animation {
  padding: 2rem;
}

.loading-animation__circles {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
.loading-animation__circles .circle {
  animation: 0.9s bounce infinite alternate;
  background-color: #305078;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin: 0 8px;
}
.loading-animation__circles .circle:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-animation__circles .circle:nth-child(3) {
  animation-delay: 0.4s;
}

.logo {
  color: #305078;
  width: 9.6875rem;
}
.logo a:link, .logo a:visited, .logo a:hover, .logo a:focus, .logo a:active {
  color: #305078;
}
.logo svg {
  height: 3.25rem;
  width: 9.6875rem;
}

mark {
  color: inherit;
  display: inline-block;
  position: relative;
  z-index: 2;
}
mark.underline {
  background-color: transparent;
}
mark.underline::before, mark.underline::after {
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  z-index: -1;
}
mark.underline::before {
  height: 0.12em;
  top: 0.98em;
  left: 6px;
  background: rgba(244, 235, 120, 0.8);
  transform: rotate(-2deg);
}
mark.underline::after {
  height: 0.18em;
  top: 1em;
  left: -2px;
  background: rgba(244, 235, 120, 0.8);
  border-bottom-left-radius: 6%;
  border-bottom-right-radius: 16%;
  border-top-left-radius: 90%;
  transform: rotate(-1deg);
}
mark.highlight {
  background-color: transparent;
}
mark.highlight::after {
  background: no-repeat url("../images/mark-2-dull.png") center/100% 100%;
  bottom: 0;
  content: " ";
  display: block;
  left: -0.25em;
  position: absolute;
  right: -0.25em;
  top: 0;
  z-index: -1;
}
mark.highlight--v2:after {
  background-image: url("../images/mark-1-dull.png");
}
mark.highlight--v3 {
  display: block;
}
mark.highlight--v3::after {
  background-image: url("../images/mark-double.png");
  top: 0.5em;
}

.masthead-heading,
.masthead-subheading,
.masthead-text,
.masthead-list {
  color: #305078;
}

.masthead-heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 3rem;
  line-height: 1;
  text-align: center;
}
@media (min-width: 48.0625em) {
  .masthead-heading {
    font-size: 4.5rem;
    text-align: left;
  }
}

.masthead-subheading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 2.5rem;
}

.masthead-text {
  font-size: 1.625rem;
}

.masthead-text--medium {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

.masthead-list {
  font-size: 1.25rem;
  padding-left: 1em;
}

.masthead-tagline {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 1.125rem;
}
@media (min-width: 30.0625em) {
  .masthead-tagline {
    font-size: 1.5625rem;
  }
}

.masthead-quote {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #305078;
  padding-left: 3.5rem;
  position: relative;
}
.masthead-quote::after {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  content: "“";
  font-size: 4rem;
  left: 0;
  line-height: 1;
  padding-right: 1.5rem;
  position: absolute;
  top: 0;
}
.masthead-quote .masthead-text {
  font-size: 1.5rem;
}
.masthead-quote .masthead__src {
  font-size: 1.125rem;
  font-style: italic;
}

.masthead {
  align-items: center;
  background-color: #edecec;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 4px solid #fff;
  display: flex;
  height: 38.125rem;
  justify-content: center;
  padding: 1rem;
}
.masthead h1 {
  margin: auto;
}
.masthead .masthead-content {
  margin: auto;
  max-width: 87.5rem;
  width: 100%;
}

.masthead--right {
  background-position: right bottom;
}

@media (max-width: 48em) {
  .masthead-hide {
    display: none;
  }
}

.mobile-icons {
  text-align: center;
}
.mobile-icons .mobile-icons__app {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}
.mobile-icons .mobile-icons__app:last-child {
  margin-right: 0;
}
.mobile-icons img {
  width: 135px;
}

@media (min-width: 48.0625em) {
  .mobile-icons--large img {
    width: 216px;
  }
}

.modal__overlay, .modal {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

body.has-modal {
  overflow: hidden;
}

.modal {
  background-color: #223854;
  display: none;
  opacity: 0;
  outline: 0;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 800;
}
.modal .modal__content {
  opacity: 0;
  outline: 0;
  transform: translate3d(0, -8px, 0);
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s, transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s;
}
.modal.is-active {
  display: block;
}
.modal.is-visible {
  opacity: 1;
}
.modal.is-visible .modal__content {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.modal__close {
  background-color: transparent;
  border: 0;
  color: #fff;
  display: block;
  height: 2.5rem;
  padding: 0.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2.5rem;
}

.modal__overlay {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 30.0625em) {
  .modal__overlay {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.modal__content {
  background-color: #fff;
  border-radius: 10px;
  margin: 5rem auto;
  max-width: 58rem;
}

.modal--savings-calc .modal__content {
  max-width: 64rem;
}

.modal--login .modal__close {
  color: #8aa1be;
}
.modal--login .modal__content {
  background-color: #f5f7fc;
  max-width: 87.5rem;
  padding: 9rem 1.5rem;
}
@media (min-width: 48.0625em) {
  .modal--login .modal__content {
    padding: 4rem 2rem;
  }
}

.modal__header {
  padding-top: 2rem;
}

@media (max-width: 59.9375em) {
  .modal__header--padded .section-heading,
  .modal__header--padded .section-heading__sub {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.modal__header--bg {
  background-repeat: no-repeat;
  background-position: 50% 0;
  min-height: 376px;
  padding: 1.5rem;
}
@media (min-width: 48.0625em) {
  .modal__header--bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 314px;
  }
}
.modal .modal__header--bg {
  border-radius: 8px 8px 0 0;
}
.modal__header--bg.request-demo-header {
  background-image: url("../images/request-demo/request-demo-exit-intent-mobile.jpg");
  background-size: cover;
}
@media (min-width: 30.0625em) {
  .modal__header--bg.request-demo-header {
    background-image: url("../images/request-demo/request-demo-exit-intent.jpg");
    padding: 3rem;
  }
}
.modal__header--bg.perks-report-header {
  background-color: #f4f4f4;
  background-image: url("../images/perks-report/perks-comparison-exit-intent-mobile.jpg");
  background-position: right center;
  background-size: auto auto;
}
@media (min-width: 33.8125em) {
  .modal__header--bg.perks-report-header {
    background-position: right bottom;
  }
}
@media (min-width: 53.8125em) {
  .modal__header--bg.perks-report-header {
    background-image: url("../images/perks-report/perks-comparison-exit-intent.jpg");
    padding: 3rem;
  }
}
.modal__header--bg .modal__header__text {
  font-size: 1.375rem;
}

.modal__header--solid {
  background-color: #f6f6f4;
}
.modal .modal__header--solid {
  border-radius: 8px 8px 0 0;
}

.modal__header__content {
  padding: 0 2rem;
}
@media (min-width: 48.0625em) {
  .modal__header__content {
    padding-right: 0;
  }
}

.modal__content--pad {
  padding: 2rem;
}

.modal__header__text {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #305078;
}
@media (min-width: 48.0625em) {
  .modal__header__text {
    font-size: 1.375rem;
  }
}

.modal__header--img-wrap:after {
  clear: both;
  content: "";
  display: table;
}
@media (min-width: 48.0625em) {
  .modal__header--img-wrap img {
    float: right;
  }
}
@media (max-width: 48em) {
  .modal__header--img-wrap {
    display: flex;
    flex-direction: column;
  }
  .modal__header--img-wrap .modal__header__content {
    margin-bottom: 2rem;
    order: -1;
  }
}

.multimedia {
  height: 0;
  margin: 0 auto;
  padding-bottom: 56.25%;
  position: relative;
}

.multimedia embed,
.multimedia object,
.multimedia video,
.multimedia iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0 !important;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.multimedia--3-4 {
  padding-bottom: 133.3333333%;
}

.multimedia--standard {
  padding-bottom: 75%;
}

.nav {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.nav--piped > li {
  border-right: 1px solid #d6d7d9;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.nav--piped > li:first-child {
  padding-left: 0;
}
.nav--piped > li:last-child {
  border: 0;
}

.perks-widget-iframe {
  border: 1px solid #ccc;
  height: 400px;
  margin-bottom: 1rem;
  width: 100%;
}

.perks-widget-iframe--browser {
  border: 0;
  border-radius: 0 0 6px 6px;
  height: 600px;
  margin-bottom: 0;
}

.perks-widget-layout {
  background-color: rgb(250, 250, 250);
  max-width: 100%;
}
.perks-widget-layout main {
  max-width: 100%;
  min-height: 0;
}

.perks-body {
  background-color: rgb(250, 250, 250);
  margin-left: auto;
  margin-right: auto;
  max-width: 87.5rem;
  padding: 2rem;
}
.perks-body h2 {
  border-bottom: 1px solid #ccc;
  color: #525252;
  font-size: 1.3125rem;
}

.perks-widget {
  display: flex;
  flex-direction: column;
}
@media (min-width: 48.0625em) {
  .perks-widget {
    flex-direction: row;
  }
}

@media (min-width: 48.0625em) {
  .perks-widget__cats {
    width: 20%;
  }
}
.perks-widget__cats ul {
  padding-left: 0;
}
.perks-widget__cats ul > li {
  list-style: none;
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.perks-widget__cats ul ul > li {
  font-size: 0.875rem;
  list-style-type: disc;
  margin-bottom: 0;
  margin-left: 1.5em;
}

@media (min-width: 48.0625em) {
  .perks-widget__offers {
    padding-left: 24px;
    width: 80%;
  }
}
.perks-widget__offers ul li {
  margin-bottom: 0.25rem;
}
.perks-widget__offers ul li p {
  margin-bottom: 0;
}

.offer-list {
  display: flex;
  flex-wrap: wrap;
}

.offer-list__item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .offer-list__item {
    width: 50%;
  }
}
@media (min-width: 48.0625em) {
  .offer-list__item {
    width: 33.3333333333%;
  }
}
.offer-list__item img {
  display: block;
  margin: 0 auto 1rem;
  max-height: 3.125rem;
}
.offer-list__item h3 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: #305078;
  text-align: center;
}
.offer-list__item ul {
  font-size: 0.875rem;
}

.perks-widget__box {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.15);
  flex: 1;
  padding: 1rem;
}
.perks-widget__box ul {
  padding-left: 1em;
}

.privacy-notice {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  background-color: #fff;
  border-radius: 8px;
  font-size: 0.875rem;
  left: 1rem;
  padding: 2rem 1rem 1rem;
  position: fixed;
  right: 1rem;
  top: 100%;
  transition: opacity 0.25s ease-in, transform 0.35s ease-in;
  z-index: 900;
}
@media (min-width: 30.0625em) {
  .privacy-notice {
    left: auto;
    padding: 2rem;
    right: 2rem;
    width: 27rem;
  }
}
.privacy-notice[aria-hidden=true] {
  opacity: 0;
  transform: translate3d(0, 0, 0);
}
.privacy-notice[aria-hidden=false] {
  opacity: 1;
  transform: translate3d(0, calc(-100% - 2rem), 0);
}

.privacy-notice__close {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #99a6b7;
  cursor: pointer;
  display: block;
  line-height: 1;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 901;
}
.privacy-notice__close:hover {
  color: #305078;
}
.privacy-notice__close svg {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.quote {
  align-items: center;
  background-color: #d6d7d9;
  border-radius: 6px;
  display: flex;
  padding: 1.5rem 2rem;
}
@media (max-width: 30em) {
  .quote {
    flex-direction: column;
    text-align: center;
  }
  .quote .quote__img {
    margin-bottom: 1rem;
    order: -1;
  }
  .quote .quote__caption {
    padding-left: 0;
  }
}

.quote__img {
  border: 8px solid #fff;
  height: auto;
  max-width: 7.5rem;
}

.quote__src {
  color: #38414c;
}
.quote__src p:first-child {
  font-size: 1.125rem;
}
.quote__src strong {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
}

.quote__caption {
  padding-left: 2rem;
}

.quote__text {
  color: #305078;
  font-size: 1.125rem;
  font-style: italic;
  margin-bottom: 0;
}
.quote__text::before {
  content: "“";
}
.quote__text::after {
  content: "”";
}

.quote-mark {
  margin-bottom: 7.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}
.quote-mark::before {
  background: no-repeat url("../images/quotation-marks-light.svg");
  content: "";
  height: 72px;
  left: -24px;
  position: absolute;
  top: -32px;
  width: 98px;
  z-index: 1;
}
@media (min-width: 48.0625em) {
  .quote-mark {
    padding-left: 9.25rem;
    padding-right: 3.5rem;
  }
  .quote-mark::before {
    background: no-repeat url("../images/quotation-marks.svg");
    left: 12px;
    top: 0;
  }
}

.quote-mark__text {
  border-bottom: 1px solid #aaa;
  color: #525252;
  font-size: 1.125rem;
  font-style: italic;
  padding-bottom: 1.5rem;
  position: relative;
  z-index: 5;
}

.quote-mark__text--bd {
  border-top: 1px solid #aaa;
  padding-top: 1.5rem;
}

.quote-large {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #305078;
  font-size: 1.875rem;
  line-height: 1.2;
}
@media (min-width: 48.0625em) {
  .quote-large {
    font-size: 2.5rem;
    text-align: center;
  }
}
.quote-large strong {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
}

.quote-large--light {
  color: #E0EEFF;
}

.quote-large__src {
  color: #fff;
  margin-bottom: 1rem;
}
@media (min-width: 48.0625em) {
  .quote-large__src {
    font-size: 1.125rem;
    margin-bottom: 4rem;
    text-align: center;
  }
}

.quote-box {
  margin: 0 auto;
  max-width: 90.5rem;
  width: 100%;
}
.quote-box .flickity-button {
  box-shadow: 0 0 0 2px #90a8c6;
}
.quote-box .flickity-prev-next-button.previous {
  left: 16px;
}
.quote-box .flickity-prev-next-button.next {
  right: 16px;
}
.quote-box .flickity-slider {
  align-items: center;
  display: flex;
}
.quote-box .flickity-prev-next-button {
  display: none;
}
@media (min-width: 48.0625em) {
  .quote-box .flickity-prev-next-button {
    display: block;
  }
}
@media (min-width: 100.0625em) {
  .quote-box .flickity-prev-next-button.previous {
    left: -64px;
  }
  .quote-box .flickity-prev-next-button.next {
    right: -64px;
  }
}

.quote-box__wrap {
  padding: 1.5rem;
  width: 100%;
}
@media (min-width: 60em) {
  .quote-box__wrap {
    width: 33.3333333333%;
  }
}

.g-recaptcha > div {
  margin: 0 auto;
}

.section-heading,
.section-subheading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #305078;
  line-height: 1.2;
}

.section-heading {
  font-size: 2.3125rem;
}
@media (min-width: 48.0625em) {
  .section-heading {
    font-size: 2.625rem;
    text-align: center;
  }
}
.section-heading .section-heading__sub {
  display: block;
}

.section-heading--resp-black {
  color: #272a2e;
}
@media (min-width: 48.0625em) {
  .section-heading--resp-black {
    color: #305078;
  }
}

.section-heading--large {
  font-size: 2.9375rem;
}
@media (min-width: 48.0625em) {
  .section-heading--large {
    font-size: 2.9375rem;
  }
}

.section-heading-v2 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 2.3125rem;
  line-height: 1;
}
@media (min-width: 48.0625em) {
  .section-heading-v2 {
    font-size: 3.25rem;
    line-height: 1.2;
    text-align: center;
  }
}

.section-heading-v3 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 2.3125rem;
  line-height: 1;
}
@media (min-width: 48.0625em) {
  .section-heading-v3 {
    font-size: 2.75rem;
    line-height: 1.2;
    text-align: center;
  }
}

.section-heading-v4 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #305078;
  font-size: 2.5rem;
  line-height: 1.2;
}

.section-heading__sub {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #305078;
  font-size: 1.625rem;
}

.section-heading--black {
  color: #272a2e;
}

.section-subheading {
  font-size: 1.75rem;
  text-align: center;
}

@media (max-width: 48em) {
  .section-subheading--align {
    text-align: left;
  }
}

.section-subheading-v2 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #272a2e;
  font-size: 1rem;
  letter-spacing: 0.125em;
  text-align: left;
  text-transform: uppercase;
}
@media (min-width: 48.0625em) {
  .section-subheading-v2 {
    color: #305078;
    text-align: center;
  }
}

.section-tagline {
  font-size: 1.5625rem;
}

.section-text {
  font-size: 1.125rem;
}

@media (min-width: 48.0625em) {
  .section-text--align {
    text-align: center;
  }
}

.section-text-v2 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
}
@media (min-width: 48.0625em) {
  .section-text-v2 {
    font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.375rem;
    text-align: center;
  }
}

.section-text-v3 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
}
@media (min-width: 48.0625em) {
  .section-text-v3 {
    font-size: 1.375rem;
    text-align: center;
  }
}

.section-tagline-list {
  color: #272a2e;
  font-size: 1.25rem;
  padding: 1rem 0 0;
}
@media (min-width: 680px) {
  .section-tagline-list {
    padding-top: 0;
    text-align: center;
  }
}
@media (min-width: 87.5em) {
  .section-tagline-list {
    font-size: 1.5rem;
  }
}
.section-tagline-list > li {
  list-style: none;
  margin-bottom: 20px;
  position: relative;
}
.section-tagline-list > li::after {
  background-color: #c9d2ea;
  bottom: -10px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 208px;
}
.section-tagline-list > li:last-child::after {
  display: none;
}
@media (min-width: 680px) {
  .section-tagline-list > li {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 2rem;
  }
  .section-tagline-list > li:first-child {
    margin-left: 0;
  }
  .section-tagline-list > li::after {
    height: 32px;
    left: auto;
    right: -18px;
    top: 0;
    width: 1px;
  }
  .section-tagline-list > li:last-child::after {
    display: none;
  }
}

.section {
  padding: 4rem 1rem;
  position: relative;
}

.section--pad-top {
  padding: 4rem 1rem 0;
}

.section--v2 {
  padding: 2rem 1rem;
}

.section--v3 {
  padding: 1.5rem;
}

.section--v4 {
  padding: 3rem 1rem;
}

.section--v5 {
  padding: 5rem 0;
}

.section--v6 {
  padding: 3rem 0;
}

.section--v7 {
  padding: 1.5rem 3rem;
}

.section--margin {
  margin-bottom: 8rem;
}

.section-content {
  position: relative;
  z-index: 100;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 87.5rem;
}

.container--v2 {
  max-width: 72.5rem;
}

.container--v3 {
  max-width: 59rem;
}

.container--v4 {
  max-width: 49rem;
}

.container--v5 {
  max-width: 61rem;
}

.container--spaced-m {
  padding-left: 1rem;
  padding-right: 1rem;
}

.container--spaced-xm {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.container--spaced-xxxl {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 48.0625em) {
  .container--spaced-xxxl {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.container--spaced-v--xl {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.dots {
  background-repeat: repeat;
  display: none;
  height: 28rem;
  position: absolute;
  width: 21rem;
  z-index: 1;
}
@media (min-width: 44em) {
  .dots {
    display: block;
  }
}

.dots--min-height {
  min-height: 30.25rem;
}

.dots--narrow {
  height: 26.25rem;
  width: 16rem;
}
.dots--narrow.dots--c-l, .dots--narrow.dots--c-r {
  margin-top: -13.125rem;
}

.dots--grey {
  background-image: url("../images/grey-dots.svg");
}

.dots--grey-light-med {
  background-image: url("../images/grey-light-med-dots.svg");
}

.dots--blue {
  background-image: url("../images/blue-dots.svg");
}

.dots--c-l {
  left: 0;
  margin-top: -14rem;
  top: 50%;
}

.dots--c-r {
  margin-top: -14rem;
  right: 0;
  top: 50%;
}

.dots--b-l {
  bottom: 0;
  left: 0;
}
.dots--b-l.dots--offset {
  bottom: 5rem;
}

.dots--b-r {
  bottom: 0;
  right: 0;
}
.dots--b-r.dots--offset {
  bottom: 5rem;
}

.dots--t-l {
  left: 0;
  top: 0;
}

.dots--t-r {
  right: 0;
  top: 0;
}

.offset-stripe {
  content: "";
  left: 0;
  position: absolute;
  height: 18rem;
  top: 0;
  width: 100%;
  z-index: 1;
}

.section-blue {
  background-color: #305078;
  color: #E0EEFF;
  padding-bottom: 14rem;
}
@media (max-width: 59.9375em) {
  .section-blue {
    padding-top: 2rem;
    padding-bottom: 11rem;
  }
}
.section-blue .app-icons__link {
  color: #fff;
}
.section-blue .app-icons__link svg {
  background-color: #557090;
  color: #fff;
}
.section-blue .heading-signup {
  align-items: center;
  display: flex;
}
.section-blue .heading-signup .heading-signup__heading {
  margin-bottom: 0;
}
.section-blue .heading-signup .btn {
  font-size: 1.125rem;
}
@media (max-width: 36.4375em) {
  .section-blue .heading-signup {
    flex-direction: column;
  }
  .section-blue .heading-signup .heading-signup__heading {
    margin-bottom: 1rem;
    text-align: center;
  }
  .section-blue .heading-signup .btn {
    width: 100%;
  }
}
@media (min-width: 60em) and (max-width: 78.0625em) {
  .section-blue .heading-signup {
    flex-direction: column;
  }
  .section-blue .heading-signup .heading-signup__heading {
    margin-bottom: 1rem;
    text-align: center;
  }
  .section-blue .heading-signup .btn {
    width: 100%;
  }
}
@media (max-width: 59.9375em) {
  .section-blue .heading-signup {
    margin-bottom: 2rem;
  }
}
.section-blue .heading-signup__heading {
  flex: 1 0 0%;
  width: 100%;
}
@media (min-width: 60em) {
  .section-blue .heading-signup__heading {
    font-size: 1.25rem;
  }
}
@media (min-width: 87.5em) {
  .section-blue .heading-signup__heading {
    font-size: 1.5rem;
  }
}
.section-blue .field.field-addon.has-focus {
  box-shadow: 0 0 0 2px #95a5b9;
}
@media (max-width: 33.5625em) {
  .section-blue .field.field-addon {
    background-color: transparent;
    flex-direction: column;
  }
  .section-blue .field.field-addon input.field-addon__input {
    margin-bottom: 1rem;
  }
  .section-blue .field.field-addon.has-focus {
    box-shadow: none;
  }
  .section-blue .field.field-addon.has-focus input.field-addon__input {
    box-shadow: 0 0 0 2px #95a5b9;
  }
}
@media (min-width: 60em) and (max-width: 71.8125em) {
  .section-blue .field.field-addon {
    background-color: transparent;
    flex-direction: column;
  }
  .section-blue .field.field-addon input.field-addon__input {
    margin-bottom: 1rem;
  }
  .section-blue .field.field-addon.has-focus {
    box-shadow: none;
  }
  .section-blue .field.field-addon.has-focus input.field-addon__input {
    box-shadow: 0 0 0 2px #95a5b9;
  }
}
.section-blue .grid {
  display: flex;
  flex-direction: column;
}
.section-blue .grid > div:first-child {
  order: 2;
  padding-bottom: 2rem;
}
.section-blue .grid > div:last-child {
  order: 1;
}
@media (min-width: 60em) {
  .section-blue .grid {
    flex-direction: row;
  }
  .section-blue .grid > div {
    width: 50%;
  }
  .section-blue .grid > div:first-child {
    order: 1;
    padding-bottom: 0;
  }
  .section-blue .grid > div:last-child {
    order: 2;
    padding-left: 4rem;
  }
}
@supports (display: grid) {
  .section-blue .grid {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (min-width: 60em) {
    .section-blue .grid {
      grid-template-columns: 1fr 1fr;
    }
    .section-blue .grid > div {
      width: auto;
    }
  }
}

.section-blue--no-padding {
  padding-bottom: 0;
}

.section-blue__heading {
  color: #fff;
  font-size: 1.5rem;
}

@media (max-width: 33.5625em) {
  .section-blue__heading--center {
    text-align: center;
  }
}
@media (min-width: 60em) and (max-width: 71.8125em) {
  .section-blue__heading--center {
    text-align: center;
  }
}

.svg-sprite {
  display: none;
}

body,
html {
  height: 100%;
}

body {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #305078;
  color: #272a2e;
  font-size: 100%;
  line-height: 1.5;
}

.site-wrapper {
  margin: 0 auto;
  max-width: 120rem;
  position: relative;
}

.site-main {
  background-color: #fff;
}
.site-main:after {
  clear: both;
  content: "";
  display: table;
}

.savings-calc {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  background-color: #fff;
  border-radius: 6px;
  clear: both;
  margin: 0 auto;
  max-width: 64rem;
}
.savings-calc fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.savings-calc__header {
  margin-bottom: 4rem;
}
@media (min-width: 48.0625em) {
  .savings-calc__header {
    display: flex;
    padding: 0 4rem;
  }
}

.savings-calc__content {
  flex: 1 0 0;
  padding: 2rem;
  width: 100%;
}
@media (min-width: 48.0625em) {
  .savings-calc__content {
    padding: 3rem 1.5rem 0 0;
  }
}

.savings-calc__total-savings {
  background-color: #305078;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-end;
}
@media (min-width: 48.0625em) {
  .savings-calc__total-savings {
    border-radius: 0 0 6px 6px;
  }
}

.savings-calc__total {
  color: #fff;
  line-height: 1;
  padding: 1.5rem 5rem;
  text-align: center;
}
.savings-calc__total h2 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #acb9c9;
  font-size: 1.25rem;
}
.savings-calc__total .dollar-sign,
.savings-calc__total .total-savings {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
}
.savings-calc__total .dollar-sign {
  font-size: 1.875rem;
  margin-right: 0.25rem;
  position: relative;
  top: -2px;
}
.savings-calc__total .total-savings {
  font-size: 3.125rem;
}

.savings-calc__body {
  padding-bottom: 4rem;
}

.input-table {
  display: table;
  width: 110px;
}

.input-table__cell {
  display: table-cell;
}
.input-table__cell:first-child, .input-table__cell:last-child {
  text-align: center;
}
.input-table__cell:first-child span {
  padding-right: 0.25rem;
}
.input-table__cell:last-child span {
  padding-left: 0.25rem;
}

.savings-calc__row {
  align-items: center;
  border-top: 1px dashed #d6d7d9;
  display: flex;
  margin: 0 1rem;
}
@media (min-width: 48.0625em) {
  .savings-calc__row {
    margin: 0 4rem;
  }
}

.savings-calc__row-wrap:first-child .savings-calc__row {
  border: 0;
}

.savings-calc__btn,
.savings-calc__header__total {
  font-size: 1.125rem;
}
@media (min-width: 48.0625em) {
  .savings-calc__btn,
  .savings-calc__header__total {
    font-size: 1.375rem;
  }
}

.savings-calc__btn {
  background-color: transparent;
  border: 0;
  display: flex;
  flex: 1 0 0;
  padding: 1rem 0.5rem 1rem 0;
  text-align: left;
  width: 100%;
}
.savings-calc__btn:hover {
  color: #418BDB;
}
.savings-calc__btn.is-visible .savings-calc__icon {
  transform: rotate(180deg);
}

.savings-calc__icon {
  flex-shrink: 0;
  height: 16px;
  margin: 0.25em 0.5rem 0 0.25em;
  margin-top: 0.5em;
  transform: rotate(0);
  transition: transform 0.15s ease-in;
  width: 16px;
}

.savings-calc__header.is-active img {
  transform: rotate(180deg);
}

.savings-calc__header__total {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  flex-shrink: 0;
  text-align: right;
}

.savings-calc__heading {
  flex: 1;
  font-size: inherit;
  margin-bottom: 0;
}

.savings-calc__row-body {
  background-color: rgb(255, 255, 255);
  border-left: 1px solid #f7f8fa;
  border-right: 1px solid #f7f8fa;
  position: relative;
  width: 100%;
  display: none;
}
.savings-calc__row-body.is-visible {
  display: block;
}

.savings-calc__cell-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1rem;
}
.savings-calc__cell-row:last-of-type {
  border-bottom: 0;
}
.savings-calc__cell-row:last-of-type .savings-calc__cell {
  border-bottom: 0;
}
.savings-calc__cell-row.odd {
  background-color: #f7f8fa;
}
@media (min-width: 48.0625em) {
  .savings-calc__cell-row {
    border: 0;
    flex-wrap: nowrap;
    padding: 0 4rem;
  }
}

.savings-calc__cell--label {
  flex: 1 1 auto;
}
.savings-calc__cell {
  padding: 0.5rem;
}
.savings-calc__cell input,
.savings-calc__cell select:not([multiple]) {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  outline: 0;
  padding: 0.25rem;
}
.savings-calc__cell input:focus,
.savings-calc__cell select:not([multiple]):focus {
  border-color: #aaa;
}
.savings-calc__cell select:not([multiple]) {
  background-position: right 8px top 50%;
}
.savings-calc__cell.total,
.savings-calc__cell label {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.savings-calc__cell label {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 0;
  position: relative;
}
.savings-calc__cell.total {
  text-align: right;
}
@media (min-width: 48.0625em) {
  .savings-calc__cell.total {
    min-width: 7rem;
  }
}
@media (min-width: 48.0625em) {
  .savings-calc__cell {
    border-bottom: 1px solid #f7f8fa;
    padding: 1rem 0.5rem;
    vertical-align: top;
  }
  .savings-calc__cell:first-child {
    padding-right: 1rem;
  }
  .savings-calc__cell:last-child {
    padding-left: 1rem;
  }
  .savings-calc__cell input {
    width: 100%;
  }
  .savings-calc__cell select {
    margin-left: 1.25rem;
    width: 130px;
  }
}

.savings-calc__header.first {
  border: 1px solid #d6d7d9;
  border-radius: 6px 6px 0 0;
}
.savings-calc__header.first .savings-calc__icon {
  border-radius: 6px 0 0 0;
  top: -1px;
}

.savings-calc__header.last {
  border-radius: 0 0 6px 6px;
}
.savings-calc__header.last .savings-calc__icon {
  border: none;
  border-radius: 0 0 0 6px;
}
.savings-calc__header.last.is-active {
  border-radius: 0;
}
.savings-calc__header.last.is-active .savings-calc__icon {
  border-radius: 0;
}

.vendor-info__icon {
  color: #aaa;
  display: inline-block;
  cursor: pointer;
  height: 16px;
  margin-left: 0.25rem;
  position: relative;
  top: -2px;
  width: 16px;
}
@media (min-width: 1025px) {
  .vendor-info__icon {
    left: -2rem;
    margin-left: 0;
    position: absolute;
  }
}
.vendor-info__icon svg {
  height: auto;
  width: 100%;
}
.vendor-info__icon:hover {
  color: #418BDB;
}

.vendor-info {
  display: none;
}

.savings-calc__vendor .vendor-info {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  display: none;
  margin-top: 0.5rem;
  padding: 1rem 1.5rem 0 1rem;
  position: absolute;
  width: 100%;
  z-index: 5;
}
@media (min-width: 481px) {
  .savings-calc__vendor .vendor-info {
    margin-top: 1rem;
    padding: 1rem 1rem 0;
    width: 440px;
  }
}
@media (min-width: 1025px) {
  .savings-calc__vendor .vendor-info {
    margin-left: -220px;
  }
}
@media (max-width: 480px) {
  .savings-calc__vendor .vendor-info {
    left: 0 !important;
    right: auto !important;
  }
}
.savings-calc__vendor .vendor-info p {
  font-size: 0.875rem;
}

.vendor-info__logo {
  display: block;
  height: auto;
  margin: 0 auto 1.5rem;
  max-height: 2rem;
  max-width: 100%;
  width: auto;
}

.us-map {
  background: #305078 url("../images/us-map-full.svg") no-repeat 50% 50%;
  background-size: auto 592px;
}

.us-map--white {
  background-color: transparent;
  background-image: url("../images/us-map-white.svg");
}
.us-map--white .savings-network__cell .stat__number {
  color: #272a2e;
}
.us-map--white .show-more-btn:hover {
  color: #305078;
}

.us-map--auto {
  background-size: 100% auto;
}

.savings-network {
  margin: 0 auto;
  max-width: 79.5rem;
  padding-bottom: 2rem;
}
.savings-network .show-more-btn {
  background: transparent;
  border: 0;
  color: #95a5b9;
  margin: 0 auto;
  text-align: center;
  width: auto;
}
.savings-network .show-more-btn:hover {
  color: #fff;
}

.savings-network__body {
  display: flex;
  flex-flow: row wrap;
}

.savings-network__cell {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .savings-network__cell {
    padding-right: 1rem;
    width: 50%;
  }
}
@media (min-width: 48.0625em) {
  .savings-network__cell {
    padding-right: 2rem;
    width: 33.3333333333%;
  }
}
@media (max-width: 30em) {
  .savings-network__cell.mobile-hide {
    display: none;
  }
  .savings-network__cell.mobile-hide.toggle {
    display: flex;
  }
}
.savings-network__cell .stat__number {
  color: #fff;
  display: block;
}
.savings-network__cell .stat__text {
  color: #eaeff0;
  margin-bottom: 0;
}
.savings-network__cell img {
  flex-shrink: 0;
  height: 2.5rem;
  margin-right: 1rem;
  width: auto;
}

@media (min-width: 48.0625em) {
  .savings-network--two-col .savings-network__cell {
    padding-right: 2rem;
    width: 50%;
  }
}

.savings-network .show-more-btn {
  display: none;
}
@media (max-width: 30em) {
  .savings-network .show-more-btn {
    display: block;
  }
}

.skip-to-content {
  font-size: 0.875rem;
  height: 0;
  left: -9999%;
  margin: 0;
  opacity: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 9.25rem;
}
.skip-to-content:focus {
  height: auto;
  left: 0;
  opacity: 1;
  width: 100%;
}
@media (min-width: 48.0625em) {
  .skip-to-content:focus {
    top: 6.125rem;
  }
}
.skip-to-content:focus span {
  background: #305078;
  color: #fff;
  padding: 0.25rem 0.5rem;
}

.social-network-list {
  align-items: center;
  display: flex;
  justify-content: center;
}

.social-network__link {
  color: #305078;
  margin: 0 0.5rem;
}
.social-network__link:hover {
  color: #418BDB;
}
.social-network__link svg {
  height: 3rem;
  width: auto;
}

.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
}
.text h2 {
  font-size: 2.25rem;
}
.text h3 {
  font-size: 1.875rem;
}
.text h4 {
  font-size: 1.5625rem;
}
.text h5 {
  font-size: 1.375rem;
}
.text h6 {
  font-size: 1.125rem;
}
.text ul,
.text ol {
  padding-left: 1em;
}
.text ul > li,
.text ol > li {
  margin-bottom: 0.5rem;
}
.text ul ul,
.text ul ol,
.text ol ul,
.text ol ol {
  margin-top: 0.5rem;
}

.text--dark a:not([class]),
.text--dark a[class|=js] {
  color: #272a2e;
}
.text--dark a:not([class]):visited,
.text--dark a[class|=js]:visited {
  color: #272a2e;
}
.text--dark a:not([class]):hover, .text--dark a:not([class]):focus,
.text--dark a[class|=js]:hover,
.text--dark a[class|=js]:focus {
  color: #418BDB;
}

.text--large h3 {
  font-size: 1.375rem;
}
.text--large p {
  font-size: 1.125rem;
  margin-bottom: 2.5rem;
}
.text--large p + h3 {
  margin-top: 5rem;
}

.top-brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 87.5rem;
}
@media (min-width: 48.0625em) {
  .top-brands {
    padding: 0 1rem;
  }
}

.top-brands-heading {
  margin-bottom: 1rem;
}
@media (min-width: 48.0625em) {
  .top-brands-heading {
    margin-bottom: 2rem;
  }
}

.top-brands__item {
  margin: 0;
  padding: 0 0.5rem;
  width: 50%;
}
@media (min-width: 30.0625em) {
  .top-brands__item {
    width: 25%;
  }
}
@media (min-width: 48.0625em) {
  .top-brands__item {
    width: auto;
  }
}

.top-brands__img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-height: 6rem;
  max-width: 100%;
  width: auto;
}

@media (max-width: 48em) {
  .top-brands--responsive .top-brands__item {
    width: 100%;
  }
}
.top-brands--responsive::after {
  content: "flickity";
  display: none;
}
@media (max-width: 48em) {
  .top-brands--responsive .top-brands__img {
    height: auto;
    max-height: 8rem;
    max-width: 100%;
    width: auto;
  }
}
@media (min-width: 48.0625em) {
  .top-brands--responsive::after {
    content: "";
  }
}

@media (min-width: 60em) {
  .top-brands--8 .top-brands__item {
    width: 12.5%;
  }
}

.top-brands--slider .top-brands__item {
  width: 100%;
}
@media (min-width: 48.0625em) {
  .top-brands--slider .top-brands__item {
    width: 20%;
  }
}

.top-brands-section {
  background: #305078 url("../images/top-brands/bg/top-brands-section-bg.jpg") no-repeat 50% 50%;
  background-size: cover;
  padding: 5rem 0;
}
@media (max-width: 30em) {
  .top-brands-section {
    background-image: url("../images/top-brands/bg/top-brands-section-bg-mobile.jpg");
  }
}
@media (min-width: 80em) {
  .top-brands-section .top-brands--responsive {
    justify-content: space-between;
  }
}
.top-brands-section .flickity-button {
  color: #e9e9e9;
}
.top-brands-section .flickity-slider {
  align-items: center;
  display: flex;
}

.top-brands-section--universal {
  background-image: url("../images/top-brands/bg/top-brands-section-bg-universal.jpg");
  background-position: center bottom;
}

.top-brands-section--quote {
  padding: 3rem 0;
}
.top-brands-section--quote .section-heading,
.top-brands-section--quote .section-tagline {
  text-align: center;
}
@media (min-width: 60em) {
  .top-brands-section--quote .section-heading,
  .top-brands-section--quote .section-tagline {
    text-align: left;
  }
}
.top-brands-section--quote .section-heading {
  font-size: 1.625rem;
}
@media (min-width: 48.0625em) {
  .top-brands-section--quote .section-heading {
    font-size: 3.125rem;
  }
}
.top-brands-section--quote .section-tagline {
  font-size: 1.125rem;
}
@media (min-width: 48.0625em) {
  .top-brands-section--quote .section-tagline {
    font-size: 2.125rem;
  }
}
@media (max-width: 59.9375em) {
  .top-brands-section--quote .l--max--order-first {
    margin-bottom: 2rem;
  }
}

.vendor-logo-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.vendor-logo__item {
  background-color: #fff;
  margin: 0 0.5rem 1.5rem;
  padding: 0 0.5rem;
  width: 8rem;
}
.vendor-logo__item img {
  display: block;
  margin: 0 auto;
  height: auto;
  max-height: 3.375rem;
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}

.vendor-logo-grid-v2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}
@media (min-width: 48.0625em) {
  .vendor-logo-grid-v2 {
    justify-content: space-between;
    width: 75%;
  }
}
.vendor-logo-grid-v2 img {
  display: block;
  margin: 0 auto;
  height: auto;
  max-width: 100%;
  width: auto;
}
@media (min-width: 48.0625em) {
  .vendor-logo-grid-v2 img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.vendor-logo-grid-v2 .third,
.vendor-logo-grid-v2 .fourth,
.vendor-logo-grid-v2 .fifth,
.vendor-logo-grid-v2 .sixth {
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.vendor-logo-grid-v2 .third {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.vendor-logo-grid-v2 .fourth {
  flex: 1 0 25%;
  max-width: 25%;
}
.vendor-logo-grid-v2 .fifth {
  flex: 1 0 20%;
  max-width: 20%;
}
.vendor-logo-grid-v2 .sixth {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.vendor-logo-grid--responsive::after {
  content: "flickity";
  display: none;
}
@media (min-width: 48.0625em) {
  .vendor-logo-grid--responsive::after {
    content: "";
  }
}
@media (max-width: 48em) {
  .vendor-logo-grid--responsive {
    display: block;
    width: 100%;
  }
  .vendor-logo-grid--responsive .third,
  .vendor-logo-grid--responsive .fourth,
  .vendor-logo-grid--responsive .fifth,
  .vendor-logo-grid--responsive .sixth {
    display: inline-block;
    margin-bottom: 0;
    max-width: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    vertical-align: middle;
  }
  .vendor-logo-grid--responsive .third img,
  .vendor-logo-grid--responsive .fourth img,
  .vendor-logo-grid--responsive .fifth img,
  .vendor-logo-grid--responsive .sixth img {
    max-height: 70px;
  }
}

.vendor-logo-grid-v3 {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 48em) {
  .vendor-logo-grid-v3 {
    flex-wrap: wrap;
  }
}
.vendor-logo-grid-v3 > div {
  max-width: 5rem;
  padding: 0 0.5rem;
}
@media (max-width: 48em) {
  .vendor-logo-grid-v3 > div {
    padding-bottom: 0.5rem;
  }
}
.vendor-logo-grid-v3 img {
  display: block;
  height: auto;
  max-height: 3rem;
  max-width: 100%;
  width: auto;
}

.whats-next {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 2rem;
  max-width: 59rem;
}
@media (min-width: 48.0625em) {
  .whats-next {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 60em) {
  .whats-next {
    flex-wrap: nowrap;
    margin-bottom: 6rem;
  }
}

.whats-next__link {
  background-color: #f7f8fa;
  border-radius: 24px;
  color: #525252;
  font-size: 1rem;
  margin: 0 0.75rem 1.5rem;
  padding: 3rem 1rem;
  text-align: center;
  text-decoration: none;
  width: calc(100% - 2rem);
}
.whats-next__link:hover p, .whats-next__link:hover strong {
  color: #418BDB;
}
@media (min-width: 30.0625em) {
  .whats-next__link {
    width: calc(50% - 0.75rem);
  }
}
@media (min-width: 48.0625em) {
  .whats-next__link {
    width: calc(33.3333333333% - 0.75rem);
  }
}

.whats-next__img {
  display: block;
  margin: 0 auto 1rem;
  width: 5rem;
}
.whats-next__img img {
  display: inline-block;
  height: 4rem;
  margin: 0 auto;
  width: auto;
}

.whats-next__text {
  margin-bottom: 0;
}
.whats-next__text strong {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #305078;
  display: block;
  font-size: 1.125rem;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

@media (max-width: 30em) {
  .whats-next__link {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    text-align: left;
  }
  .whats-next__img {
    margin: 0;
  }
  .whats-next__text {
    padding-left: 1.5rem;
    max-width: 9rem;
    width: 100%;
  }
}
.yes-no-section {
  margin: 0 auto 4rem;
  max-width: 88.5rem;
  padding: 1rem;
}
@media (max-width: 48em) {
  .yes-no-section {
    background-color: #305078;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.yes-no {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
@media (min-width: 48.0625em) {
  .yes-no {
    flex-direction: row;
  }
  .yes-no .yes-no__yes,
  .yes-no .yes-no__no {
    width: 50%;
  }
}

@supports (display: grid) {
  .yes-no {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (min-width: 48.0625em) {
    .yes-no {
      grid-template-columns: 1fr 1fr;
    }
    .yes-no .yes-no__yes,
    .yes-no .yes-no__no {
      width: auto;
    }
  }
}
@media (max-width: 48em) {
  .yes-no--padded-s {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
}

.yes-no--v2.box {
  margin-top: -12rem;
  position: relative;
  z-index: 5;
}

.yes-no__yes,
.yes-no__no {
  padding: 0 1.5rem;
}
.yes-no__yes li,
.yes-no__no li {
  margin-bottom: 1.5rem;
}

@media (min-width: 48.0625em) {
  .yes-no__yes {
    border-right: 1px dashed #d6d7d9;
  }
}
.yes-no__yes li::marker {
  color: #3C9D36;
}

.yes-no__no li::marker {
  color: #C13D3D;
}

@media (min-width: 87.5em) {
  .zip-code-form {
    display: flex;
  }
  .zip-code-form .zip-code-form__item {
    margin-bottom: 0;
    padding-right: 1rem;
  }
}
.zip-code-form label {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #4d5055;
  font-size: 1rem;
  white-space: nowrap;
}
.zip-code-form input[type=text],
.zip-code-form input[type=number],
.zip-code-form select:not([multiple]) {
  background-color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 0.5rem;
}
.zip-code-form input::-webkit-outer-spin-button,
.zip-code-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.zip-code-form select:not([multiple]) {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23272a2e' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
  background-position: right 16px top 50%;
  background-repeat: no-repeat;
  background-size: 12px auto;
  line-height: inherit;
  padding-right: 32px;
}
.zip-code-form select:not([multiple]):-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.zip-code-form select:not([multiple])::-ms-expand {
  display: none;
}
.zip-code-form .btn {
  background-color: #4472AB;
  border-color: #4472AB;
  text-align: center;
}
@media (max-width: 30em) {
  .zip-code-form .btn {
    width: 100%;
  }
}

.zip-code-form__inputs {
  display: flex;
}

.zip-code-form__item {
  align-items: center;
  display: flex;
}
@media (min-width: 75em) {
  .zip-code-form__item:first-child {
    flex-shrink: 0;
  }
  .zip-code-form__item label {
    padding-right: 1rem;
  }
}
@media (max-width: 74.9375em) {
  .zip-code-form__item {
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
  }
}
.zip-code-form__item:first-child {
  padding-right: 0.5rem;
}
.zip-code-form__item:last-child {
  padding-left: 0.5rem;
}
.zip-code-form__item input[type=text],
.zip-code-form__item input[type=number],
.zip-code-form__item select:not([multiple]) {
  width: 100%;
}

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .logo a::after,
  .copyright a::after {
    content: "";
  }
  .section-blue {
    color: #000 !important;
  }
  .accordion-section {
    display: block;
  }
  .site-header__nav,
  .mobile-menu-btn,
  .site-footer,
  .copyright__item--social {
    display: none;
  }
  .btn {
    page-break-inside: avoid;
  }
  .flickity-prev-next-button {
    display: none;
  }
  .flickity-viewport {
    height: auto !important;
    overflow: auto !important;
    max-width: 100%;
  }
  .flickity-slider {
    transform: none !important;
    width: 100%;
  }
  .top-brands {
    display: block;
  }
  .top-brands .top-brands__item {
    display: inline-block;
    position: static;
    width: 25% !important;
  }
  .top-brands .top-brands__item img {
    height: auto;
    max-width: 100%;
  }
  .top-brands-section {
    display: none;
  }
  .field--floating label {
    visibility: hidden;
  }
  .card-wrap {
    display: block;
  }
}
.about-masthead {
  background-image: url("../images/masthead/about-masthead.jpg");
  background-position: 50% 0;
  display: block;
  padding: 6.25rem 0 0;
}
.about-masthead:after {
  clear: both;
  content: "";
  display: table;
}
@media (max-width: 56.4375em) {
  .about-masthead {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media (min-width: 56.5em) {
  .about-masthead img {
    float: right;
  }
}
.about-masthead .masthead-content-center {
  margin: 0 auto;
  max-width: 87.5rem;
}
.about-masthead .masthead-content-wrap {
  max-width: 65rem;
}
.about-masthead .masthead-content {
  padding-left: 1rem;
  padding-top: 8rem;
}
.about-masthead .masthead-text {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: none;
}
@media (min-width: 56.5em) {
  .about-masthead .masthead-text {
    display: block;
    font-size: 1.125rem;
  }
}
@media (min-width: 60em) {
  .about-masthead .masthead-text {
    font-size: 1.5rem;
  }
}
@media (max-width: 56.4375em) {
  .about-masthead .masthead-heading {
    margin: auto;
    text-align: center;
  }
  .about-masthead .masthead-content-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .about-masthead .masthead-content {
    order: -1;
    padding-left: 0;
    padding-top: 5rem;
    text-align: center;
  }
}
@media (max-width: 32.9375em) {
  .about-masthead {
    background-image: url("../images/masthead/about-masthead-small.jpg");
    overflow: hidden;
  }
  .about-masthead img {
    margin-left: -0.5rem;
    max-width: 118%;
  }
}

.section--about-spacing {
  padding: 2rem 0;
}
@media (min-width: 60em) {
  .section--about-spacing {
    padding: 4.5rem 0 7.5rem;
  }
}

.section--about-spacing--v2 {
  padding: 2rem;
}
@media (min-width: 60em) {
  .section--about-spacing--v2 {
    padding: 4.5rem 2rem;
  }
}

@media (min-width: 60em) {
  .section--about .grid-flex {
    margin-left: -3.5rem;
  }
}
@media (min-width: 87.5em) {
  .section--about .grid-flex {
    margin-left: -7rem;
  }
}
@media (min-width: 60em) {
  .section--about .grid-flex > .grid__item {
    padding-left: 3.5rem;
  }
}
@media (min-width: 87.5em) {
  .section--about .grid-flex > .grid__item {
    padding-left: 112px;
  }
}
@media (max-width: 59.9375em) {
  .section--about .grid-flex .section--about-video {
    margin-bottom: 2rem;
  }
}
@media (max-width: 87.4375em) {
  .section--about .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.rgist {
  position: relative;
}
@media (min-width: 60em) {
  .rgist {
    display: flex;
  }
}

.rgist__vertical-img {
  max-width: 96px;
  position: absolute;
}
@media (min-width: 60em) {
  .rgist__vertical-img {
    display: none;
  }
}

.rgist__horizontal-img {
  display: none;
  padding: 0 70px;
}
@media (min-width: 60em) {
  .rgist__horizontal-img {
    display: block;
  }
}

.rgist__item {
  margin: 0 auto 3rem;
  text-align: left;
}
@media (max-width: 59.9375em) {
  .rgist__item {
    display: flex;
    height: 169px;
    margin-bottom: 0;
    padding-left: 112px;
  }
  .rgist__item:last-child {
    height: 120px;
  }
}
@media (min-width: 60em) {
  .rgist__item {
    margin-bottom: 0;
    padding: 0 1rem 2rem;
    text-align: center;
    width: 50%;
  }
  .rgist__item:nth-child(odd) {
    position: relative;
    top: 48px;
  }
}

.rgist__letter,
.rgist__content {
  position: relative;
  z-index: 5;
}

.rgist__letter {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  background-color: #e5e3e2;
  border-radius: 50%;
  color: #3d4a57;
  flex-shrink: 0;
  font-size: 4rem;
  height: 6rem;
  line-height: 1.2;
  margin: 0 auto 1.5rem;
  padding: 0.625rem 0;
  text-align: center;
  width: 6rem;
}
@media (min-width: 60em) {
  .rgist__letter {
    display: none;
  }
}

.rgist__content {
  flex: 1;
  padding-left: 1rem;
}
@media (min-width: 48.0625em) {
  .rgist__content {
    padding: 0;
  }
}

.rgist__heading {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}
@media (min-width: 60em) {
  .rgist__heading {
    border-bottom: 1px solid #aaa;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 80em) {
  .rgist__heading {
    font-size: 1.5rem;
  }
}
@media (max-width: 30em) {
  .rgist__heading {
    font-size: 1.125rem;
  }
}

.rgist__text {
  margin-bottom: 0;
}
@media (min-width: 80em) {
  .rgist__text {
    font-size: 1.125rem;
  }
}
@media (max-width: 30em) {
  .rgist__text {
    font-size: 0.875rem;
  }
}

.rgist__svg {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.line {
  stroke-width: 2px;
  stroke: #e0d6cf;
}

.site-header-agency {
  background-color: transparent !important;
}
.site-header-agency .site-header__inner {
  align-items: center;
}
.site-header-agency .logo a {
  color: #fff;
}

.agency-logo {
  max-width: 146px;
  text-align: right;
  width: 100%;
}
.agency-logo.agency-logo--text {
  max-width: 100%;
  width: auto;
}
.agency-logo h1 {
  color: #fff;
  font-size: 2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.agency-masthead.home-masthead {
  background: no-repeat url("../images/masthead/agency-masthead.jpg") center center/cover;
}
.agency-masthead.home-masthead .masthead-heading,
.agency-masthead.home-masthead .masthead-tagline,
.agency-masthead.home-masthead .masthead-form-label {
  color: #fff !important;
}

.agency-coupon {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 48.0625em) {
  .agency-coupon {
    padding-bottom: 6rem;
  }
}
.agency-coupon .agency-coupon__details {
  background-image: url("../images/coupon@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: #305078;
  display: flex;
  flex-direction: column;
  height: 119px;
  justify-content: center;
  line-height: 2.5rem;
  width: 509px;
}
@media (max-width: 48em) {
  .agency-coupon .agency-coupon__details {
    height: 23.3791748527vw;
    max-width: 509px;
    width: 100%;
  }
}
@media (max-width: 30em) {
  .agency-coupon .agency-coupon__details {
    line-height: 7vw;
  }
}
.agency-coupon .agency-coupon__value {
  font-size: 2.26rem;
  font-weight: 700;
}
@media (max-width: 30em) {
  .agency-coupon .agency-coupon__value {
    font-size: 7vw;
  }
}
.agency-coupon .agency-coupon__code {
  font-size: 1.62rem;
  font-weight: 500;
}
@media (max-width: 30em) {
  .agency-coupon .agency-coupon__code {
    font-size: 6vw;
  }
}

.agency-exclusive-section.small-business-masthead {
  padding-top: 2rem;
}
.agency-exclusive-section.small-business-masthead .mobile-icons {
  text-align: center;
}
@media (min-width: 79.6875em) {
  .agency-exclusive-section.small-business-masthead .mobile-icons {
    padding-left: 2em;
    text-align: left;
  }
}
.agency-exclusive-section.small-business-masthead .perks-for-masthead-list {
  margin-bottom: 2rem;
}
@media (min-width: 79.6875em) {
  .agency-exclusive-section.small-business-masthead .perks-for-masthead-list {
    margin-bottom: 1rem;
  }
}

.laptop-masthead {
  background-image: url(../images/masthead/laptop-masthead-small.jpg);
}
@media (min-width: 48.0625em) {
  .laptop-masthead {
    background-image: url(../images/masthead/laptop-masthead.jpg);
  }
}

.parameters_table,
.tag_definition {
  border: 1px solid #d6d7d9;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  width: 100%;
}
.parameters_table thead td,
.tag_definition thead td {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #4472AB;
  color: rgb(255, 255, 255);
}
.parameters_table td,
.tag_definition td {
  border-bottom: 1px solid #d6d7d9;
  padding: 0.5rem;
  vertical-align: top;
}
.parameters_table tr:last-child td,
.tag_definition tr:last-child td {
  border-bottom: 0;
}

table.tag_definition tr td:nth-child(1) {
  width: 200px;
}

span.code {
  background-color: #f2f2f2;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 0.25em;
  font-family: Monaco, Consolas, "Lucida Console", monospace;
  padding: 0 0.25em;
}
span.code.url {
  word-wrap: break-word;
}

.code.code-parameter {
  color: rgb(207, 49, 96);
}

.code-parameter-list {
  margin: 10px 0 10px 15px;
}

.code-parameter-list li {
  margin-bottom: 0;
}

.code.http-verb {
  color: rgb(49, 207, 96);
}

.code.url {
  color: #254ca5;
}

span.long_string {
  display: inline-block;
  width: 200px;
  word-wrap: break-word;
}

table pre > code {
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}

.features-masthead {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 auto;
  max-width: 87.5rem;
  padding-bottom: 4.5rem;
  padding-top: 10rem;
}
@media (max-width: 87.4375em) {
  .features-masthead {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 60em) {
  .features-masthead {
    flex-direction: row;
    padding-bottom: 6rem;
    padding-top: 11rem;
  }
}
@media (max-width: 59.9375em) {
  .features-masthead .masthead-heading {
    width: auto;
  }
  .features-masthead .masthead-heading mark.highlight--v3 {
    display: inline-block;
  }
}

@supports (display: grid) {
  @media (min-width: 60em) {
    .features-masthead {
      display: grid;
      grid-template-columns: 1fr minmax(520px, 778px);
    }
  }
}
.features-masthead__content {
  padding-right: 2rem;
}
.features-masthead__content p {
  max-width: 30rem;
}

.features-masthead__img {
  max-width: 778px;
  padding-right: 1.5rem;
}
@media (max-width: 59.9375em) {
  .features-masthead__img {
    margin-bottom: 2rem;
    padding-right: 0;
  }
}

.features-masthead-img-wrap {
  display: block;
  position: relative;
}
.features-masthead-img-wrap .poster-image {
  position: relative;
  z-index: 5;
}
.features-masthead-img-wrap .play-icon {
  height: 64px;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  position: absolute;
  opacity: 0.6;
  top: 50%;
  transition: opacity 0.3s ease-in;
  width: 64px;
  z-index: 10;
}
.features-masthead-img-wrap:hover .play-icon {
  opacity: 1;
}
@media (min-width: 60em) {
  .features-masthead-img-wrap::before {
    background-color: #305078;
    content: "";
    height: 100%;
    position: absolute;
    right: -1.5rem;
    top: -1.5rem;
    transition: background-color 0.3s ease-in;
    width: 100%;
    z-index: 1;
  }
  .features-masthead-img-wrap:hover::before {
    background-color: #507fba;
  }
}

.sub-nav-features {
  position: sticky;
  top: -1px;
  z-index: 800;
}
.sub-nav-features.is-stuck {
  border-bottom: 4px solid #fff;
}
@media (max-width: 54.9375em) {
  .sub-nav-features {
    display: none;
  }
}

.sub-nav {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 87.5rem;
}

.sub-nav__link {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.sub-nav__link:link, .sub-nav__link:visited {
  color: #E0EEFF;
}
.sub-nav__link:hover {
  color: #fff;
}

.feature-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  margin-left: -1rem;
  margin-top: 0;
  padding: 0;
}
.feature-cards > .card-wrap {
  margin-bottom: 2rem;
  margin-top: 0;
  max-width: 28.75rem;
  padding-left: 1rem;
}
@media (min-width: 80em) {
  .feature-cards > .card-wrap {
    margin-bottom: 0;
    width: 33.3333333333%;
  }
}

@media (min-width: 80em) {
  .feature-cards--spaced > .card-wrap {
    margin-bottom: 2rem;
  }
}
.feature-cards--spaced .card__heading {
  color: #305078;
  font-size: 1.375rem;
  text-align: center;
}

.feature-cards--responsive::after {
  content: "flickity";
  display: none;
}
@media (min-width: 60em) {
  .feature-cards--responsive {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
  }
  .feature-cards--responsive::after {
    content: "";
  }
}
@media (max-width: 59.9375em) {
  .feature-cards--responsive {
    margin-left: 0;
  }
  .feature-cards--responsive .card-wrap {
    padding: 2rem;
    width: 100%;
  }
}
.feature-cards--responsive .flickity-prev-next-button.previous {
  left: -16px;
}
.feature-cards--responsive .flickity-prev-next-button.next {
  right: -16px;
}

.features-our-perks {
  background: no-repeat url("../images/features/our-perks-bg.jpg") center bottom;
  display: flex;
  min-height: 630px;
}
.features-our-perks .feature-img {
  width: 60.9375%;
}
.features-our-perks .feature-img img {
  display: none;
}
.features-our-perks .feature-text {
  max-width: 27.25rem;
  padding-right: 1rem;
}
@media (min-width: 45.3125em) {
  .features-our-perks .feature-text {
    margin-top: 4.5rem;
  }
}
@media (min-width: 48.75em) {
  .features-our-perks .feature-text {
    margin-top: 5.5rem;
  }
}
.features-our-perks .hyphen-list {
  padding-left: 0.5em;
}
@media (max-width: 45.1875em) {
  .features-our-perks .hyphen-list {
    padding-left: 1.125em;
  }
}
@media (min-width: 75em) {
  .features-our-perks {
    background-position: -200px bottom;
  }
}
@media (min-width: 87.5em) {
  .features-our-perks {
    background-position: center bottom;
  }
}
@media (max-width: 45.1875em) {
  .features-our-perks {
    align-items: center;
    background: none;
    flex-direction: column;
    min-height: 0;
  }
  .features-our-perks .feature-text {
    padding: 1rem;
    order: -1;
    width: 100%;
  }
  .features-our-perks .feature-img {
    width: 100%;
  }
  .features-our-perks .feature-img img {
    display: block;
  }
}

.features-discounts-wrap {
  background: #305078 url(../images/features/us-map-large.png) no-repeat right center;
  padding-bottom: 10rem;
  padding-top: 3rem;
}
@media (min-width: 48.0625em) {
  .features-discounts-wrap {
    padding-bottom: 13.125rem;
  }
}
@media (max-width: 36.1875em) {
  .features-discounts-wrap {
    background-position: center 320px;
    background-size: 600px auto;
  }
}
@media (max-width: 87.4375em) {
  .features-discounts-wrap {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.features-discounts {
  margin: 0 auto;
  max-width: 87.5rem;
}
@media (min-width: 62.5em) {
  .features-discounts {
    display: flex;
  }
  .features-discounts .features-discounts__text {
    padding-right: 2rem;
    width: 30%;
  }
  .features-discounts .features-discounts__savings-network {
    width: 70%;
  }
}

@supports (display: grid) {
  @media (min-width: 62.5em) {
    .features-discounts {
      display: grid;
      grid-gap: 6rem;
      grid-template-columns: 25rem 1fr;
    }
    .features-discounts .features-discounts__text {
      padding-right: 0;
    }
    .features-discounts .features-discounts__text,
    .features-discounts .features-discounts__savings-network {
      width: auto;
    }
  }
}
.features-discounts-heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #fff;
  font-size: 2.9375rem;
  line-height: 1;
  margin-bottom: 1.5rem;
}

.features-discounts__text {
  align-self: center;
  margin-bottom: 4rem;
}

.features-discounts-p {
  color: rgba(255, 255, 255, 0.6);
}

.stats {
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  color: #fff;
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 2rem;
  max-width: 25rem;
  width: 100%;
}

.stats__label {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
}

.stats__value {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 59px;
}

.offers-city-heading {
  color: #fff;
  font-size: 1.125rem;
}

.city-table-wrap {
  display: grid;
}
@media (min-width: 30.0625em) {
  .city-table-wrap {
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 60em) {
  .city-table-wrap {
    grid-gap: 2rem;
  }
}

.city-table {
  width: 100%;
}

.city-table__city {
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
}

.city-table__count {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #fff;
  text-align: right;
  width: 50px;
}

@media (max-width: 87.4375em) {
  .features-perks-wrap {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 48em) {
  .features-perks-wrap {
    padding: 0;
  }
}

.features-perks {
  margin-top: -8.25rem;
}
@media (min-width: 48.0625em) {
  .features-perks {
    box-shadow: 0px 0px 58.5px 6.5px rgba(0, 0, 0, 0.09);
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    margin: -7.125rem auto 5rem;
    max-width: 87.5rem;
    padding: 0.5rem;
  }
}
.features-perks .flickity-slider {
  padding: 0 1.5rem;
}
.features-perks .features-perks__images {
  max-width: 915px;
  width: 100%;
}

@supports (display: grid) {
  .features-perks {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: minmax(0, 915px) 1fr;
  }
}
.features-perks__images.flickity-enabled {
  margin-bottom: 4rem;
}
.features-perks__images::after {
  content: "flickity";
  display: none;
}
@media (min-width: 48.0625em) {
  .features-perks__images::after {
    content: "";
  }
}

@media (min-width: 48.0625em) {
  .features-perks__card-wrap {
    display: none;
  }
  .features-perks__card-wrap.is-visible {
    display: block;
  }
}

.features-perks__card__info {
  display: none;
}

@media (max-width: 48em) {
  .features-perks__card-wrap {
    padding: 1.5rem;
    width: 100%;
  }
  .features-perks__card {
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
    font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    background-color: #fff;
    border-radius: 6px;
    display: block;
    padding: 0.5rem;
    width: 100%;
  }
  .features-perks__card__info {
    align-items: center;
    display: flex;
    font-size: 1.125rem;
    justify-content: center;
    letter-spacing: 0.025em;
    padding: 1rem;
    text-transform: uppercase;
  }
}
.features-perks__img {
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 6px;
  height: 310px;
  max-width: 916px;
  width: 100%;
}
@media (max-width: 48em) {
  .features-perks__img {
    background-size: contain;
    height: 0;
    padding-bottom: 33.8427947598%;
  }
}

.features-perks__nav {
  display: none;
}
@media (min-width: 48.0625em) {
  .features-perks__nav {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 100%;
  }
}

@supports (display: grid) {
  @media (min-width: 48.0625em) {
    .features-perks__nav {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      max-width: none;
      width: auto;
    }
  }
}
.features-perks__nav__btn {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  align-items: center;
  background: none;
  border: 0;
  border-top: 1px dashed #d6d7d9;
  display: flex;
  font-size: 1.125rem;
  letter-spacing: 0.025em;
  padding: 1rem;
  text-align: left;
  text-transform: uppercase;
}
.features-perks__nav__btn:first-child {
  border: 0;
}
.features-perks__nav__btn:hover {
  color: #418BDB;
}
.features-perks__nav__btn.is-active, .features-perks__nav__btn.is-active:hover {
  background-color: #4b5971;
  border-color: transparent;
  border-radius: 8px;
  color: #fff;
}
.features-perks__nav__btn.is-active + .features-perks__nav__btn, .features-perks__nav__btn.is-active:hover + .features-perks__nav__btn {
  border-color: transparent;
}

.features-perks__icon {
  height: 1.5rem;
  margin-right: 1.5rem;
  white-space: nowrap;
  width: 1.5rem;
}

.features-back-office {
  background-color: #305078;
  color: #fff;
  min-height: 448px;
  padding: 4rem 1.5rem;
  position: relative;
}

.features-back-office__heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
}

.features-back-office__text {
  margin: 0 auto 4rem;
  max-width: 37.375rem;
  text-align: center;
}

.features-back-office__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}
.features-back-office__list img {
  display: block;
  height: 6.25rem;
  margin: 0 auto 1.5rem;
  width: 6.25rem;
}
.features-back-office__list > li {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
  width: 50%;
}
@media (min-width: 48.0625em) {
  .features-back-office__list > li {
    width: 33.3333333333%;
  }
}
@media (min-width: 60em) {
  .features-back-office__list > li {
    width: 16.6666666667%;
  }
}

.features-branding-options-one {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 87.5rem;
  padding-top: 3rem;
  position: relative;
  z-index: 10;
}

.features-branding-options__text {
  width: 100%;
}
@media (max-width: 87.4375em) {
  .features-branding-options__text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 30.5rem;
  }
}

.features-branding-options__img .small-img {
  display: block;
}
.features-branding-options__img .large-img {
  display: none;
}

@media (min-width: 48.0625em) {
  .features-branding-options__img .small-img {
    display: none;
  }
  .features-branding-options__img .large-img {
    display: block;
  }
}
@media (min-width: 60em) {
  .features-branding-options-one {
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .features-branding-options__text {
    max-width: 27.5rem;
    padding-right: 0;
  }
  .features-branding-options__img {
    flex: 1 0 64.2857142857%;
    max-width: 64.2857142857%;
  }
}
.features-branding-options-two {
  padding: 4rem 1rem;
  position: relative;
  z-index: 1;
}
@media (min-width: 48.0625em) {
  .features-branding-options-two {
    background-color: #f4f4f4;
    margin-top: -150px;
    padding-top: 177px;
  }
}
@media (min-width: 80em) {
  .features-branding-options-two {
    margin-top: -250px;
    padding-top: 277px;
  }
}
.features-branding-options-two .dots {
  display: none;
}
@media (min-width: 48.0625em) {
  .features-branding-options-two .dots {
    display: block;
  }
}

.features-one-click {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
}
@media (min-width: 60em) {
  .features-one-click {
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 59.9375em) {
  .features-one-click {
    align-items: center;
  }
}

.features-one-click__img {
  max-width: 760px;
  position: relative;
}
@media (min-width: 60em) {
  .features-one-click__img {
    margin-right: 170px;
    order: -1;
  }
  .features-one-click__img::after {
    background: no-repeat url("../images/features/arrow.png");
    content: "";
    height: 316px;
    position: absolute;
    right: -176px;
    top: 96px;
    width: 176px;
    z-index: 1;
  }
  .features-one-click__img .btn {
    display: none;
  }
}

.features-one-click__text {
  max-width: 26.75rem;
  padding-top: 32px;
  position: relative;
  z-index: 5;
}
.features-one-click__text .mobile-icons .mobile-icons__app:last-child {
  margin-bottom: 3rem;
  margin-right: 0;
}
@media (min-width: 60em) {
  .features-one-click__text .mobile-icons .mobile-icons__app:last-child {
    margin-bottom: 1rem;
  }
  .features-one-click__text .mobile-icons img {
    width: 172px;
  }
}
.features-one-click__text .btn {
  display: none;
  margin: 0 auto;
  max-width: 355px;
  text-align: center;
  width: 100%;
}
@media (min-width: 60em) {
  .features-one-click__text .btn {
    display: block;
  }
}

.features-content-controls {
  align-items: center;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 720px;
  padding-top: 4.5rem;
}
@media (min-width: 75.0625em) {
  .features-content-controls {
    background: #f4f4f4 url("../images/features/content-controls-bg.png") center bottom;
    flex-direction: row;
    justify-content: center;
    padding-top: 0;
  }
}
.features-content-controls .features-content-controls__img {
  width: 51.9270833333%;
}
.features-content-controls .features-content-controls__img img {
  display: none;
}
@media (max-width: 74.9375em) {
  .features-content-controls .features-content-controls__img {
    width: 100%;
  }
  .features-content-controls .features-content-controls__img img {
    display: block;
    margin: 0 auto;
    max-width: 525px;
  }
}
.features-content-controls .features-content-controls__text {
  max-width: 43.625rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.features-content-controls .features-content-controls__text p {
  max-width: 34.625rem;
}

.compare-discounts {
  padding: 4rem 0;
}
.compare-discounts .btn--center {
  padding: 0 1.5rem;
}

.compare-discounts-one {
  color: #305078;
  margin: 0 auto;
  max-width: 37.75rem;
  text-align: center;
}
@media (max-width: 37.6875em) {
  .compare-discounts-one {
    padding: 0 1.5rem;
  }
}
.compare-discounts-one h2 {
  font-size: 2rem;
  line-height: 1.2;
}

.comparison-slider {
  display: flex;
  margin: 0 auto 3rem;
  max-width: 81.25rem;
  padding: 0 1.5rem;
  text-align: center;
  width: 100%;
}
.comparison-slider.flickity-enabled {
  display: block;
}
.comparison-slider::after {
  content: "flickity";
  display: none;
}
@media (min-width: 30.0625em) {
  .comparison-slider {
    padding: 0;
  }
}
@media (min-width: 60em) {
  .comparison-slider::after {
    content: "";
  }
}
.comparison-slider .card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01), 0 6px 10px rgba(50, 50, 93, 0.07);
  margin: 1rem 1rem 2rem;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .comparison-slider .card {
    width: 400px;
  }
}
@media (min-width: 60em) {
  .comparison-slider .card {
    margin: 1rem;
    width: calc(33.3333333333% - 1rem);
  }
}
.comparison-slider .card.flickity-enabled {
  display: inline-block;
}
.comparison-slider .card img {
  height: auto;
  width: 100%;
}
.comparison-slider .flickity-button {
  color: #305078;
}
.comparison-slider .flickity-prev-next-button {
  background: none;
}
.comparison-slider .flickity-prev-next-button.previous {
  left: -64px;
}
.comparison-slider .flickity-prev-next-button.next {
  right: -64px;
}
@media (max-width: 89.1875em) {
  .comparison-slider .flickity-prev-next-button {
    display: inline-block;
    position: static;
    margin: 2rem 0.5rem 0;
  }
}

.dev-section {
  display: flex;
  flex-wrap: wrap;
}
.dev-section .dev-intro {
  width: 100%;
}
.dev-section .dev-icon {
  width: 33.3333333333%;
}

.dev-section {
  align-items: center;
  display: grid;
  grid-gap: 2rem;
  margin: 0 auto;
  max-width: 87.5rem;
  padding: 5rem 0 4rem;
  width: 100%;
}
@media (min-width: 80em) {
  .dev-section {
    grid-template-columns: 320px repeat(3, 1fr);
  }
}
@media (min-width: 87.5em) {
  .dev-section {
    grid-gap: 4.5rem;
  }
}
@media (max-width: 87.4375em) {
  .dev-section {
    padding: 5rem 1.5rem 4rem;
  }
}

@supports (display: grid) {
  .dev-section .dev-intro,
  .dev-section .dev-icon {
    width: auto;
  }
}
@media (min-width: 48.0625em) and (max-width: 79.9375em) {
  .dev-section {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  .dev-intro {
    grid-column: 1/4;
    grid-row: 1/2;
  }
  .dev-icon {
    grid-row: 2/3;
  }
  .dev-icon:nth-child(1) {
    grid-column: 1/2;
  }
  .dev-icon:nth-child(2) {
    grid-column: 2/3;
  }
  .dev-icon:nth-child(3) {
    grid-column: 3/4;
  }
}
.dev-icon {
  display: flex;
}
.dev-icon > div {
  padding-left: 1rem;
}

.dev-icon__heading {
  color: #3c3c3c;
  font-size: 1.25rem;
  letter-spacing: 0.025em;
  margin-bottom: 0;
}
@media (min-width: 48.0625em) {
  .dev-icon__heading {
    text-transform: uppercase;
  }
}

.dev-icon__text {
  font-size: 0.9375rem;
}

.dev-icon__img {
  height: auto;
  max-height: 4.25rem;
  max-width: 4.5rem;
}
@media (min-width: 87.5em) {
  .dev-icon__img {
    max-height: 4.625rem;
    max-width: 5.5rem;
  }
}

.additional-features-heading {
  font-size: 2rem;
  line-height: 1.2;
}

.additional-features-subheading {
  font-size: 1.25rem;
  letter-spacing: 0.025em;
  margin-bottom: 3rem;
  text-transform: uppercase;
}
@media (min-width: 48.0625em) {
  .additional-features-subheading {
    margin-bottom: 4.5rem;
  }
}

.additional-features {
  margin: 0 auto;
  max-width: 87.5rem;
}
@media (max-width: 87.4375em) {
  .additional-features {
    padding: 0 1.5rem;
  }
}
.additional-features .box {
  padding: 1.5rem;
}
@media (min-width: 48.0625em) {
  .additional-features .box {
    padding: 4rem;
  }
}
.additional-features li::marker {
  color: #3C9D36;
}
@media (min-width: 48.0625em) {
  .additional-features .grid__item {
    padding-right: 1.5rem;
    position: relative;
  }
  .additional-features .grid__item::after {
    background: url("../images/border-vertical.svg") repeat-y 0 0;
    content: "";
    height: 100%;
    position: absolute;
    right: 12px;
    top: 0;
    width: 1px;
  }
  .additional-features .grid__item:last-child::after {
    display: none;
  }
}
.additional-features .grid__item ul {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
@media (min-width: 60em) {
  .additional-features .grid__item ul {
    padding-left: 2em;
  }
}
.additional-features .grid__item ul > li {
  margin-bottom: 1rem;
}
@media (min-width: 48.0625em) {
  .additional-features .grid__item ul > li {
    margin-bottom: 2rem;
  }
}

.home-masthead {
  background-color: #d6d7d9;
  border-bottom: 4px solid #fff;
  padding-top: 150px;
  position: relative;
  text-align: center;
}
@media (min-width: 60em) {
  .home-masthead {
    min-height: calc(100vh - 150px);
  }
}
.home-masthead header {
  padding: 0 1rem;
}
.home-masthead header .masthead-heading {
  margin-bottom: 1rem;
  text-align: center;
}
@media (max-width: 59.9375em) {
  .home-masthead header .masthead-heading {
    font-size: 2.75rem;
  }
}
.home-masthead header .masthead-tagline {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #305078;
  font-size: 1.375rem;
  margin-bottom: 4rem;
}
@media (min-width: 60em) {
  .home-masthead header .masthead-tagline {
    color: #272a2e;
  }
}
@media (min-width: 87.5em) {
  .home-masthead header .masthead-tagline {
    margin-bottom: 4.5rem;
    font-size: 2rem;
  }
}
.home-masthead .masthead-form-label {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 60em) {
  .home-masthead .masthead-form-label {
    margin-bottom: 2rem;
  }
}
.home-masthead .top-brands-wrap {
  background-color: #f4f4f4;
  padding-bottom: 1rem;
  padding-top: 2rem;
}
.home-masthead .top-brands-wrap .top-brands-heading {
  color: #505256;
}
@media (min-width: 48.0625em) {
  .home-masthead .top-brands-wrap {
    background-color: transparent;
  }
  .home-masthead .top-brands-wrap .top-brands-heading {
    color: #272a2e;
  }
}
@media (min-width: 87.5em) {
  .home-masthead .top-brands-wrap {
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 30.0625em) {
  .home-masthead .large-hide {
    display: none;
  }
}
@media (max-width: 30em) {
  .home-masthead .small-hide {
    display: none;
  }
}

.request-demo-signup,
.request-demo-signup__input {
  border-radius: 99em;
}

.request-demo-signup {
  margin: 0 auto 2rem;
  max-width: 728px;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .request-demo-signup {
    background-color: #fff;
    display: flex;
    justify-content: center;
  }
}
.request-demo-signup.has-focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
}
.request-demo-signup .btn {
  font-size: 1.125rem;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .request-demo-signup .btn {
    width: auto;
  }
}

.request-demo-signup__input {
  border: 0;
  margin-bottom: 1rem;
  min-width: 100%;
  outline: 0;
  padding: 1rem 1rem 1rem 2rem;
  transition: 0.15s box-shadow ease-in;
}
@media (min-width: 30.0625em) {
  .request-demo-signup__input {
    flex: 1 0 0%;
    margin: 0 1rem 0 0;
    min-width: 250px;
    width: 100%;
  }
}

.home-masthead__content {
  position: relative;
  width: 100%;
  z-index: 5;
}
@media (min-width: 48.0625em) {
  .home-masthead__content {
    bottom: 0;
    position: absolute;
  }
}
.home-masthead__content header {
  margin-bottom: 2rem;
}
@media (min-width: 48.0625em) {
  .home-masthead__content header {
    margin-bottom: 6rem;
  }
}
@media (min-width: 80em) {
  .home-masthead__content header {
    margin-bottom: 8rem;
  }
}

@supports (display: grid) {
  .home-masthead__content {
    display: grid;
    grid-template-rows: 1fr auto;
    bottom: auto !important;
    position: relative !important;
  }
  @media (min-width: 60em) {
    .home-masthead__content {
      min-height: calc(100vh - 150px);
    }
  }
  .home-masthead__content header {
    align-self: end;
    margin-bottom: 0 !important;
  }
  @media (min-width: 60em) {
    .home-masthead__content header {
      align-self: center;
    }
  }
}
.masthead-slider .masthead__slide {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  z-index: 1;
}
.masthead-slider .masthead__slide.is-showing {
  opacity: 1;
  z-index: 2;
}
.masthead-slider .masthead__img {
  background-size: cover;
  background-position: bottom center;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
@media ((min-width: 46.625em)) {
  .masthead-slider .masthead__img {
    background-position: 50% 50%;
  }
}

.reward-section-one {
  padding: 2rem 1rem 1.5rem;
}
@media (min-width: 60em) {
  .reward-section-one {
    background-color: #f4f4f4;
    padding: 4rem 1rem;
  }
}

.reward-section-two {
  background-color: #f4f4f4;
  padding: 2.5rem 2rem;
}
@media (min-width: 60em) {
  .reward-section-two {
    padding: 0 2rem 4rem;
  }
}

.typewriter {
  display: block;
  white-space: nowrap;
}
@media (min-width: 30.0625em) {
  .typewriter {
    display: inline;
  }
}

.typewriter__caret {
  animation: blink-caret 0.75s step-end infinite;
  bottom: -10px;
  display: inline-block;
  border: 2px solid #000;
  height: 42px;
  position: relative;
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #72cd6c;
  }
}
.home-icon-list-heading {
  font-size: 1.5rem;
}
@media (min-width: 30.0625em) {
  .home-icon-list-heading {
    text-align: left;
  }
}

.home-icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  width: 100%;
}
@media (max-width: 30em) {
  .home-icon-list {
    margin: 0 auto;
  }
}
@media (min-width: 60em) {
  .home-icon-list {
    justify-content: space-between;
  }
}
.home-icon-list img,
.home-icon-list [role=img] {
  display: inline-flex;
  height: auto;
  margin-right: 2rem;
  margin-bottom: 1rem;
  max-width: 4rem;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .home-icon-list img,
  .home-icon-list [role=img] {
    margin-right: 0;
    max-width: 6rem;
  }
}
.home-icon-list > li {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  list-style: none;
  font-size: 1.25rem;
  margin: 0;
  padding: 0.5rem;
  min-width: 6rem;
  width: 100%;
}
.home-icon-list > li:first-child {
  margin-left: 0;
}
@media (min-width: 30.0625em) {
  .home-icon-list > li {
    text-align: center;
    width: auto;
  }
}
.home-icon-list a {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
.home-icon-list a:link, .home-icon-list a:visited {
  color: #272a2e;
}
.home-icon-list a:hover {
  color: #418BDB;
}
@media (min-width: 30.0625em) {
  .home-icon-list a {
    flex-direction: column;
    justify-content: center;
  }
}
.home-icon-list p {
  margin-bottom: 0;
}
.home-icon-list span {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: block;
}

.perks-report__form {
  margin-bottom: 2rem;
}

@supports (display: grid) {
  .perks-report__form {
    margin-bottom: 0;
  }
}
@media (min-width: 60em) {
  .perks-report__img,
  .perks-report__form {
    float: left;
    width: 50%;
  }
  .perks-report__merchants {
    clear: left;
    padding-top: 3rem;
  }
  @supports (display: grid) {
    .perks-report__img,
    .perks-report__form {
      float: none;
      width: auto;
    }
    .perks-report__merchants {
      padding-top: 0;
    }
  }
}
.perks-report {
  display: grid;
  place-items: start center;
}

.perks-report-heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #305078;
  line-height: 1.2;
  font-size: 2.3125rem;
}
@media (min-width: 23.5em) {
  .perks-report-heading {
    font-size: 2.75rem;
    text-align: center;
  }
}

.perks-report__title {
  margin-bottom: 2rem;
  order: 1;
}
.perks-report__title p {
  margin: 0 auto;
  max-width: 22.75rem;
}

.perks-report__img {
  order: 2;
  padding-bottom: 1.5rem;
}
.perks-report__img img {
  height: auto;
  max-width: 27.25rem;
}

.perks-report__form {
  order: 4;
}
.perks-report__form .box {
  padding: 1rem;
}
@media (min-width: 30.0625em) {
  .perks-report__form .box {
    padding: 2rem 2.5rem;
  }
}

.perks-report__merchants {
  padding-bottom: 1.5rem;
  order: 3;
}

@media (min-width: 57em) {
  .perks-report {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
  }
  .perks-report__img,
  .perks-report__merchants {
    grid-column: 1/2;
  }
  .perks-report__img {
    align-self: end;
    grid-row: 1/3;
  }
  .perks-report__merchants {
    grid-row: 3/4;
    margin-bottom: 0;
    padding-bottom: 2rem;
  }
  .perks-report__title {
    grid-row: 1/2;
  }
  .perks-report__form {
    grid-row: 2/4;
    margin-bottom: 0;
  }
  .perks-report__form .box {
    max-width: 31.25rem;
  }
}
@media (min-width: 48.0625em) {
  .complete-control__img {
    float: left;
    width: 50%;
  }
  @supports (display: grid) {
    .complete-control__img {
      float: none;
      width: auto;
    }
  }
}
.complete-control {
  display: grid;
  gap: 0 1rem;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
}
.complete-control:after {
  clear: both;
  content: "";
  display: table;
}
.complete-control .section-heading {
  margin-bottom: 2rem;
}

.complete-control__title,
.complete-control__content {
  padding-left: 1rem;
  padding-right: 1rem;
}

.complete-control__title {
  grid-row: 1/2;
  padding-top: 2rem;
}

.complete-control__img {
  align-self: end;
  grid-row: 2/3;
}
.complete-control__img img {
  max-width: 53.75rem;
}

.complete-control__content {
  align-self: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

@media (min-width: 52.5em) {
  .complete-control {
    grid-template-columns: 1fr 1fr;
  }
  .complete-control__title {
    grid-column: 1/2;
    padding-bottom: 2rem;
  }
  .complete-control__content {
    grid-row: 1/3;
  }
}
@media (min-width: 80em) {
  .complete-control {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
  }
  .complete-control .section-heading {
    margin-bottom: 0;
  }
  .complete-control__title,
  .complete-control__content {
    padding-left: 0;
    padding-right: 0;
  }
  .complete-control__img {
    grid-column: 1/2;
    grid-row: 1/3;
  }
  .complete-control__content {
    grid-row: 2/3;
    padding-bottom: 2rem;
  }
  .complete-control__title {
    align-self: end;
    padding-bottom: 0;
  }
  .complete-control__title,
  .complete-control__content {
    grid-column: 2/3;
  }
  .complete-control__content {
    grid-row: 2/3;
  }
}
@media (min-width: 87.5em) {
  .complete-control__img {
    padding-top: 2rem;
  }
}
.feature-list {
  color: #305078;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  padding: 0;
}
@media (min-width: 30.0625em) {
  .feature-list .mobile-icons {
    margin-bottom: -0.5rem;
  }
}
@media (max-width: 30em) {
  .feature-list .mobile-icons {
    width: 100%;
  }
}
@media (min-width: 87.5em) {
  .feature-list {
    margin-bottom: 2rem;
  }
}

.feature-list__item {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
}
@media (min-width: 30.0625em) {
  .feature-list__item {
    margin-bottom: 1rem;
    margin-right: 1rem;
    width: calc(50% - 1rem);
  }
}

.feature-list__icon {
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 1rem;
  height: 48px;
  width: 48px;
}

.today-section-heading {
  text-align: left;
  margin-bottom: 1rem;
}
@media (min-width: 60em) {
  .today-section-heading {
    margin-bottom: 3rem;
    text-align: center;
  }
}

.today-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 60em) {
  .today-section {
    flex-direction: row;
  }
}
.today-section .today-section__col {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  background-color: #fff;
  border-radius: 10px;
  color: inherit;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  max-width: 29rem;
  padding: 2rem 1rem;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: 0.25s transform ease-in-out;
  width: 100%;
}
.today-section .today-section__col:last-child {
  margin-right: 0;
}
@media (max-width: 59.9375em) {
  .today-section .today-section__col:nth-child(2) {
    order: -1;
  }
}
@media (min-width: 60em) {
  .today-section .today-section__col {
    margin: 0 1.5rem 2rem 0;
    flex: 1 0 calc((100% / 3) - 1.5rem);
    max-width: calc((100% / 3) - 1.5rem);
  }
}
.today-section .today-section__col:hover {
  transform: translate3d(0, -16px, 0);
}
.today-section .today-section__col:hover h3 {
  color: #305078;
}
.today-section .today-section__col h3 {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}
@media (min-width: 87.5em) {
  .today-section .today-section__col h3 {
    font-size: 1.375rem;
    margin-bottom: 2rem;
  }
}
.today-section .today-section__col p {
  font-size: 1.125rem;
  margin: 0 auto 2rem;
  max-width: 100%;
}
@media (min-width: 60em) {
  .today-section .today-section__col p {
    max-width: 18.125rem;
  }
}
@media (min-width: 87.5em) {
  .today-section .today-section__col p {
    font-size: 1.25rem;
    margin-bottom: 3rem;
  }
}
.today-section .today-section__col .disneyland-text {
  max-width: 100%;
}
@media (min-width: 87.5em) {
  .today-section .today-section__col .disneyland-text {
    margin-bottom: 2rem;
  }
}
.today-section .today-section__col--no-pad {
  padding: 2rem 0;
}
.today-section .today-section__col--no-pad header {
  padding: 0 1rem;
}
.today-section .today-section__body {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
}
.today-section .today-section-img {
  display: block;
  height: auto;
  margin: 0 auto;
  width: 18rem;
}

.login-heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
}
@media (min-width: 48.0625em) {
  .login-heading {
    font-size: 1.5rem;
  }
}
.login-heading span {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 2rem;
  display: block;
}
@media (min-width: 48.0625em) {
  .login-heading span {
    color: #305078;
    font-size: 2.625rem;
    display: inline;
  }
}

.login-form {
  margin: 0 auto;
  position: relative;
  max-width: 63rem;
}

.login-form-inputs {
  margin-left: auto;
  margin-right: auto;
  max-width: 27rem;
  width: 100%;
}
.login-form-inputs input {
  width: 100%;
}

.login-options-btn,
.login-options-list {
  box-shadow: 0px 0px 58.5px 6.5px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  border: 1px solid #d6dbe5;
  border-radius: 8px;
  width: 100%;
}

.login-options-btn {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%236e84a0' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3E%3C/svg%3E");
  background-position: right 24px top 50%;
  background-repeat: no-repeat;
  background-size: 12px auto;
  border: 0;
  display: block;
  margin-bottom: 4rem;
  padding: 1rem 32px 1rem 1.5rem;
  text-align: left;
}
@media (min-width: 48.0625em) {
  .login-options-btn {
    display: none;
  }
}

.login-options-list {
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 5;
}
.login-options-list.is-visible {
  display: block;
}
@media (min-width: 48.0625em) {
  .login-options-list {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    display: block;
    position: static;
  }
}

.login-options {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 48.0625em) {
  .login-options {
    flex-direction: row;
    margin-bottom: 4rem;
  }
}

.login-options__radio {
  border-bottom: 1px solid #F3F4F8;
}
.login-options__radio:last-child {
  border: 0;
}
@media (min-width: 48.0625em) {
  .login-options__radio {
    border: 0;
    padding: 1rem;
    width: 50%;
  }
}
@media (max-width: 48em) {
  .login-options__radio:first-child label {
    border-radius: 8px 8px 0 0;
  }
  .login-options__radio:last-child label {
    border-radius: 0 0 8px 8px;
  }
}
.login-options__radio label {
  cursor: pointer;
  margin: 0;
  padding: 1rem 1.5rem;
}
@media (min-width: 48.0625em) {
  .login-options__radio label {
    box-shadow: 0px 0px 58.5px 6.5px rgba(0, 0, 0, 0.09);
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
  }
}
.login-options__radio input[type=checkbox],
.login-options__radio input[type=radio] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.login-options__radio input[type=checkbox]:checked + label,
.login-options__radio input[type=radio]:checked + label {
  background-color: #B9C5DF;
}
@media (max-width: 48em) {
  .login-options__radio input[type=checkbox]:checked + label,
  .login-options__radio input[type=radio]:checked + label {
    padding: 1rem 1.5rem;
  }
  .login-options__radio input[type=checkbox]:checked + label .list-options__text,
  .login-options__radio input[type=radio]:checked + label .list-options__text {
    color: #272a2e;
  }
}

.list-options__heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #305078;
  font-size: 1.25rem;
  margin-bottom: 0;
}
@media (min-width: 48.0625em) {
  .list-options__heading {
    font-size: 1.75rem;
  }
}

.list-options__text {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #99a6b7;
  font-size: 1rem;
  margin-bottom: 0;
}
@media (min-width: 48.0625em) {
  .list-options__text {
    color: #272a2e;
    font-size: 1.125rem;
  }
}

.password-input.field-addon button.passwordless-btn {
  background-color: #305078;
  flex: 1;
  padding: 1rem 1rem;
  transition: all 0.15s ease-in;
}
@media (max-width: 30em) {
  .password-input.field-addon button.passwordless-btn {
    flex: auto;
  }
}
.password-input.field-addon button.passwordless-btn:hover {
  background-color: #4472AB;
  color: #f7f8fa;
}
.password-input.field-addon button.passwordless-btn.hide {
  display: none;
}

.passwordless-help {
  color: #aaa;
  margin-top: 0.5rem;
  text-align: center;
}
.passwordless-help.hide {
  display: none;
}

.password-hide-toggle.hide {
  display: none;
}

input[name=password].field-addon__input {
  flex: 1;
  transition: all 0.15s ease-in;
}
input[name=password].field-addon__input.toggle {
  flex: 0;
  opacity: 0;
  padding: 0;
}

.merchants-masthead {
  background: #f4f4f4 no-repeat url("../images/masthead/merchants-small.jpg") right top;
}
@media (min-width: 30.0625em) {
  .merchants-masthead {
    background-image: url("../images/masthead/merchants-large.jpg");
    background-position: left top;
  }
}
@media (min-width: 60em) {
  .merchants-masthead {
    background-position: center calc(100% - 145px);
  }
}

.merchants-masthead__text h2 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  padding-top: 3rem;
}
@media (min-width: 60em) {
  .merchants-masthead__text h2 {
    font-size: 1.5rem;
    padding-top: 5rem;
  }
}
.merchants-masthead__text p {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #4d5055;
}
@media (min-width: 60em) {
  .merchants-masthead__text p {
    font-size: 1.25rem;
  }
}
.merchants-masthead__text form {
  padding-bottom: 3rem;
}
@media (min-width: 60em) {
  .merchants-masthead__text form {
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 60em) {
  .merchants-masthead__text {
    margin-bottom: 145px;
    padding-right: 3rem;
  }
}
@media (min-width: 80em) {
  .merchants-masthead__text {
    padding-right: 13.5rem;
  }
}
@media (max-width: 30em) {
  .merchants-masthead__text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.perks-for-masthead {
  background-color: #f4f4f4;
  position: relative;
}
@media (min-width: 30.0625em) {
  .perks-for-masthead {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .perks-for-masthead::after {
    background-color: #fff;
    bottom: 0;
    content: "";
    height: 145px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}
.perks-for-masthead .masthead-heading {
  font-size: 3rem;
  line-height: 3.5rem;
  margin-bottom: 0;
  max-width: 30.25rem;
  text-align: left;
}
.perks-for-masthead .masthead-heading mark {
  display: block;
  font-size: 3rem;
}
@media (min-width: 30.0625em) {
  .perks-for-masthead .masthead-heading mark {
    font-size: 4.5rem;
  }
}
@media (max-width: 30em) {
  .perks-for-masthead .masthead-heading {
    font-size: 2rem;
    line-height: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: center;
  }
}
.perks-for-masthead .box {
  position: relative;
  z-index: 5;
}
@media (max-width: 30em) {
  .perks-for-masthead .box {
    border-radius: 0;
    box-shadow: none;
  }
  .perks-for-masthead .box__header {
    border-radius: 0;
  }
  .perks-for-masthead h2 {
    text-align: center;
  }
}

.perks-for-masthead__inner {
  padding-top: 7.75rem;
  margin: 0 auto;
  max-width: 87.5rem;
}
@media (min-width: 60em) {
  .perks-for-masthead__inner {
    display: flex;
  }
  .perks-for-masthead__inner .perks-for-masthead__intro {
    width: 60%;
  }
  .perks-for-masthead__inner .box {
    width: 40%;
  }
}

@supports (display: grid) {
  .perks-for-masthead__inner {
    display: grid !important;
  }
  @media (min-width: 60em) {
    .perks-for-masthead__inner {
      grid-template-columns: 1fr 530px;
    }
  }
  @media (min-width: 87.5em) {
    .perks-for-masthead__inner {
      grid-template-columns: minmax(370px, 870px) minmax(0, 530px);
    }
  }
  .perks-for-masthead__inner .perks-for-masthead__intro,
  .perks-for-masthead__inner .box {
    width: auto;
  }
}
.perks-for-masthead__intro {
  align-self: center;
}
@media (min-width: 80em) {
  .perks-for-masthead__intro {
    align-self: flex-end;
  }
}
@media (min-width: 30.0625em) {
  .perks-for-masthead__intro .small-img {
    display: none;
  }
}

@supports (display: grid) {
  .perks-for-masthead__intro {
    align-self: center;
  }
  @media (min-width: 80em) {
    .perks-for-masthead__intro {
      align-self: end;
    }
  }
}
.perks-for-masthead__text {
  background-color: #dedfe1;
  padding: 2rem;
}
@media (min-width: 30.0625em) {
  .perks-for-masthead__text {
    background-color: #f4f4f4;
  }
}
@media (min-width: 60em) {
  .perks-for-masthead__text {
    margin-bottom: 145px;
    padding-left: 0;
    padding-top: 3.5rem;
  }
}
@media (min-width: 80em) {
  .perks-for-masthead__text {
    background: no-repeat #f4f4f4 20px 100% image-set(url("../images/masthead/masthead-alumni.jpg") 1x, url("../images/masthead/masthead-alumni@2x.jpg") 2x);
    min-height: 510px;
    padding-bottom: 0;
    padding-right: 56.3218390805%;
  }
}

@supports (display: grid) {
  @media (min-width: 80em) {
    .perks-for-masthead__text {
      background-position: -40px 100%;
    }
  }
  @media (min-width: 87.5em) {
    .perks-for-masthead__text {
      background-position: right bottom;
    }
  }
}
.perks-for-masthead-list {
  padding-left: 1em;
}
.perks-for-masthead-list > li {
  padding-left: 1rem;
}
.perks-for-masthead-list h2 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
}
.perks-for-masthead-list p {
  color: #525252;
  font-size: 0.875rem;
}

.avatar-layout {
  align-items: center;
  display: flex;
}
.avatar-layout .avatar-layout__text {
  flex: 1 1 auto;
  padding-right: 1.5rem;
}
.avatar-layout .avatar-layout__text p {
  color: #525252;
}
.avatar-layout .avatar-layout__img {
  flex-shrink: 0;
  width: 128px;
}
@media (max-width: 30em) {
  .avatar-layout {
    flex-direction: column;
    text-align: center;
  }
  .avatar-layout .avatar-layout__text {
    padding-right: 0;
  }
  .avatar-layout .avatar-layout__img {
    margin-bottom: 1rem;
    order: -1;
  }
}

.avatar-layout__heading {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
}

.features-icons {
  display: flex;
}
@media (max-width: 48em) {
  .features-icons {
    align-items: center;
    flex-direction: column;
  }
}

.features-icons__item {
  text-align: center;
}
@media (min-width: 30.0625em) {
  .features-icons__item {
    padding: 1.875rem;
    width: 80%;
  }
}
@media (min-width: 80em) {
  .features-icons__item {
    width: 33.3333333333%;
  }
}

.features-icons__img {
  background-color: #e3e1df;
  border-radius: 50%;
  height: 12.5rem;
  margin: 0 auto 2rem;
  padding: 2.5rem;
  width: 12.5rem;
}

.features-icons__heading {
  font-size: 1.25rem;
  border-bottom: 1px solid #aaa;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}
@media (min-width: 80em) {
  .features-icons__heading {
    font-size: 1.5rem;
  }
}

.features-icons__text {
  color: #525252;
}

.pricing-masthead {
  background-image: url("../images/masthead/pricing-masthead-small.jpg");
  background-position: center bottom;
}
.pricing-masthead .masthead-content {
  margin-top: 10rem;
}
.pricing-masthead .masthead-list {
  display: none;
}
@media (min-width: 30.0625em) {
  .pricing-masthead {
    background-image: image-set(url("../images/masthead/pricing-masthead-large.jpg") 1x, url("../images/masthead/pricing-masthead-large@2x.jpg") 2x);
  }
  .pricing-masthead .masthead-content {
    margin: auto;
  }
  .pricing-masthead .masthead-list {
    display: block;
  }
}

.pricing-wrap {
  margin: 0 auto 3rem;
  position: relative;
  max-width: 680px;
}
@media (min-width: 72.5em) {
  .pricing-wrap {
    margin-bottom: 3rem;
    max-width: 87.5rem;
  }
}

.pricing {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 72.5em) {
  .pricing {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
}

.pricing__item {
  margin-bottom: 2rem;
  width: 100%;
}
.pricing__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 72.5em) {
  .pricing__item {
    margin-bottom: 0;
    width: calc(33.3333333333% - 0.75rem);
  }
}

.pricing-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01), 0 6px 10px rgba(50, 50, 93, 0.07);
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
  position: relative;
}
@media (min-width: 72.5em) {
  .pricing-card {
    margin-top: 4rem;
  }
  .pricing-card.pricing-card--em {
    margin-top: 0;
  }
}
.pricing-card:last-child {
  margin-bottom: 0;
}
.pricing-card h4,
.pricing-card h5 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.pricing-card h3 {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 0;
  text-align: center;
}
.pricing-card h3 span {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #305078;
  display: block;
  font-size: 2rem;
  line-height: 1;
}
.pricing-card h3 strong {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 3.125rem;
}
.pricing-card h4 {
  color: #305078;
  font-size: 1.375rem;
}
.pricing-card h5 {
  font-size: 1.25rem;
}
.pricing-card ul {
  padding-left: 1em;
}
.pricing-card ul > li {
  margin-bottom: 0.5rem;
}

.pricing__item__text {
  margin-top: 3rem;
}
.pricing__item__text p {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
}
.pricing__item__text a {
  font-size: 1rem;
}
.pricing__item__text a:link, .pricing__item__text a:visited {
  color: #fff;
}

.pricing__item__text:not(.pricing__item__text--small) {
  display: none;
}
@media (min-width: 72.5em) {
  .pricing__item__text:not(.pricing__item__text--small) {
    display: block;
  }
}

@media (min-width: 72.5em) {
  .pricing__item__text--small {
    display: none;
  }
}

.pricing-card__badge {
  height: 89px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 89px;
}

.pricing-card__header {
  border-bottom: 1px dashed #d6d7d9;
  padding: 1.5rem;
  width: 100%;
}

.pricing-card__body {
  flex-grow: 1;
  justify-content: center;
  padding-top: 1rem;
}
@media (min-width: 30.0625em) and (max-width: 72.4375em) {
  .pricing-card__body > ul {
    display: flex;
    flex-wrap: wrap;
  }
  .pricing-card__body > ul > li {
    width: 40%;
  }
  .pricing-card__body > ul > li:nth-child(even) {
    margin-left: 10%;
  }
}

.pricing-card__footer {
  border-radius: 0 0 8px 8px;
  padding: 1rem;
  text-align: center;
}
.pricing-card__footer p {
  margin: 0;
}
.pricing-card__footer span {
  display: block;
}
.pricing-card__footer .btn {
  border: 2px solid #fff;
  margin-bottom: 0.5rem;
}

.pricing-tagline {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #305078;
  font-size: 1.875rem;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 1rem;
  text-align: center;
}

@media (max-width: 48em) {
  .standard-features {
    background-color: #305078;
  }
  .standard-features .section-heading,
  .standard-features .section-text {
    color: #fff;
  }
  .standard-features .app-icons__link {
    color: #fff;
    transition: 0.25s opacity ease-in;
  }
  .standard-features .app-icons__link:hover {
    opacity: 0.7;
  }
  .standard-features .app-icons__link svg {
    background-color: #456286;
    color: #fff;
  }
}

.coupon-code-pricing .field-addon {
  border: 1px solid #d5dde9;
  border-radius: 99em;
}

.commission-table-container {
  overflow-x: auto;
}

.small-business-masthead .large-img {
  flex-shrink: 0;
}
@media (min-width: 79.6875em) {
  .small-business-masthead {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
  }
  .small-business-masthead .large-img {
    height: auto;
    order: 2;
    max-width: 50%;
    width: 100%;
  }
  .small-business-masthead .masthead-content {
    padding-left: 2rem;
    padding-top: 2rem;
  }
  .small-business-masthead .masthead-content__text {
    padding-bottom: 2rem;
  }
}

.small-business-masthead {
  background-color: #f4f4f4;
  border-bottom: 4px solid #fff;
  padding-top: 6.25rem;
}
.small-business-masthead .masthead-heading-custom {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #305078;
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: left;
}
@media (min-width: 38.75em) {
  .small-business-masthead .masthead-heading-custom {
    font-size: 4.5rem;
    margin-bottom: 2rem;
  }
}
.small-business-masthead .masthead-heading-custom mark {
  font-size: 2rem;
  line-height: 2rem;
}
@media (min-width: 38.75em) {
  .small-business-masthead .masthead-heading-custom mark {
    font-size: 3rem;
    line-height: 3rem;
  }
}
@media (max-width: 79.625em) {
  .small-business-masthead {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
  }
  .small-business-masthead .masthead-content {
    padding: 2rem;
    max-width: 43.75rem;
    width: 100%;
  }
}
.small-business-masthead .small-img {
  display: none;
}
@media (max-width: 30em) {
  .small-business-masthead .small-img {
    display: block;
  }
  .small-business-masthead .large-img {
    display: none;
  }
  .small-business-masthead .masthead-content {
    padding: 0;
  }
  .small-business-masthead .masthead-content__text {
    background-color: #DEDFE1;
    padding-bottom: 1.5rem;
  }
  .small-business-masthead .masthead-content__heading,
  .small-business-masthead .masthead-content__text {
    margin-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@supports (shape-outside: url("../images/masthead/small-business-masthead-mask.png")) {
  .small-business-masthead:after {
    clear: both;
    content: "";
    display: table;
  }
  .small-business-masthead .large-img {
    shape-outside: url("../images/masthead/small-business-masthead-mask.png");
  }
  .small-business-masthead .masthead-content__text {
    padding-bottom: 0;
  }
  @media (min-width: 79.6875em) {
    .small-business-masthead {
      display: block;
    }
    .small-business-masthead .large-img {
      float: right;
      max-width: 72.9166666667%;
    }
    .small-business-masthead .masthead-content {
      padding-left: 1.5rem;
      padding-top: 3.75%;
    }
  }
  @media (min-width: 89em) {
    .small-business-masthead .masthead-content {
      padding-left: 6.6666666667%;
      padding-top: 6.6666666667%;
    }
  }
  @media (min-width: 105.625em) {
    .small-business-masthead .masthead-content {
      padding-left: 13.0208333333%;
    }
    .small-business-masthead .masthead-content.masthead-content--thankyou {
      padding-left: 22%;
    }
  }
}
.small-business-stat {
  border: 1px solid #7388a3;
  border-radius: 6px;
}

.credit-card-box {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.07), 0 10px 24px rgba(50, 50, 93, 0.1);
  display: flex;
  flex-direction: column;
}
@media (min-width: 57.625em) {
  .credit-card-box {
    flex-direction: row;
  }
}

.credit-card-box__text {
  padding: 1rem;
}
@media (min-width: 57.625em) {
  .credit-card-box__text {
    flex-basis: 328px;
  }
}
.credit-card-box__text h3 {
  margin-bottom: 0.5rem;
}
.credit-card-box__text .old-monthly-price {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #525252;
  margin-bottom: 0;
  text-decoration: line-through;
}
.credit-card-box__text .note-green {
  color: #3C9D36;
}
.credit-card-box__text .monthly-price {
  font-family: "Gotham A", "Gotham B", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #3C9D36;
  line-height: 1;
  font-size: 3rem;
}
.credit-card-box__text .small-business-price {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.credit-card-box__text .small-business-price.fade-out {
  opacity: 0;
}

.credit-card-box__body {
  background-color: #E8E8E8;
  border-radius: 0 0 4px 4px;
  font-size: 0.875rem;
  padding: 1rem;
  width: 100%;
}
@media (min-width: 57.625em) {
  .credit-card-box__body {
    border-radius: 0 4px 4px 0;
    flex: 1 0 auto;
    width: auto;
  }
}

.credit-card-icons {
  height: auto;
  width: 23rem;
}

.credit-card-fields .form_item {
  margin-bottom: 0;
}
.credit-card-fields h3,
.credit-card-fields input {
  margin-bottom: 8px;
}
.credit-card-fields input {
  width: 100%;
}

.credit-card {
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
  padding-bottom: 8px;
}
@media (min-width: 30.0625em) {
  .credit-card {
    display: flex;
  }
  .credit-card > div {
    padding-right: 8px;
    width: 50%;
  }
  .credit-card > div:last-child {
    padding-left: 8px;
    padding-right: 0;
  }
}

.credit-card__details {
  display: flex;
  flex-wrap: wrap;
}
.credit-card__details .credit-card__number {
  flex-basis: 232px;
  max-width: 232px;
  padding-right: 16px;
}
.credit-card__details .credit-card__date .label {
  white-space: nowrap;
}
.credit-card__details .credit-card__date,
.credit-card__details .credit-card__cvc {
  flex-basis: 144px;
  max-width: 144px;
  padding-right: 16px;
}
.credit-card__details .credit-card__date > div,
.credit-card__details .credit-card__cvc > div {
  display: inline-block;
  padding-right: 8px;
  width: calc(50% - 8px);
}

.coupon-code__label {
  flex-shrink: 0;
  padding: 0 0 8px;
}
.coupon-code__label label {
  margin-bottom: 0;
}

.coupon-code__input {
  flex: 1 0 auto;
}

@media (min-width: 38em) {
  .coupon-code {
    align-items: center;
    display: flex;
  }
  .coupon-code__label {
    padding: 0 16px 0;
  }
}
.social-mission-masthead {
  background-image: url("../images/masthead/social-mission-masthead.jpg");
  background-position: left bottom;
  padding-top: 6.25rem;
}
.social-mission-masthead .masthead-content {
  margin: auto;
  max-width: 87.5rem;
}
.social-mission-masthead .masthead-heading {
  margin: 0;
}
.social-mission-masthead .masthead-heading,
.social-mission-masthead .masthead-quote {
  max-width: 28rem;
}
@media (max-width: 48em) {
  .social-mission-masthead {
    background-image: url("../images/masthead/social-mission-masthead-small.jpg");
    padding-top: 10rem;
  }
  .social-mission-masthead .masthead-heading {
    margin: auto;
    max-width: 27rem;
  }
  .social-mission-masthead .masthead-content {
    margin-top: 0;
  }
  .social-mission-masthead .masthead-quote {
    display: none;
  }
}

@media (max-width: 48em) {
  .impact-report-preview {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .impact-report-preview img {
    max-width: 675px;
    width: 675px;
  }
}

.impact-cover {
  float: right;
  max-width: 32.5rem;
}

li.status {
  list-style-type: none;
}
li.status::before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 12px;
  margin-right: 0.5rem;
  width: 12px;
}
li.status.green_light::before {
  background-color: #3C9D36;
}
li.status.red_light::before {
  background-color: #C13D3D;
}
li.status.all::before {
  display: none;
}