@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

// ----------------------------------------------
// Used for elements that fill the screen
// ----------------------------------------------

%modal-cover {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}


// ----------------------------------------------
// Body Class to prevent scrolling
// ----------------------------------------------

body.has-modal {
    overflow: hidden;
}


// ----------------------------------------------
// Parent container that holds all modal elements
// ----------------------------------------------

.modal {
    @extend %modal-cover;

    background-color: _c.$blue-dark;
    display: none;
    opacity: 0;
    outline: 0;
    transition: opacity 0.25s _c.$ease-in-out-cubic;
    z-index: _c.$z-index-overlay;

    .modal__content {
        opacity: 0;
        outline: 0;
        transform: translate3d(0, -8px, 0);
        transition: opacity 0.25s _c.$ease-in-out-cubic 0.15s, transform 0.35s _c.$ease-in-out-cubic 0.15s;
    }

    &.is-active {
        display: block;
    }

    &.is-visible {
        opacity: 1;

        .modal__content {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
}


// ----------------------------------------------
// Close Button
// ----------------------------------------------

.modal__close {
    background-color: transparent;
    border: 0;
    color: _c.$white;
    display: block;
    height: _f.rem(32) + _c.$spacing-s;
    padding: _c.$spacing-s;
    position: absolute;
    right: _c.$spacing-m;
    top: _c.$spacing-m;
    width: _f.rem(32) + _c.$spacing-s;
}


// ----------------------------------------------
// Invisible element used for the background close
// ----------------------------------------------

.modal__overlay {
    @extend %modal-cover;

    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-left: _c.$spacing-m;
    padding-right: _c.$spacing-m;

    @media #{(_f.query(_c.$breakpoint-s))} {
        padding-left: _c.$spacing-l;
        padding-right: _c.$spacing-l;
    }
}


// ------------ Displays the content of the modal ------------ //
.modal__content {
    background-color: _c.$white;
    border-radius: _c.$border-radius-xl;
    margin: _c.$spacing-xxxl auto;
    max-width: _f.rem(928);
}

.modal--savings-calc .modal__content {
    max-width: _f.rem(1024);
}

.modal--login {
    .modal__close {
        color: #8aa1be;
    }

    .modal__content {
        background-color: #f5f7fc;
        max-width: _c.$main-width;
        padding: _f.rem(144) _c.$spacing-xm;

        @media #{(_f.query(_c.$breakpoint-m))} {
            padding: _c.$spacing-xxl _c.$spacing-l;
        }
    }
}

.modal__header {
    padding-top: _c.$spacing-l;
}

.modal__header--padded {
    @media #{(_f.query(_c.$breakpoint-l, 'max'))} {
        .section-heading,
        .section-heading__sub {
            padding-left: _c.$spacing-m;
            padding-right: _c.$spacing-m;
        }
    }
}

.modal__header--bg {
    background-repeat: no-repeat;
    background-position: 50% 0;
    min-height: 376px;
    padding: _c.$spacing-xm;


    @media #{_f.query(_c.$breakpoint-m)} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 314px;
    }

    .modal & {
        border-radius: _c.$border-radius-l _c.$border-radius-l 0 0;
    }

    &.request-demo-header {
        background-image: url('../images/request-demo/request-demo-exit-intent-mobile.jpg');
        background-size: cover;

        @media #{_f.query(_c.$breakpoint-s)} {
            background-image: url('../images/request-demo/request-demo-exit-intent.jpg');
            padding: _c.$spacing-xl;
        }
    }


    &.perks-report-header {
        background-color: _c.$gray-light-med;
        background-image: url('../images/perks-report/perks-comparison-exit-intent-mobile.jpg');
        background-position: right center;
        background-size: auto auto;

        @media #{_f.query(_f.em(541, 16))} {
            background-position: right bottom;
        }

        @media #{_f.query(_f.em(861, 16))} {
            background-image: url('../images/perks-report/perks-comparison-exit-intent.jpg');
            padding: _c.$spacing-xl;
        }
    }

    .modal__header__text {
        font-size: _f.rem(22);
    }
}

.modal__header--solid {
    background-color: #f6f6f4;

    .modal & {
        border-radius: _c.$border-radius-l _c.$border-radius-l 0 0;
    }
}

.modal__header__content {
    padding: 0 _c.$spacing-l;

    @media #{(_f.query(_c.$breakpoint-m))} {
        padding-right: 0;
    }
}

.modal__content--pad {
    padding: _c.$spacing-l;
}

.modal__header__text {
    @include _m.sans--bold;
    color: _c.$blue;

    @media #{_f.query(_c.$breakpoint-m)} {
        font-size: _f.rem(22);
    }
}

.modal__header--img-wrap {
    @include _m.clearfix;

    @media #{_f.query(_c.$breakpoint-m)} {
        img {
            float: right;
        }
    }

    @media #{_f.query(_c.$breakpoint-m, 'max')} {
        display: flex;
        flex-direction: column;

        .modal__header__content {
            margin-bottom: _c.$spacing-l;
            order: -1;
        }
    }
}
