@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

//
//   @table
// --------------------------------------------------------
//  Generic table styles
// --------------------------------------------------------

.table {
    @include _m.layer-shadow-a;

    border-radius: _c.$border-radius-l;
    margin-bottom: _c.$spacing-m;
    width: 100%;

    td,
    th {
        padding: _c.$spacing-s;
    }

    th {
        background-color: _c.$blue-light;
        color: rgb(255, 255, 255);

        &:first-child {
            border-radius: 6px 0 0;
        }
        &:last-child {
            border-radius: 0 6px 0 0;
        }
    }

    td {
        border-bottom: 1px solid _c.$gray-med;
    }

    tr:nth-child(even) td {
        background-color: #fafafa;
    }

    tr:last-child td {
        border-bottom: 0;

        &:first-child {
            border-radius: 0 0 0 6px;
        }

        &:last-child {
            border-radius: 0 0 6px;
        }

    }
}