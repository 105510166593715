@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


.about-masthead {
    $masthead-medium: _f.em(904, 16);
    $masthead-small: _f.em(528, 16);

    @include _m.clearfix;

    background-image: url('../images/masthead/about-masthead.jpg');
    background-position: 50% 0;
    display: block;
    padding: _f.rem(100) 0 0;

    @media #{_f.query($masthead-medium, 'max')} {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }


    img {
        @media #{_f.query($masthead-medium)} {
            float: right;
        }
    }

    .masthead-content-center {
        margin: 0 auto;
        max-width: _f.rem(1400);
    }

    .masthead-content-wrap {
        max-width: _f.rem(1040);
    }

    .masthead-content {
        padding-left: _c.$spacing-m;
        padding-top: _f.rem(128);
    }

    .masthead-text {
        @include _m.sans--semi-bold;

        display: none;

        @media #{_f.query($masthead-medium)} {
            display: block;
            font-size: _f.rem(18);
        }

        @media #{_f.query(_c.$breakpoint-l)} {
            font-size: _f.rem(24);
        }
    }

    @media #{_f.query($masthead-medium, 'max')} {
        .masthead-heading {
            margin: auto;
            text-align: center;
        }

        .masthead-content-wrap {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .masthead-content {
            order: -1;
            padding-left: 0;
            padding-top: _c.$spacing-xxxl;
            text-align: center;
        }
    }

    @media #{_f.query($masthead-small, 'max')} {
        background-image: url('../images/masthead/about-masthead-small.jpg');
        overflow: hidden;

        img {
            margin-left: _f.rem(-8);
            max-width: 118%;
        }
    }
}

// ----------------------------------------------
// Section Padding
// ----------------------------------------------

.section--about-spacing {
    padding: _c.$spacing-l 0;

    @media #{_f.query(_c.$breakpoint-l)} {
        padding: _f.rem(72) 0 _f.rem(120);
    }
}

.section--about-spacing--v2 {
    padding: _c.$spacing-l;

    @media #{_f.query(_c.$breakpoint-l)} {
        padding: _f.rem(72) _c.$spacing-l;
    }
}

.section--about {
    .grid-flex {
        @media #{_f.query(_c.$breakpoint-l)} {
            margin-left: _f.rem(-56);
        }

        @media #{_f.query(_c.$breakpoint-xxl)} {
            margin-left: _f.rem(-112);
        }

        > .grid__item {
            @media #{_f.query(_c.$breakpoint-l)} {
                padding-left: _f.rem(56);
            }

            @media #{_f.query(_c.$breakpoint-xxl)} {
                padding-left: 112px;
            }
        }

        .section--about-video {
            @media #{_f.query(_c.$breakpoint-l, 'max')} {
                margin-bottom: _c.$spacing-l;
            }
        }
    }

    .container {
        @media #{_f.query(_f.em(1400, 16), 'max')} {
            padding-left: _c.$spacing-l;
            padding-right: _c.$spacing-l;
        }
    }
}


// *******************************************************
//
//   Rgist
//   -> The design for the grid and highlighted letters
//      of RGIST
//
// *******************************************************

.rgist {
    position: relative;

    @media #{_f.query(_c.$breakpoint-l)} {
        display: flex;
        // flex-wrap: wrap;
    }
}

.rgist__vertical-img {
    max-width: 96px;
    position: absolute;

    @media #{_f.query(_c.$breakpoint-l)} {
        display: none;
    }
}

.rgist__horizontal-img {
    display: none;
    padding: 0 70px;

    @media #{_f.query(_c.$breakpoint-l)} {
        display: block;
    }
}

.rgist__item {
    margin: 0 auto _c.$spacing-xl;
    text-align: left;

    @media #{_f.query(_c.$breakpoint-l, 'max')} {
        display: flex;
        height: 169px;
        margin-bottom: 0;
        padding-left: 112px;

        &:last-child {
            height: 120px;
        }
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        margin-bottom: 0;
        // margin-top: 20px;
        padding: 0 _c.$spacing-m _c.$spacing-l;
        text-align: center;
        width: 50%;

        &:nth-child(odd) {
            position: relative;
            top: 48px;
        }
    }
}

.rgist__letter,
.rgist__content {
    position: relative;
    z-index: 5;
}

.rgist__letter {
    @include _m.sans--black;

    background-color: #e5e3e2;
    border-radius: 50%;
    color: #3d4a57;
    flex-shrink: 0;
    font-size: _f.rem(64);
    height: _f.rem(96);
    line-height: 1.2;
    margin: 0 auto _f.rem(24);
    padding: _f.rem(10) 0;
    text-align: center;
    width: _f.rem(96);

    @media #{_f.query(_c.$breakpoint-l)} {
        display: none;
    }
}

.rgist__content {
    flex: 1;
    padding-left: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m)} {
        padding: 0;
    }
}

.rgist__heading {
    font-size: _f.rem(20);
    margin-bottom: _c.$spacing-xs;
    text-transform: uppercase;

    @media #{_f.query(_c.$breakpoint-l)} {
        border-bottom: 1px solid _c.$gray;
        margin-bottom: _c.$spacing-m;
        padding-bottom: _c.$spacing-s;
    }

    @media #{_f.query(_c.$breakpoint-xl)} {
        font-size: _f.rem(24);
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        font-size: _f.rem(18);
    }
}

.rgist__text {
    margin-bottom: 0;

    @media #{_f.query(_c.$breakpoint-xl)} {
        font-size: _f.rem(18);
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        font-size: _f.rem(14);
    }
}

.rgist__svg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.line {
    stroke-width: 2px;
    stroke: #e0d6cf;
}
