@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;

.site-header-agency {
    background-color: transparent !important;

    .site-header__inner {
        align-items: center;
    }

    .logo a {
        color: _c.$white;
    }
}

.agency-logo {
    max-width: 146px;
    text-align: right;
    width: 100%;

    &.agency-logo--text {
        max-width: 100%;
        width: auto;
    }

    h1 {
        color: _c.$white;
        font-size: _f.rem(32);
        // Word wrap if there is a long string
        // These are technically the same, but use both
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        // This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        // Instead use this non-standard one:
        word-break: break-word;

    // Adds a hyphen where the word breaks, if supported (No Blink)
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    }
}

.agency-masthead.home-masthead {
    background: no-repeat url('../images/masthead/agency-masthead.jpg') center center / cover;

    .masthead-heading,
    .masthead-tagline,
    .masthead-form-label {
        color: _c.$white !important;
    }
}

.agency-coupon {
    display: flex;
    justify-content: center;
    padding-bottom: _c.$spacing-xxl;
    padding-left: _c.$spacing-m;
    padding-right: _c.$spacing-m;

    @media #{_f.query(_c.$breakpoint-m)} {
        padding-bottom: _c.$spacing-xxxxl;
    }

    .agency-coupon__details {
        background-image: url('../images/coupon@2x.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: _c.$blue;
        display: flex;
        flex-direction: column;
        height: 119px;
        justify-content: center;
        line-height: 2.5rem;
        width: 509px;

        @media #{_f.query(_c.$breakpoint-m, 'max')} {
            height: calc((119/509) * 100vw);
            max-width: 509px;
            width: 100%;
        }

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            line-height: 7vw;
        }
    }

    .agency-coupon__value {
        font-size: 2.26rem;
        font-weight: 700;

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            font-size: 7vw;
        }
    }

    .agency-coupon__code {
        font-size: 1.62rem;
        font-weight: 500;

        @media #{_f.query(_c.$breakpoint-s, 'max')} {
            font-size: 6vw;
        }
    }
}

.agency-exclusive-section.small-business-masthead {
    $masthead-breakpoint-medium: _f.em(1275, 16);

    padding-top: _c.$spacing-l;

    .mobile-icons {
        text-align: center;

        @media #{_f.query($masthead-breakpoint-medium)} {
            padding-left: 2em;
            text-align: left;
        }
    }

    .perks-for-masthead-list {
        margin-bottom: _c.$spacing-l;

        @media #{_f.query($masthead-breakpoint-medium)} {
            margin-bottom: _c.$spacing-m;
        }
    }
}
