@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';


.grid {
    display: grid;
}

.grid--two-col {
    grid-template-columns: 1fr;

    @media #{_f.query(_c.$breakpoint-m)} {
        grid-template-columns: 1fr 1fr;
    }
}


// ----------------------------------------------
// Flex columns
// ----------------------------------------------

// 1. Allow the grid system to be used on lists.
// 2. Remove any margins and paddings that might affect the grid system.
// 3. Apply a negative `margin-left` and `margin-top` to negate the columns’ gutters.

.grid-flex {
    $gutter-x: _c.$spacing-m;
    $gutter-y: 0;

    display: flex;
    flex-wrap: wrap;
    list-style: none;                // [1] */
    margin: 0;                       // [2] */
    margin-left: -$gutter-x;           // [3] */
    margin-top: -$gutter-y;           // [3] */
    padding: 0;

    > .grid__item {
        margin-top: $gutter-y;
        padding-left: $gutter-x;
        width: 100%;

        &.c-1of2 {
            flex-basis: math.div(100%, 2);
            max-width: math.div(100%, 2);
        }

        @media #{_f.query(_c.$breakpoint-s)} {
            &.s--c-1of2 {
                flex-basis: math.div(100%, 2);
                max-width: math.div(100%, 2);
            }
        }

        @media #{_f.query(_c.$breakpoint-m)} {
            &.m--c-1of2 {
                flex-basis: math.div(100%, 2);
                max-width: math.div(100%, 2);
            }

            &.m--c-1of3 {
                flex-basis: math.div(100%, 3);
                max-width: math.div(100%, 3);
            }

            &.m--c-2of3 {
                flex-basis: math.div(100%, 3) * 2;
                max-width: math.div(100%, 3) * 2;
            }

            &.m--c-2of10 {
                flex-basis: math.div(100%, 10) * 2;
                max-width: math.div(100%, 10) * 2;
            }

            &.m--c-8of10 {
                flex-basis: math.div(100%, 10) * 8;
                max-width: math.div(100%, 10) * 8;
            }
        }

        @media #{_f.query(_c.$breakpoint-l)} {
            &.l--c-1of2 {
                flex-basis: math.div(100%, 2);
                max-width: math.div(100%, 2);
            }

            &.l--c-1of3 {
                flex-basis: math.div(100%, 3);
                max-width: math.div(100%, 3);
            }
        }

        @media #{_f.query(_c.$breakpoint-l, 'max')} {
            &.l--max--order-first {
                order: -1;
            }
        }
    }
}

.grid-flex--middle {
    align-items: center;
}

.grid-flex--bottom {
    align-items: flex-end;
}


// ----------------------------------------------
//  Media layout at all widths
// ----------------------------------------------

.media-flex {
    align-items: flex-start;
    display: flex;

    &.media--middle {
        align-items: center;
    }

    &.media--center {
        justify-content: center;
    }

    .media__figure {
        margin: 0 _c.$spacing-xm 0 0;

        &.media__figure--alt {
            flex-shrink: 0;
        }
    }

    &:not(.media--auto) {
        .media__body {
            flex: 1;
        }
    }

    .media__body,
    .media__body :last-child {
        margin-bottom: 0;
    }
}


// ----------------------------------------------
// CSS Text Columns
// ----------------------------------------------

.text-cols-2 {
    @media #{_f.query(_c.$breakpoint-m)} {
        columns: 2 auto;
    }
}

.text-cols__col {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}


// ----------------------------------------------
// Sub Nav Layout
// ----------------------------------------------

.sub-nav-left-layout {
    margin: 0 auto;
    max-width: _c.$main-width;
    padding: _c.$spacing-xm;

    .table_wrap {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; /* has to be scroll, not auto */
    }
}

.sub-nav-left-nav {
    margin: 0 auto _c.$spacing-l;
    max-width: 400px;
}

.sub-nav-left-layout--rev {
    display: flex;
    flex-direction: column-reverse;

    @media #{_f.query(_c.$breakpoint-l)} {
        flex-direction: row;
    }
}

@media #{_f.query(_c.$breakpoint-l)} {
    .sub-nav-left-layout {
        display: flex;
        padding: _c.$spacing-xxl _c.$spacing-m;

        @supports (display:grid) {
            display: grid;
            grid-template-columns: 400px minmax(0, 1fr);
        }
    }

    .sub-nav-left-layout .table_wrap {
        overflow-x: auto;
    }

    .sub-nav-left-content {
        flex: 1 0 0%;
        max-width: 100%;
        padding-left: _c.$spacing-xl;
    }

    .sub-nav-left-nav {
        margin: 0;
        position: sticky;
        top: _c.$spacing-xl;
    }
}

.sub-nav-left-nav--stacked {
    @media #{_f.query(_c.$breakpoint-l)} {
        position: static;
    }
}

.sub-nav-left-nav {
    background-color: _c.$white;
    box-shadow: 0px 0px 58.5px 6.5px rgba(0, 0, 0, 0.09);
    border-radius: _c.$border-radius-l;
    padding: _c.$spacing-xm;
}

.sub-nav-left-icon-list {
    padding: 0;
    margin: 0;

    ul {
        padding-left: _f.rem(40);
    }

    li {
        list-style: none;
        margin-bottom: 0;
        margin-left: 0;
    }

    li li {
        padding-left: _c.$spacing-s;
    }

    a {
        border-radius: _c.$border-radius-m;
        display: inline-flex;
        font-size: _f.rem(18);
        padding: _c.$spacing-m _c.$spacing-l;
        text-decoration: none;
        width: 100%;

        &:link,
        &:visited {
            color: _c.$black;
        }

        &:hover {
            color: _c.$blue-highlight;
        }

        &.is-active {
            background-color: #e6ebf2;
            color: _c.$black;
        }
    }

    img {
        height: _f.rem(24);
        margin-right: _c.$spacing-xm;
        width: _f.rem(24);
    }
}

.sub-nav-left-heading {
    padding: 0 _c.$spacing-m;
}

.sub-nav-left-list {
    padding-left: 1em;

    > li {
        margin-bottom: _c.$spacing-s;
    }

    a {
        display: block;
    }
}
