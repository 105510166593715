@use '../config/' as _c;

.canvas-close {
    bottom: 0;
    display: none;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.25s _c.$ease-in-out-cubic;
    z-index: _c.$z-index-overlay;

    &.is-active {
        display: block;
    }

    &.is-visible {
        opacity: 1;
    }
}
