@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


.merchants-masthead {
    background: _c.$gray-light-med no-repeat url('../images/masthead/merchants-small.jpg') right top;

    @media #{_f.query(_c.$breakpoint-s)} {
        background-image: url('../images/masthead/merchants-large.jpg');
        background-position: left top;
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        background-position: center calc(100% - 145px);
    }
}

.merchants-masthead__text {
    h2 {
        @include _m.sans--semi-bold;
        font-size: _f.rem(18);
        margin-bottom: _c.$spacing-m;
        padding-top: _c.$spacing-xl;

        @media #{_f.query(_c.$breakpoint-l)} {
            font-size: _f.rem(24);
            padding-top: _c.$spacing-xxxl;
        }
    }
    p {
        @include _m.sans--semi-bold;
        color: #4d5055;

        @media #{_f.query(_c.$breakpoint-l)} {
            font-size: _f.rem(20);
        }
    }

    form {
        padding-bottom: _c.$spacing-xl;

        @media #{_f.query(_c.$breakpoint-l)} {
            padding-bottom: _c.$spacing-xxml;
        }
    }

    @media #{_f.query(_c.$breakpoint-l)} {
        margin-bottom: 145px;
        padding-right: _c.$spacing-xl;
    }

    @media #{_f.query(_c.$breakpoint-xl)} {
        padding-right: _f.rem(216);
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        padding-left: _c.$spacing-xm;
        padding-right: _c.$spacing-xm;
    }
}
