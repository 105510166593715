@mixin box-shadow-default {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.15);
}

@mixin layer-shadow-a {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01), 0 6px 10px rgba(#32325D, 0.07);
}

@mixin layer-shadow-b {
    box-shadow: 0 3px 14px rgba(#000, 0.07), 0 10px 24px rgba(#32325D, 0.1);
}

@mixin layer-shadow-c {
    box-shadow: 0px 0px 58.5px 6.5px rgba(0, 0, 0, 0.09);
}
