@use '../config';
@use '../function';

.logo {
    color: config.$blue;
    width: function.rem(155);

    a {
        &:link,
        &:visited,
        &:hover,
        &:focus,
        &:active {
            color: config.$blue;
        }
    }

    svg {
        height: function.rem(52);
        width: function.rem(155);
    }
}
