@use '../function' as _f;

$spacing-xxs: _f.rem(2);
$spacing-xs: _f.rem(4);
$spacing-s: _f.rem(8);
$spacing-m: _f.rem(16);
$spacing-xm: _f.rem(24);
$spacing-l: _f.rem(32);
$spacing-lm: _f.rem(40);
$spacing-xl: _f.rem(48);
$spacing-xxl: _f.rem(64);
$spacing-xxml: _f.rem(72);
$spacing-xxxl: _f.rem(80);
$spacing-xxxxl: _f.rem(96);
