@use '../config' as _c;
@use '../function' as _f;

.accordion-btn {
    background-color: transparent;
    border: 0;
    display: flex;
    font: inherit;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    text-align: left;
    width: 100%;

    &.is-visible .accordion-btn__icon {
        transform: rotate(180deg);
    }
}

.accordion-btn__icon {
    fill: _c.$white;
    flex-shrink: 0;
    height: 16px;
    margin-left: 0.25em;
    margin-top: 0.25em;
    transform: rotate(0);
    transition: transform 0.15s ease-in;
    width: 16px;
}

.accordion-section {
    display: none;

    &.is-visible {
        display: block;
    }
}


// ----------------------------------------------
// Accordion Question List
// ----------------------------------------------
// A list of questions where the answers
// are expandable/collapsible.
// ----------------------------------------------

.accordion-question-list {
    list-style: none;
    padding: 0;

    @media #{_f.query(_c.$breakpoint-m)} {
        padding-right: _c.$spacing-xl;
    }

    > li {
        border-bottom: 1px dashed _c.$gray-med;
    }

    .accordion-btn {
        padding-bottom: _c.$spacing-m;
        padding-top: _c.$spacing-m;

        &:hover {
            color: _c.$blue;
        }
    }
}

.accordion-question-list__heading {
    font-size: _f.rem(18);
    margin-bottom: 0;
}
