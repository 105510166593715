$red: #C13D3D;
$red-lightest: #F3E0DD;
$red-light: #D94848;
$red-dark: #9A0704;

$blue: #305078;
$blue-light: #4472AB;
$blue-xlight: #E0EEFF;
$blue-dark: #223854;
$blue-highlight: #418BDB;

$yellow: #F3F2D1;
$yellow-light: #FFFABE;
$yellow-dark: #CFA404;

$green: #3C9D36;
$green-light: #49BE41;
$green-lightest: #E9F9D9;
$green-dark: #1E581D;

$gray: #aaa;
$gray-light: #f7f8fa;
$gray-light-med: #f4f4f4;
$gray-med: #d6d7d9;
$gray-dark: #525252;
$gray-xdark: #2c2b2b;

$slate: #99a6b7;
$slate-light: #d5dde9;
$slate-x-light: #EFF1F5;
$slate-dark: #3c4147;

$white: #fff;
$white-faded: rgba($white, 0.6);
$black: #272a2e;
