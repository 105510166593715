@use '../config' as _c;
@use '../function' as _f;

.back-to-top-btn {
    background-color: _c.$white;
    border: 0;
    border-radius: _f.rem(6) 0 0 _f.rem(6);
    bottom: _f.rem(64);
    box-shadow: 0 3px 14px rgba(#000, 0.07),
                0 10px 24px rgba(#32325D, 0.1);
    color: _c.$blue;
    cursor: pointer;
    height: _f.rem(64);
    line-height: 2;
    opacity: 1;
    outline: 0;
    padding: 0 0.5em;
    position: fixed;
    right: 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.25s ease-in-out;
    width: _f.rem(64);
    z-index: 999;

    &:hover {
        color: _c.$blue-light;
    }

    .icon {
        display: inline-block;
        fill: _c.$blue;
        height: _f.rem(48);
        position: relative;
        top: -.0625em;
        vertical-align: middle;
        width: _f.rem(48);
    }

    &.hidden {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}
