@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


$masthead-breakpoint-medium: _f.em(1275, 16);
$masthead-breakpoint-large: _f.em(1424, 16);
$masthead-extra-large: _f.em(1690, 16);


// If browser does not support shape outside
.small-business-masthead {
    .large-img {
        flex-shrink: 0;
    }

    @media #{_f.query($masthead-breakpoint-medium)} {
        align-items: flex-end;
        display: flex;
        flex-direction: row;

        .large-img {
            height: auto;
            order: 2;
            max-width: 50%;
            width: 100%;
        }

        .masthead-content {
            padding-left: _c.$spacing-l;
            padding-top: _c.$spacing-l;
        }

        .masthead-content__text {
            padding-bottom: _c.$spacing-l;
        }
    }
}


// All browsers
.small-business-masthead {
    background-color: _c.$gray-light-med;
    border-bottom: 4px solid _c.$white;
    padding-top: _f.rem(100);

    .masthead-heading-custom {
        @include _m.sans--black;

        color: _c.$blue;
        font-size: _f.rem(48);
        line-height: _f.rem(56);
        text-align: left;

        @media #{_f.query(_f.em(620, 16))} {
            font-size: _f.rem(72);
            margin-bottom: _c.$spacing-l;
        }

        mark {
            font-size: _f.rem(32);
            line-height: _f.rem(32);

            @media #{_f.query(_f.em(620, 16))} {
                font-size: _f.rem(48);
                line-height: _f.rem(48);
            }
        }
    }

    // ------------ Smallest ------------ //
    @media #{_f.query($masthead-breakpoint-medium, 'max')} {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;

        .masthead-content {
            padding: _c.$spacing-l;
            max-width: _f.rem(700);
            width: 100%;
        }
    }

    .small-img {
        display: none;
    }

    @media #{_f.query(_c.$breakpoint-s, 'max')} {
        .small-img {
            display: block;
        }

        .large-img {
            display: none;
        }

        .masthead-content {
            padding: 0;
        }

        .masthead-content__text {
            background-color: #DEDFE1;
            padding-bottom: _c.$spacing-xm;
        }

        .masthead-content__heading,
        .masthead-content__text {
            margin-bottom: 0;
            padding-left: _c.$spacing-xm;
            padding-right: _c.$spacing-xm;
        }
    }

}


// Browsers that support shape-outside
@supports (shape-outside: url('../images/masthead/small-business-masthead-mask.png')) {
    .small-business-masthead {
        @include _m.clearfix;

        .large-img {
            shape-outside: url('../images/masthead/small-business-masthead-mask.png');
        }

        .masthead-content__text {
            padding-bottom: 0; // reset from browsers that don't support shape-outside
        }

        @media #{_f.query($masthead-breakpoint-medium)} {
            display: block; // override flexbox for browsers that don't support shape-outside

            .large-img {
                float: right;
                max-width: _f.percent(1400, 1920);
            }

            .masthead-content {
                padding-left: _c.$spacing-xm;
                padding-top: _f.percent(72, 1920);
            }
        }

        @media #{_f.query($masthead-breakpoint-large)} {
            .masthead-content {
                padding-left: _f.percent(128, 1920);
                padding-top: _f.percent(128, 1920);
            }
        }

        @media #{_f.query($masthead-extra-large)} {
            .masthead-content {
                padding-left: _f.percent(250, 1920);

                &.masthead-content--thankyou {
                    padding-left: 22%;
                }
            }
        }
    }
}

.small-business-stat {
    border: 1px solid #7388a3;
    border-radius: _c.$border-radius-m;
}


// ----------------------------------------------
// Credit Card Box
// ----------------------------------------------
$credit-card-media-query: _f.em(922, 16);

.credit-card-box {
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 3px 14px rgba(#000, 0.07),
                0 10px 24px rgba(#32325D, 0.1);
    display: flex;
    flex-direction: column;

    @media #{_f.query($credit-card-media-query)} {
        flex-direction: row;
    }
}

.credit-card-box__text {
    padding: _f.rem(16);

    @media #{_f.query($credit-card-media-query)} {
        flex-basis: 328px;
    }

    h3 {
        margin-bottom: _f.rem(8);
    }

    .old-monthly-price {
        @include _m.sans--bold;
        color: _c.$gray-dark;
        margin-bottom: 0;
        text-decoration: line-through;
    }

    .note-green {
        color: _c.$green;
    }

    .monthly-price {
        @include _m.sans--bold;
        color: _c.$green;
        line-height: 1;
        font-size: _f.rem(48);
    }

    .small-business-price {
        opacity: 1;
        transition: opacity 0.3s ease-in;

        &.fade-out {
            opacity: 0;
        }
    }
}

.credit-card-box__body {
    background-color: #E8E8E8;
    border-radius: 0 0 4px 4px;
    font-size: _f.rem(14);
    padding: _f.rem(16);
    width: 100%;

    @media #{_f.query($credit-card-media-query)} {
        border-radius: 0 4px 4px 0;
        flex: 1 0 auto;
        width: auto;
    }
}

.credit-card-icons {
    height: auto;
    width: _f.rem(368);
}


// ----------------------------------------------
// Credit Card Layout
// ----------------------------------------------

// ---------------
//  Credit Card
// ---------------


.credit-card-fields {
    .form_item {
        margin-bottom: 0;
    }

    h3,
    input {
        margin-bottom: 8px;
    }

    input {
        width: 100%;
    }
}

.credit-card {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
    padding-bottom: 8px;

    @media #{_f.query(_c.$breakpoint-s)} {
        display: flex;

        > div {
            padding-right: 8px;
            width: 50%;
        }

        > div:last-child {
            padding-left: 8px;
            padding-right: 0;
        }
    }
}

.credit-card__details {
    display: flex;
    flex-wrap: wrap;

    .credit-card__number {
        flex-basis: 232px;
        max-width: 232px;
        padding-right: 16px;
    }

    .credit-card__date .label {
        white-space: nowrap;
    }

    .credit-card__date,
    .credit-card__cvc {
        flex-basis: 144px;
        max-width: 144px;
        padding-right: 16px;

        > div {
            display: inline-block;
            padding-right: 8px;
            width: calc(50% - 8px);
        }
    }
}


// ---------------
//  Coupon Code
// ---------------

.coupon-code__label {
    flex-shrink: 0;
    padding: 0 0 8px;

    label {
        margin-bottom: 0;
    }
}

.coupon-code__input {
    flex: 1 0 auto;
}

@media #{_f.query(_f.em(608))} {
    .coupon-code {
        align-items: center;
        display: flex;
    }

    .coupon-code__label {
        padding: 0 16px 0;
    }
}
