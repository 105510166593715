@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;


// ----------------------------------------------
// Header Layout
// ----------------------------------------------

$mobile-menu-button-show: _f.em(861, 16);

.site-header {
    background-color: _c.$white;
    left: 0;
    padding: _c.$spacing-xm;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: _c.$z-index-header;

    &.is-transparent {
        background-color: transparent;
    }

    @media #{_f.query($mobile-menu-button-show)} {
        background-color: transparent;
    }

    @media #{_f.query(_f.em(860, 16), 'max')} {
        padding: _f.rem(12) _c.$spacing-xm;

        .logo,
        .logo svg {
            height: _f.rem(42);
            width: _f.rem(124);
        }
    }

        // cloned header that is revealed when
        // main header is out of view

        &.is-fixed {
            background-color: _c.$white;
            bottom: 100%;
            position: fixed;
            top: auto;
            transition: transform 0.3s ease-in-out;
            z-index: _c.$z-index-header + 5;
        }

        &.is-visible {
            @include _m.layer-shadow-b;
            transform: translate3d(0, 100%, 0);
        }
}

.site-header__inner {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: _c.$main-width;
}

.site-header__nav {
    align-content: center;
    display: none;

    @media #{_f.query($mobile-menu-button-show)} {
        display: flex;
    }
}


// ----------------------------------------------
// Navigation Styles
// ----------------------------------------------

.site-nav {
    align-items: center;
    display: flex;
    list-style: none;
    padding: 0;

    .has-submenu {
        position: relative;
    }

    .accordion-btn__icon {
        height: _f.rem(12);
        margin-top: 0;
        width: _f.rem(12);
    }
}

.site-nav__item {
    flex-shrink: 0;
}

.site-nav__link {
    @include _m.sans--bold;

    display: block;
    padding: _c.$spacing-s;
    text-decoration: none;
    transition: color 0.15s ease-in;

    @media #{_f.query(_c.$breakpoint-l)} {
        padding: _c.$spacing-s _c.$spacing-m;
    }

    @media #{_f.query(_c.$breakpoint-xl)} {
        padding: _c.$spacing-s _c.$spacing-l;
    }

    &:link,
    &:visited {
        color: _c.$blue;
    }

    // [1] Submenu is showing
    &:hover,
    &[aria-expanded='true'] { // [1]
        color: _c.$blue-highlight;
    }

    &.is-active {
        text-decoration: underline;
    }
}

.site-nav .btn {
    @media #{_f.query(_c.$breakpoint-xl, 'max')} {
        padding-left: _c.$spacing-l;
        padding-right: _c.$spacing-l;
    }
}

// ------------ Submenu ------------ //
.site-nav__submenu-wrap {
    display: none;
}

.site-nav__submenu {
    list-style: none;
    padding: _c.$spacing-m;

    .site-nav__link {
        padding: _c.$spacing-s;
        white-space: nowrap;

        &:link,
        &:visited {
            color: _c.$white;
        }

        &:hover {
            color: _c.$gray-med;
        }
    }
}

.no-js {
    .site-nav__submenu {
        @include _m.box-shadow-default;

        background-color: _c.$blue;
        border-radius: _c.$border-radius-l;
        display: none;
        position: absolute;
        top: 100%;

        .has-submenu:hover & {
            display: block;
        }
    }
}

.tippy-box[data-theme~='navigation'] {
    background-color: _c.$blue;
    border-radius: _c.$border-radius-xl;
    color: _c.$white;
}


// ----------------------------------------------
// Mobile Menu
// ----------------------------------------------

body.has-mobile-menu {
    overflow: hidden;
}

.canvas-close--mobile-menu {
    background-color: rgba(#002441, 0.98);
    padding: _c.$spacing-m;
}

.mobile-menu-btn {
    background-color: transparent;
    border: 0;
    color: _c.$blue;
    display: inline-block;
    padding: 0;

    @media #{_f.query($mobile-menu-button-show)} {
        display: none;
    }
}

.mobile-menu {
    display: none;
    opacity: 0;
    outline: 0;
    transition: opacity 0.25s _c.$ease-in-out-cubic, transform 0.35s _c.$ease-in-out-cubic;

    &.is-active {
        display: block;
    }

    &.is-visible {
        opacity: 1;
    }
}

.mobile-nav,
.mobile-nav__subnav {
    list-style: none;
}

.mobile-nav__item {
    margin-bottom: _c.$spacing-m;
}

.mobile-menu__header {
    margin-bottom: _c.$spacing-xl;
    padding: _c.$spacing-m;
    position: relative;

    .logo {
        color: _c.$white;
    }
}

.mobile-menu__close {
    background-color: transparent;
    border: 0;
    color: _c.$white;
    display: block;
    height: _f.rem(32) + _c.$spacing-s;
    margin-top: -20px;
    padding: _c.$spacing-s;
    position: absolute;
    right: 0;
    top: 50%;
    width: _f.rem(32) + _c.$spacing-s;
}


// ------------ Accordion Submenu ------------ //
.mobile-nav__section {
    display: none;

    &.is-visible {
        display: block;
    }
}

.mobile-nav__link {
    background: transparent;
    border: 0;
    color: _c.$white;
    display: block;
    font-size: _f.rem(22);
    padding: 0;
    text-align: left;
    text-decoration: none;
    width: 100%;

    &:link,
    &:visited {
        color: _c.$white;
    }
}
