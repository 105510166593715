@use '../config' as _c;
@use '../function' as _f;
@use '../mixin' as _m;
@use 'sass:math';

.app-icons {
    display: flex;
    flex-wrap: wrap;
}

.app-icons__link {
    align-items: center;
    color: _c.$black;
    display: flex;
    font-size: _f.rem(16);
    margin-bottom: _c.$spacing-m;
    text-decoration: none;
    width: 100%;

    @media #{_f.query(_c.$breakpoint-s)} {
        width: math.div(100%, 2);
    }

    @media #{_f.query(_c.$breakpoint-m)} {
        width: math.div(100%, 3);
    }

    &:hover {
        color: _c.$blue-highlight;

        svg {
            color: _c.$blue-highlight;
        }
    }

    svg {
        background-color: _c.$slate-light;
        border-radius: 50%;
        color: _c.$blue-dark;
        height: _f.rem(64);
        flex: 0 0 auto;
        margin-right: _f.rem(16);
        width: _f.rem(64);
    }

    span {
        flex: 1 0 0%;
        width: 100%;

        @media #{_f.query(_c.$breakpoint-s)} {
            padding-right: _c.$spacing-m;
        }
    }
}

.app-icons--small {
    .app-icons__link svg {
        height: _f.rem(48);
        margin-right: _f.rem(8);
        width: _f.rem(48);
    }
}
